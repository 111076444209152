<ngx-smart-modal #particularDeudoresModal identifier="particularDeudoresModal" [customClass]="'modal-lg'" [dismissable] = "false" [closable] = "false" >

    <div class="modal-body">
      <h4 *ngIf="deudores.length > 0">Cuentas pendientes de pago <i class="fas fa-hand-holding-usd"></i> - Orden particular </h4>
      <table class="table table-sm table-bordered" *ngIf="deudores.length > 0">
          <thead>
            <tr>
              <th style="width:8%">#</th>
              <th style="width:20%">Fecha de orden</th>
              <th>Nombre del cliente</th>
              <th style="width:20%">Ver cuenta</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let cuenta of deudores; let i = index">
              <td>{{ i + 1 }}.</td>
              <td>{{ cuenta.fecha | fechaFormat }}</td>
              <td>{{ cuenta.cliente | uppercase }}</td>
              <td>
                <button class="btn btn-block btn-default"
                        (click)="mostrarCuenta(cuenta._id)">
                  <i class="fas fa-file-invoice-dollar fa-lg"></i>
                </button>
              </td>
            </tr>
          </tbody>
      </table>

      <ng-container *ngIf="deudores.length == 0">
        <div class="row justify-content-center">
          <h5>No hay cuentas pendientes de pago</h5>
        </div>
        <div class="row justify-content-center">
          <img
              src="../../../../assets/imgs/save_money.png"
              class="img-fluid"
              alt="check-green"
              style="width: 400px; height: 500;"/>
        </div>
      </ng-container>


  </div>
  <div class="modal-footer justify-content-right">

    <button class="btn btn-secondary float-right" (click)="particularDeudoresModal.close();">
      Cerrar
    </button>

  </div>


</ngx-smart-modal>

<ngx-smart-modal #cuentaParticularModal identifier="cuentaParticularModal" [customClass]="'modal-lg'" [dismissable] = "false" [closable] = "false">

  <div class="modal-body">

    <h5>Cuenta por orden particular <i class="fas fa-file-invoice-dollar fa-lg"></i> </h5>

    <h6><strong>Número de cuenta: </strong>{{ numeroCuenta }}</h6>
    <h6><strong>Fecha: </strong>{{ fechaCuenta | fechaFormat }}</h6>
    <h6><strong>Cliente: </strong>{{ nombreCliente }}<strong> - Código de cliente: </strong>{{ codigo }}</h6>
    <h6><strong>Cuenta total: </strong>${{sumaCuenta}}</h6>
    <h6><strong>Estado: </strong>{{ estado }}</h6>
    <hr>

    <div class="row">
      <table class="table table-sm table-bordered">
          <thead>
            <th style="width:13%">#</th>
            <th>A nombre de: </th>
            <th style="width:20%">Precio</th>
            <th style="width:15%">Descartar</th>
          </thead>
          <tbody>
            <ng-container *ngFor="let cuenta of cuentas; let i = index">
              <tr>
                <td><b> Orden {{ i + 1 }}: </b></td>
                <td>
                  {{ cuenta.nombre }}
                </td>
                <td>
                  <input
                      type="text"
                      class="form-control"
                      placeholder="$0.00"
                      name="{{'cuenta'+ i}}"
                      #precioEstudio="ngModel"
                      [(ngModel)]="cuentas[i].precio"
                      autocomplete="off"
                      prefix="$"
                      mask="separator.2" thousandSeparator=","
                      (blur)="calcularTotalCuenta()"
                      (keyup)="calcularTotalCuenta()"
                  />
                </td>
                <td>
                  <button class="btn btn-block btn-default"
                          [disabled]="i == 0"
                          (click)="descartarOrden(i)">
                          <i class="fas fa-backspace"></i>
                  </button>
                </td>
              </tr>
              <tr *ngIf="cuenta.id_detalle.estudios">
                <td>Estudios: </td>
                <td colspan="3">
                  <ng-container *ngFor="let estudio of cuenta.id_detalle.estudios">
                    <ng-container *ngFor="let examen of estudio.examenes">
                      <li style="list-style-type: disc;">{{ examen.nombre }}: ${{ examen.precio }}</li>
                    </ng-container>
                  </ng-container>
                </td>
              </tr>
            </ng-container>

          </tbody>
          <tfoot>
            <tr>
              <td colspan="2">
                <b>Precio total:</b>
              </td>
              <td>
                <b>${{sumaCuenta}}</b>
              </td>
            </tr>
          </tfoot>
      </table>
    </div>

    <div class="row" *ngIf="!totalesValidos">
      <p style="color: red;">El total de la cuenta no debe ser menor al pago</p>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="$0.00"
              name="abono"
              #abonoCuenta="ngModel"
              [(ngModel)]="abono"
              autocomplete="off"
              prefix="$"
              mask="separator.2" thousandSeparator=","
            />
          </div>

        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <button class="btn btn-info float-left"
                  (click)="agregarAbono()">
            <i class="fas fa-hand-holding-usd"></i>
              Agregar abono
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <table class="table table-sm table-bordered">
        <thead>
            <tr>
              <th colspan="4">Lista de pagos</th>
            </tr>
            <tr>
              <th>#</th>
              <th>Fecha: </th>
              <th style="width:30%">Monto abonado: </th>
              <th>Registró:</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let abono of abonos; let j = index">
              <td>{{ j +1 }}.</td>
              <td>{{ abono.fecha | fechaFormatDate }}</td>
              <td>
                <input
                    type="text"
                    class="form-control"
                    placeholder="$0.00"
                    name="{{'abono' + j}}"
                    #abonoCuenta="ngModel"
                    [(ngModel)]="abonos[j].monto"
                    autocomplete="off"
                    prefix="$"
                    mask="separator.2" thousandSeparator=","
                    (blur)="calcularTotalCuenta()"
                    (keyup)="calcularTotalCuenta()"
                />
              </td>
              <td>{{ abono.registro }}</td>
            </tr>
        </tbody>
        <tfoot *ngIf="abonos.length > 0">
          <td colspan="2"><strong>Total abonado:</strong> </td>
          <td><strong>${{ sumaAbonos }}</strong></td>
          <td><strong>Pendiente: ${{ restoPendiente }}</strong></td>
        </tfoot>
        <tfoot *ngIf="abonos.length == 0">
          <td colspan="4" style="text-align: center;">
            <p>No hay pagos realizados</p>
          </td>
        </tfoot>
      </table>
    </div>

    <div class="row" *ngIf="!validValues">
      <p style="color: red;">Digite los campos correctamente</p>
    </div>
  </div>

  <div class="modal-footer d-none d-sm-block">

    <div class="float-left">
      <button class="btn btn-info"
              (click)="validarPago()"
              [disabled]="((!totalesValidos || !validValues))">
        <i class="fas fa-save"></i>
          Guardar
      </button>

      <button class="btn btn-secondary ml-3 mr-0"
      (click)="cuentaToPDF()">
        <i class="fas fa-print"></i>
          Imprimir
      </button>
    </div>

    <div class="float-right">
      <button class="btn btn-secondary float-right"
      (click)="cuentaParticularModal.close(); cancelar()">
        Cancelar
      </button>
    </div>
  </div>
</ngx-smart-modal>

<ngx-smart-modal  #confirmaPagoParticularModal identifier="confirmaPagoParticularModal" [customClass]="'modal-dialog modal-dialog-centered'" [dismissable] = "false" [closable] = "false" >

  <div class="modal-body justify-content-center" style="text-align: center;">

      <h3>Confirma tu contraseña <i class="fas fa-key"></i></h3>
      <h5>Por favor ingresa tu contraseña para continuar </h5>
      <br>
      <div class="form-group">
        <input type="password"
              autofocus
              #pass="ngModel"
              [(ngModel)]="password"
              class="form-control form-control-lg"
              placeholder="Contraseña">
      </div>

  </div>

  <div class="modal-footer justify-content-center">

    <button class="btn btn-info btn-lg" (click)="validarPassword()">
        Aceptar
    </button>

    <button class="btn btn-secondary btn-lg ml-3 mr-0" (click)="confirmaPagoParticularModal.close(); cancelar();">
        Cancelar
    </button>
  </div>

</ngx-smart-modal>
