import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fechaFormatFull'
})

export class FechaFormatFull implements PipeTransform{

    transform(value: any){

        let fecha = new Date(value);

        let dia = fecha.getDate();
        let mes = fecha.getMonth()+1;
        let year = fecha.getFullYear();

        let sec = fecha.getSeconds();
        let segundo = '';
        let min = fecha.getMinutes();
        let minuto = '';
        if(sec < 10){
            segundo = '0'+sec.toString();
        }else{
            segundo = sec.toString();
        }
        if(min < 10){
            minuto = '0'+min.toString();
        }else{
            minuto = min.toString();
        }

        //let hor = fecha.toLocaleTimeString();
        let hor = fecha.getHours();

        let fechaString = dia+'/'+mes+'/'+year+' - '+hor+':'+minuto+':'+segundo;

        return fechaString;

    }

}