import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ajusteDate'
})

export class AjusteDate implements PipeTransform{

    transform(fecha: string){
        //*La variable parametro fecha es un string que retorna el datePicker
        let fechaNew = new Date(fecha);
        fechaNew.setDate(fechaNew.getDate() + 1);

        return fechaNew;
    }

}
