import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'percentStock'
})

export class PercentStock implements PipeTransform{

    transform(stockIni: string, stockAct: string){
        let stockInicial = Number(stockIni);
        let stockActual = Number(stockAct);
        //*La variable total es el stock inicial al momento de registrar el articulo
        let percentAux = (stockActual*100)/stockInicial;
        let percent = Number(percentAux.toFixed(0));

        return percent;
    }

}
