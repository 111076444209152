import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Global } from '../../../services/global'
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrdenesParticularesService {

  public url: string;

  constructor(
    private _http: HttpClient
  ) {
    this.url = Global.url;
  }

  //*Método para crear un cliente tipo persona
  crearClientePersona(clientePersona):Observable<any>{

    let params = JSON.stringify(clientePersona);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post(this.url+'estudios/saveCliente', params, {headers: headers});
  }

  //*Método para obtener la lista de clientes tipo persona paginados
  getClientesPersona(page):Observable<any>{
    let params = new HttpParams().set('page', page);
    return this._http.get(this.url+'estudios/getClientesPersona', {params});
  }

  //*Método para realizar la petición de búsqueda de cliente persona con search engine
  searchClienteP(key, page):Observable<any>{
    let params = new HttpParams().set('page', page);
    return this._http.get(this.url+'estudios/searchClientePersona/'+key, {params});
  }

   //*Método para obtener un cliente en especifico
   getClientePersona(id):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.url+'estudios/findClientePersona/'+id, {headers: headers});
  }

  //*Método para hacer una petición de edición de registro
  updateClienteP(id, cliente){
    let params = JSON.stringify(cliente);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.put(this.url+'estudios/editClientePersona/'+id, params, {headers: headers});
  }

  //*Método para hacer una petición de eliminación de registro
  deleteClienteP(id){
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.delete(this.url+'estudios/deleteClientePersona/'+id, {headers: headers});
  }

  //?A PARTIR DE AQUI ESTAN LOS MÉTODOS DE ORDEN PARTICULAR?

   //*Método para crear una orden de estudios particular
  crearOrdenParticular(orden):Observable<any>{

    let params = JSON.stringify(orden);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post(this.url+'orden-particular/save', params, {headers: headers});
  }

  //*Método para obtener la lista de ordenes particulares paginadas
  getAllOrdenes(page):Observable<any>{
    let params = new HttpParams().set('page', page);
    return this._http.get(this.url+'orden-particular/getAllOrdenes', {params});
  }

  //*Método para realizar la petición de búsqueda de una orden con search engine
  searchOrden(key, page):Observable<any>{
    let params = new HttpParams().set('page', page);
    return this._http.get(this.url+'orden-particular/search/'+key, {params});
  }

  //*Método para eliminar una orden particular de la base de datos
  deleteOrden(id):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.delete(this.url+'orden-particular/delete/'+id, {headers: headers});
  }

  //*Método para obtener los datos de una orden en especifico mediante su ID
  ordenParticularById(idOrden):Observable<any>{
    return this._http.get(this.url+'orden-particular/findOrdenById/'+idOrden);
  }

  updateOrdenParticular(id, orden){
    let params = JSON.stringify(orden);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.put(this.url+'orden-particular/updateOrden/'+id, params, {headers: headers});
  }
  

}
