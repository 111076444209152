import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../../auth/services/login.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SocketColaService } from 'src/app/services/socket-cola.service';
import { ToastrService } from 'ngx-toastr';
import { ColaService } from '../../../services/cola.service';
import { SocketCuentaService } from '../../../services/socket-cuenta.service';
import { CuentaConsultaService } from '../../../services/cuentaConsulta.service';
import { SocketIndicacionesService } from '../../../services/socket-indicaciones.service';
import { ConsultaService } from '../../../moduloConsulta/consulta/services/consulta.service';
import { CuentaParticularService } from 'src/app/moduloLaboratorio/ordenes-particulares/services/cuenta-particular.service';
import { Global } from '../../../services/global';
import { SocketUserImageService } from 'src/app/services/socket-userImage.service';
import { UsuarioService } from 'src/app/auth/services/usuario.service';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.css']
})
export class SidemenuComponent implements OnInit {

  //direccion para obtener las imagenes de usuario
  public url = Global.url+'imagenUsuario/getImage/';
  userImage = '../../../../assets//imgs/user-general.png';//imagen por defecto

  public role = '';
  public roleTitle = '';
  public colaCount = 0;
  public cola = [];
  public atendidos = [];

  public cuentaCount = 0;
  public deudores = [];

  public indicaciones = [];
  public listaCount = 0;

  public cuentaParticularCount = 0;
  public deudoresParticulares = [];

  constructor(
    private router: Router,
    private loginService: LoginService,
    public ngxSmartModalService: NgxSmartModalService,
    private socketColaService: SocketColaService,
    private colaService: ColaService,
    private socketCuentaService: SocketCuentaService,
    private cuentaConsultaService: CuentaConsultaService,
    private sockeIndicacionesService: SocketIndicacionesService,
    private socketUserImageService: SocketUserImageService,
    private consultaService: ConsultaService,
    private cuentaParticularService: CuentaParticularService,
    private usuarioService: UsuarioService){

      //Para actualizar contador de cola de espera
      this.socketColaService.getCola().subscribe(
        response => {
            if(response!=null){
                let respuesta = JSON.parse(response);
                //console.log(respuesta);
                this.cola = respuesta.cola;
                this.atendidos = respuesta.atendidos;
                this.colaCount = this.cola.length;
            }
        }
      );

      this.socketColaService.getCountCola().subscribe(
        response =>{
          let count = JSON.parse(response);
          this.colaCount = count;
          //console.log("Pacientes en cola: ",count);
        }
      );

      //Para actualizar lista de cuentas de consulta médica
      this.socketCuentaService.getCuenta().subscribe(
        response => {
          //No se usa la data enviada por socket solo necesito recibir el evento para listar las cuentas deudoras
          //let cuentaConsulta = JSON.parse(response);
          this.loadCuentas();
        },
        err => {
          // console.log('Error al agregar cuenta de consulta');
        }
      );

      //Para actualizar lista de cuentas de consulta médica
      this.socketCuentaService.getCuentaParticular().subscribe(
        response => {
          //No se usa la data enviada por socket solo necesito recibir el evento para listar las cuentas deudoras
          //let cuentaConsulta = JSON.parse(response);
          this.loadCuentasParticulares();
        },
        err => {
           //console.log('Error al agregar cuenta de consulta');
        }
      );

      this.sockeIndicacionesService.getListaUpdated().subscribe(
        response => {
          this.loadIndicaciones();
        },
        err => {
          //console.log('Error al cargar la lista de indicaciones desde socket');
        }
      );

      //*Recibiendo evento de actualizacion de la imagen del usuario
      this.socketUserImageService.getImageUpdated().subscribe(
        idUser => {
          this.loadUserLogued();
        }
      );


  }

  ngOnInit(): void {

    this.role = this.loginService.getRole();

    switch (this.role) {
      case 'admin':
          this.roleTitle = 'ADMINISTRACIÓN'
        break;
      case 'recepcion':
          this.roleTitle = 'RECEPCIÓN'
        break;

      case 'enfermeria':
          this.roleTitle = 'ENFERMERIA'
        break;
      default:
        break;
    }

    this.loadUserLogued();
    this.loadCola();
    this.loadCuentas();
    this.loadIndicaciones();
    this.loadCuentasParticulares();
  }

  //*Cargando la imagen del usuario
  loadUserLogued(){
    //*Cargando la imagen del usuario
    let userLogged = this.loginService.userLogged();
    let idUser = userLogged._id;
    this.usuarioService.getUsuario(idUser).subscribe(
      response => {
        let usuario = response.usuario;
        if(response.status == 'success'){
          this.userImage = this.url+usuario.imagen || '';
        }
      },
      error => {
        this.userImage = '../../../../assets//imgs/user-general.png'
      }
    );
  }

  //*Cuando hay un error al obtener la direccion de la imagen desde el servidor
  CargarImagenPorDefecto(){
    this.userImage = '../../../../assets//imgs/user-general.png';//imagen por defecto
  }


  OnClick(){
    //this.colaService.cargarCola();//emitiendo el evento click mediante un servicio
  }

  //Método para cargar la cola desde la base de datos
  loadCola(){
    this.colaService.getCola().subscribe(
      response => {
          this.cola = response.cola[0].colaDeEspera;
          this.atendidos = response.cola[0].colaDeAtencion;
          this.colaCount = this.cola.length;
      },
      error => {
          //console.log(error);
      }
    );
  }
  //Método para cargar las cuentas deudoras de consulta médica
  loadCuentas(){
    this.cuentaConsultaService.getCuentasDeudoras().subscribe(
      response => {

        if(response.status == 'success'){
          this.deudores = response.cuentasDeudoras;
          //console.log('Cuentas deudoras: ', this.deudores);
          this.cuentaCount = this.deudores.length;
        }

      },
      err => {
        //console.log(err);
      }
    );
  }
  //Método para cargar las cuentas deudoras de estudios particulares
  loadCuentasParticulares(){
    this.cuentaParticularService.getCuentasDeudoras().subscribe(
      response => {
        //console.log(response);
        this.deudoresParticulares = response.cuentasDeudoras;
        this.cuentaParticularCount = this.deudoresParticulares.length;
      },
      error => {
        //console.log("Error al cargar las cuentas dedudoras: ", error);
      }
    );
  }

  //Método para cargar la lista de indicaciones médicas
  loadIndicaciones(){
    this.consultaService.getIndicaciones().subscribe(
      response => {
        this.indicaciones = response.indicaciones[0].lista;
        this.listaCount = this.indicaciones.length;
      },
      error => {
        //console.log('Error al cargar la lista de indicaciones de ingreso', error);
      }
    );
  }


}
