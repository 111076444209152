import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'agregaCero'
})

export class AgregaCero implements PipeTransform{

    transform(numero: number){

        let cadena = numero.toString().padStart(2, '0');

        return cadena;
    }

}
