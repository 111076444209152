import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Global } from '../../../services/global';
import { PdfMakeWrapper, Txt, Table, Cell, Columns, Canvas, Line, Img, Ul, Stack, Item } from 'pdfmake-wrapper';
import * as pdfFonts from "pdfmake/build/vfs_fonts"; // fonts provided for pdfmake


@Injectable({
 providedIn: 'root'
})
export class ToPDFService{
    public url: string;

    constructor(
        private _http: HttpClient
    ){
        this.url = Global.url;
        //*Linea de código para que funcione PDF MAKE WRAPPER
        (window as any).pdfMake.vfs = pdfFonts.pdfMake.vfs;
    }

    //*Método que exporta a PDF los datos de la cuenta general por consulta
    async printCuentaEstudiosParticulares(data: any){

        const pdf = new PdfMakeWrapper();
        pdf.pageSize('A4');
        pdf.pageOrientation('portrait'); // 'landscape'

        pdf.header(
            pdf.add(
              await new Img('../../../../assets/imgs/membrete.PNG').height(150).width(275).absolutePosition(150,10).build()
            )
          );
        pdf.add( pdf.ln(10) );

        pdf.add(new Txt('CUENTA POR ESTUDIOS DE LABORATORIO').bold().fontSize(15).end);

        //*Dibuja una linea como separador
        pdf.add(
            new Canvas([
                new Line([2,2], [510, 2]).end
            ]).bold().end
        );
        pdf.add( pdf.ln(1) );//* Agregando un salto


        if(data.numero){
          pdf.add(new Table([
            [ new Txt('Número de cuenta: ').bold().end, data.numero],
          ]).widths([ 105, 430 ]).layout('noBorders').end);
        }

        pdf.add(new Table([
            [ new Txt('Fecha: ').bold().end, data.fecha],
        ]).widths([ 40, 100 ]).layout('noBorders').end);

        pdf.add(new Table([
            [ new Txt('Cliente: ').bold().end, data.cliente],
        ]).widths([ 45, 430 ]).layout('noBorders').end);

        pdf.add(new Table([
          [ new Txt('Código de cliente: ').bold().end, data.codigo],
        ]).widths([ 100, 430 ]).layout('noBorders').end);

        pdf.add(new Table([
            [ new Txt('Cuenta total: ').bold().end, '$'+data.cuentaTotal],
        ]).widths([ 70, 80 ]).layout('noBorders').end);

        pdf.add(new Table([
            [ new Txt('Estado: ').bold().end, data.estado],
        ]).widths([ 42, 108 ]).layout('noBorders').end);

        pdf.add( pdf.ln(1) );

        pdf.add(new Table([
            [ new Txt('Lista de cuenta ').bold().end],
        ]).widths([ 498 ]).end);

        pdf.add(new Table([
            [ new Txt('#').bold().end, new Txt('A nombre de:').bold().end, new Txt('Precio:').bold().end],
        ]).widths([ 30, 350, 100 ]).end);

        data.cuenta.forEach((x, index) => {
            let examenes = [];
            let estudios = x.id_detalle.estudios;
            estudios.forEach(y => {
              y.examenes.forEach(ex => {
                  let precio = ex.precio ? ': $'+ex.precio : '';
                  examenes.push(ex.nombre+precio);
              });
            });
            pdf.add(new Table([
                [ new Cell( new Txt((index + 1) + '.').end ).rowSpan(3).end, 'Código de orden: '+x.numeroOrden, new Cell(new Txt('$'+(x.precio.toFixed(2))).end).rowSpan(3).end],
                [ null,new Cell(new Txt(x.nombre).end).colSpan(1).end],
                [ null, new Cell(new Ul(examenes).end).colSpan(1).end]
            ]).widths([ 30, 350, 100 ]).end);
        });

        pdf.add(new Table([
            [ new Txt('Total a pagar:').bold().end, new Txt('$'+data.cuentaTotal).bold().end],
        ]).widths([ 389, 100 ]).end);

        pdf.add( pdf.ln(1) );

        pdf.add(new Table([
            [ new Txt('Lista de pagos ').bold().end],
        ]).widths([ 498 ]).end);

        pdf.add(new Table([
            [ new Txt('# ').bold().end,
              new Txt('Fecha: ').bold().end,
              new Txt('Monto abonado:').bold().end,
              new Txt('Resgistró:').bold().end],
        ]).widths([ 30, 70 , 140, 231 ]).end);

        if(data.abonos.length > 0){
          data.abonos.forEach((y, index) => {
              pdf.add(new Table([
                  [ index + 1 + '.',
                    this.printFecha(y.fecha),
                    '$'+(y.monto.toFixed(2)),
                    y.registro],
              ]).widths([ 30, 70 , 140, 231 ]).end);
          });
        }else{
          pdf.add(new Table([
            [ new Cell( new Txt('No hay pagos realizados').alignment('center').end ).colSpan(4).end,  ],
          ]).widths([ 30, 70 , 140, 231 ]).end);
        }

        pdf.add(new Table([
            [ new Txt('Total abonado:').bold().end,
              new Txt('$'+data.pagoTotal).bold().end,
              new Txt('Pendiente: $'+data.resto).bold().end],
        ]).widths([ 109 , 140, 231 ]).end);

        let fechaEmision = this.printFechaFull(new Date());

        pdf.footer(
            new Txt(fechaEmision).margin(15).color('gray').end
        );

        pdf.create().open();

    }

    //*Método para imprimir datos del cliente por ordenes particulares
    async printDatosCliente(data: any){
      const pdf = new PdfMakeWrapper();
      pdf.pageSize('A4');
      pdf.pageOrientation('portrait'); // 'landscape'

      pdf.header(
          pdf.add(
            await new Img('../../../../assets/imgs/membrete.PNG').height(150).width(275).absolutePosition(150,10).build()
          )
        );
      pdf.add( pdf.ln(10) );

      pdf.add(new Txt('DATOS GENERALES DEL CLIENTE').bold().fontSize(15).end);

      //*Dibuja una linea como separador
      pdf.add(
          new Canvas([
              new Line([2,2], [510, 2]).end
          ]).bold().end
      );
      pdf.add( pdf.ln(1) );//* Agregando un salto

      pdf.add(new Table([
        [ new Txt('CÓDIGO: ').bold().end, data.codigo],
      ]).widths([ 48, 160 ]).layout('noBorders').end);

      pdf.add(new Table([
        [ new Txt('NOMBRE: ').bold().end, data.nombre],
      ]).widths([ 55, 430 ]).layout('noBorders').end);

      pdf.add(new Table([
        [
          new Txt('FECHA DE NACIMIENTO: ').bold().end, this.printFecha(data.fechaNac),
          new Txt('SEXO: ').bold().end, data.genero,
          new Txt('EDAD: ').bold().end, data.edad
        ]
      ]).widths([ 135, 100,  35, 25, 35, 60 ]).layout('noBorders').end);

      pdf.add(new Table([
        [
          new Txt('TELÉFONO: ').bold().end, data.telefono
        ],
      ]).widths([ 66, 60 ]).layout('noBorders').end);

      pdf.add(new Table([
        [ new Txt('EMAIL: ').bold().end, data.email],
      ]).widths([ 40, 430 ]).layout('noBorders').end);

      pdf.add( pdf.ln(1) );

      pdf.add(new Table([
        [ new Txt('NOTAS: ').bold().end, data.notas],
      ]).widths([ 40, 465 ]).layout('noBorders').end);

      let fechaEmision = this.printFechaFull(new Date());

      pdf.footer(
          new Txt(fechaEmision).margin(15).color('gray').end
      );

      pdf.create().open();
    }

     //*Método para exportar a PDF los datos de la selección de estudios de laboratorio
     async printEstudiosSeleccionados(dataPrint){

      let data = dataPrint.estudios;
      let datosClinicos = dataPrint.datos;

      const pdf = new PdfMakeWrapper();
        pdf.pageSize('A4');
        pdf.pageOrientation('portrait'); // 'landscape'

        pdf.header(
            pdf.add(
              await new Img('../../../assets/imgs/membrete2.png').height(160).width(285).absolutePosition(150,10).build()
            )
        );

        pdf.add( pdf.ln(9) );//* Agregando un salto
        pdf.add(new Txt('Teléfonos: 7212-2156 y 7898-4503 ').alignment('center').fontSize(9).end);
        pdf.add( pdf.ln(1) );

        pdf.add(
          new Txt('ESTUDIOS DE LABORATORORIO CLÍNICO').bold().fontSize(13).end,
        );
        pdf.add(
          new Canvas([
              new Line([2,2], [510, 2]).end
          ]).bold().end
        ),
        pdf.add( pdf.ln(1) )//* Agregando un salto

        pdf.add(new Table([
          [ new Txt('N° de orden: ').bold().end, dataPrint.numeroOrden],
        ]).widths([ 70, 90 ]).layout('noBorders').end);

        pdf.add(new Table([
          [ new Txt('Fecha: ').bold().end, this.printFecha(dataPrint.fecha)],
        ]).widths([ 40, 90 ]).layout('noBorders').end);

        pdf.add(new Table([
          [ new Txt('Cliente: ').bold().end, dataPrint.cliente],
        ]).widths([ 43, 420 ]).layout('noBorders').end);

        pdf.add(new Table([
          [ new Txt('Edad: ').bold().end, dataPrint.edad],
        ]).widths([ 30, 90 ]).layout('noBorders').end);

        pdf.add( pdf.ln(1) )//* Agregando un salto

       for (let i = 0; i < data.length; i+=3) {
        let subArray = data.slice(i, i+3);
        let titulos = subArray.map(x => new Txt(x.nombre).bold().end);
        let examenes = subArray.map(y =>
          y.examenes.map(z => z.seleccionado ? new Item(new Txt(z.nombre).bold().end).listType('square').end : new Item(new Txt(z.nombre).end).listType('none').end)
        );
        let mostrarExamenes = [];
        examenes.forEach(element => {
            mostrarExamenes.push(
              new Ul(element).end
            );
        });

        pdf.add(new Table([
          titulos,
          mostrarExamenes
        ]).fontSize(13).widths([ 170, 170, 170]).layout('noBorders').end);

        pdf.add( pdf.ln(1) )//* Agregando un salto

       }

       pdf.add( pdf.ln(1) )//* Agregando un salto

       pdf.add(
        new Table([
          [new Txt('OTRAS PRUEBAS ESPECIALES / DATOS CLÍNICOS:').bold().end],
          [datosClinicos]
        ]).fontSize(13).widths([ 500 ]).layout('noBorders').end
       );


         //*Footer o pie de pagina
         let fechaEmision = this.printFechaFull(new Date());
         pdf.footer(
             new Txt(fechaEmision).margin(15).color('gray').end
         );
         pdf.create().open();
    }


    printFecha(fechaParam) {//* Devuelve la fecha en un formato string dd/mm/yyyy
        var fechaNac = new Date(fechaParam);

        var dd = fechaNac.getDate();
        var mm = fechaNac.getMonth() + 1; //January is 0!
        var yyyy = fechaNac.getFullYear();

        var dia = dd.toString();
        if (dd < 10) {
          dia = "0" + dd;
        }
        var mes = mm.toString();
        if (mm < 10) {
          mes = "0" + mm;
        }
        //console.log(yyyy + "-" + mes + "-" + dia);
        return dia + "/" + mes + "/" + yyyy;
    }

    printFechaFull(fechaParam) {//* Método para imprimir fecha y hora de emisión del informe

        var fechaNac = new Date(fechaParam);

        var seg = fechaNac.getSeconds();
        var min = fechaNac.getMinutes();
        var hora = fechaNac.getHours();
        var dd = fechaNac.getDate();
        var mm = fechaNac.getMonth() + 1; //January is 0!
        var yyyy = fechaNac.getFullYear();

        var dia = dd.toString();
        if (dd < 10) {
          dia = "0" + dd;
        }
        var mes = mm.toString();
        if (mm < 10) {
          mes = "0" + mm;
        }
        var segundo = seg.toString();
        if(seg < 10){
          segundo = '0'+seg.toString();
        }else{
            segundo = seg.toString();
        }
        var minuto = min.toString();
        if(min < 10){
            minuto = '0'+min.toString();
        }else{
            minuto = min.toString();
        }
        //console.log(yyyy + "-" + mes + "-" + dia);
        return 'Fecha: '+dia + "-" + mes + "-" + yyyy+' Hora: '+hora+":"+minuto+":"+segundo;
    }

}
