<ngx-smart-modal #userImageModal identifier="userImageModal" [customClass]="'modal-lg'" [dismissable] = "false" [closable] = "false" >

  <div class="modal-body">

    <div class="row" *ngIf="!imagenExists">
      <div class="col-md-6 col-sm-6 col-12">
        <div class="row d-flex align-items-center justify-content-start">
          <div>
            <p class="h3">{{ fullName }}</p>
            <p  class="h3 widget-user-desc" style="color: grey; font-size: 20px;">
              Usuario: {{ userName }} <br>
              Rol: {{ userRole }} <br>
              Estado: {{ state == 'activo' ? 'Activo' : 'Inactivo' }}
            </p>
          </div>
        </div>
        <div class="row">
          <p class="text-success">Agrega una foto del usuario!</p>
        </div>
        <div class="row">
          <angular-file-uploader
            [config]="afuConfig"
            resetUpload="false"
            (ApiResponse)="onUploadImage($event)"
            >
          </angular-file-uploader>
        </div>
      </div>
      <div class="col-md-6 col-sm-6 col-12">
        <div class="row justify-content-center">
          <img
              src="../../../../assets//imgs/user-general.png"
              class="img-fluid img-circle img-bordered-sm"
              alt="Imagen del usuario"
              style="width: 300px; height: 300px;"
              />
        </div>
      </div>
    </div>
    <div class="row" *ngIf="imagenExists">
      <div class="col-md-6 col-sm-6 col-12 d-flex align-items-center justify-content-start">
        <div>
          <img class="img-fluid"
               src="../../../../assets//imgs/sinai_logo.jpg"
               alt="Logo mount sinaí"
               style="width: 325px !important;
                      height: 120px !important;"/>
          <p class="h3">{{ fullName }}</p>
          <p  class="h3 widget-user-desc" style="color: grey; font-size: 20px;">
            Usuario: {{ userName }} <br>
            Rol: {{ userRole }} <br>
            Estado: {{ state == 'activo' ? 'Activo' : 'Inactivo' }}
          </p>
        </div>
      </div>
      <div class="col-md-6 col-sm-6 col-12">
        <div class="row justify-content-center">
          <img class="img-fluid img-circle img-bordered-sm"
               src="{{ pathImagen }}"
               (error)="cargarImagenPorDefecto()"
               alt="user"
               style="width: 300px !important;
                      height: 300px !important;
                      object-fit: cover !important;" />
        </div>
      </div>

    </div>

  </div>
  <div class="modal-footer d-none d-sm-block">

    <button class="btn btn-default btn-sm float-left"
            *ngIf="imagenExists"
            (click)="deleteImage()">
      <i class="fas fa-times"></i>
      Eliminar imagen
    </button>

    <button class="btn btn-secondary float-right" (click)="userImageModal.close();">
      Cerrar
    </button>

  </div>


</ngx-smart-modal>

<swal
      #confirmarDeleteImagenUser2
      title="¿Deseas eliminar la imagen del registro de usuario?"
      text="Se eliminará la imagen de usuario de forma permanente."
      icon="warning"
      [showCancelButton]="true"
      confirmButtonText= "Si!"
      cancelButtonText= "No!"
      confirmButtonColor= "#3085d6"
      cancelButtonColor= "#bbbbbb"
      [focusCancel]="true">
</swal>
