import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../../../auth/services/usuario.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { ConfirmaPasswordService } from '../../../services/confirma-password.service';

@Component({
  selector: 'app-confirma-password',
  templateUrl: './confirma-password.component.html',
  styleUrls: ['./confirma-password.component.css']
})
export class ConfirmaPasswordComponent implements OnInit {

  idUserLogged = '';
  password = '';
  procesando = false;

  constructor(
    private usuarioService: UsuarioService,
    public ngxSmartModal: NgxSmartModalService,
    private toastr: ToastrService,
    private matchService: ConfirmaPasswordService
    ) { }

  ngOnInit(): void {
    this.matchService.loadMatchModal().subscribe(
      idUser => {
        this.idUserLogged = idUser;
        this.ngxSmartModal.getModal('confirmaPassModal').open();
      }
    );
  }

  validar(){
    if(this.password.length > 0){
      this.procesando = true;
      //Preparando el objeto
      let data = {
        userId: this.idUserLogged,
        pass: this.password
      }
      //Petición para comparar las passwords
      this.usuarioService.validaPass(data).subscribe(
        response => {
            //console.log("Validación de password: ", response);
            let match = response.match;
            if(match == true){
              this.matchService.loadMatch(true);//*Coinciden
              this.ngxSmartModal.getModal('confirmaPassModal').close();
              this.cleanData();
              this.procesando = false;
            }else{
              this.matchService.loadMatch(false);//*No coinciden
              this.procesando = false;
            }
        },
        err => {
            //console.log('Error al tratar de confirmar password', err);
            this.errorMessage("Error al confirmar contraseña","Contraseña inválida");
        }
      );
    }else{
      this.errorMessage("El campo está vacío","Contraseña inválida");
    }
  }

  cleanData(){
    this.idUserLogged = '';
    this.password = '';
  }

  errorMessage(mensaje: string, titulo: string){
    this.toastr.error(mensaje, titulo);
  }



}
