<!-- Navbar -->
<nav class="main-header navbar navbar-expand-md navbar-info navbar-light">

  <!-- Left navbar links -->
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
    </li>
    <li class="nav-item d-none d-sm-inline-block">
      <a [routerLink]="[link]" class="nav-link">Home</a>
    </li>

  </ul>



  <!-- Right navbar links -->
  <ul class="navbar-nav ml-auto">

    <!-- Notifications Dropdown Menu
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-bell fa-lg"></i>
          <span class="badge badge-light navbar-badge">{{ notificaciones.length }}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <span class="dropdown-item dropdown-header">
            <i class="fas fa-bell fa-3x mr-2"></i><br>
            <strong>Notificaciones</strong>
          </span>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" *ngIf="notificaciones.length == 0">
            <i class="fas fa-exclamation-circle mr-2"></i> No hay nuevas notificaciones
          </a>
          <a href="#" class="dropdown-item" *ngFor="let noti of notificaciones">
            <i [class]="noti.icono"></i> {{ noti.texto }}
          </a>
        </div>
      </li> -->

    <!-- Usuario Dropdown Menu -->
    <li class="nav-item dropdown">
      <a class="nav-link" data-toggle="dropdown" href="#">
        <i class="far fa-user-circle fa-lg"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <span class="dropdown-item dropdown-header">
          <img class="img-circle img-bordered-sm" style="width: 70px; height: 70px;" [src]="userImage"
            (error)="CargarImagenPorDefecto()" alt="User Image">
          <br>
          <strong>Usuario {{ userType }}</strong><br>
          <p>{{ user }}</p>
          <p>{{ names }}</p>
        </span>
        <div class="dropdown-divider"></div>
        <a [routerLink]="[ruta]" class="dropdown-item">
          <i class="fas fa-user mr-2"></i> Perfil de usuario
        </a>
        <a [routerLink]="[ruta]" class="dropdown-item" *ngIf="userType =='Administrador'">
          <i class="fas fa-cogs mr-2"></i> Configuración del sistema
        </a>
        <div class="dropdown-divider"></div>
        <a [routerLink]="['/']" class="dropdown-item" (click)="logout()">
          <i class="fas fa-sign-out-alt mr-2"></i> Cerrar sesión
        </a>
      </div>
    </li>

  </ul>

</nav>
<!-- /.navbar -->
