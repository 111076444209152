<ngx-smart-modal  #confirmaPassModal identifier="confirmaPassModal" [customClass]="'modal-dialog modal-dialog-centered'" [dismissable] = "false" [closable] = "false" >

  <div class="modal-body justify-content-center" style="text-align: center;">
    <h3>Confirma tu contraseña <i class="fas fa-key"></i></h3>
    <h5>Por favor ingresa tu contraseña para continuar </h5>
    <br>
    <div class="form-group">
      <input type="password"
            autofocus
            #pass="ngModel"
            [(ngModel)]="password"
            class="form-control form-control-lg"
            placeholder="Contraseña">
    </div>
  </div>

  <div class="modal-footer justify-content-center">

    <button class="btn btn-info btn-lg" (click)="validar()">
        Aceptar
    </button>

    <button class="btn btn-secondary btn-lg ml-3 mr-0" (click)="confirmaPassModal.close(); cleanData()">
        Cancelar
    </button>
  </div>

</ngx-smart-modal>
