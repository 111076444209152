import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Global } from '../../../services/global'
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CuentaParticularService {

  public url: string;

  constructor(
    private _http: HttpClient
  ) {
    this.url = Global.url;
  }

  //*Método para crear una cuenta de estudios particulares
  crearCuenta(cuenta):Observable<any>{

    let params = JSON.stringify(cuenta);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post(this.url+'cuenta-particular/save', params, {headers: headers});
  }

  //*Método para obtener cuentas que deben por estudios particulares
  getCuentasDeudoras():Observable<any>{
    return this._http.get(this.url+'cuenta-particular/getDeudores');
  }

  //*Método para actualizar la cuenta de por estudios particulares
  updateCuenta(id, data):Observable<any>{
    let params = JSON.stringify(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.put(this.url+'cuenta-particular/update-cuenta/'+id, params, {headers: headers});
  }

  //*Método para retornar los ingresos del mes actual
  getGananciasTotales():Observable<any>{
    let fechaActual = new Date();//*Enviando la fecha actual para calcular el periodo de los datos a mostrar
    return this._http.get(this.url+'cuenta-particular/getGanancias/'+fechaActual);
  }

  //*Método para listar todas las cuentas que tiene un cliente
  getCuentasPorCliente(id, page):Observable<any>{
    let params = new HttpParams().set('page', page);
    return this._http.get(this.url+'cuenta-particular/getCuentas-por-cliente/'+id, {params});
  }

  //*Método para listar todas las ganancias por examemenes particulares por mes del año actual
  getGananciasPorMes():Observable<any>{//Retorna los ingresos por mes del presente año
    let fechaActual = new Date();//*Enviando la fecha actual para calcular el periodo de los datos a mostrar
    return this._http.get(this.url+'cuenta-particular/getGananciasPorMes/'+fechaActual);
  }

  //*Método para listar todas las ganancias por examenes particulares del año pasado y el actual
  getGananciasPorMesPeriodoAnterior():Observable<any>{//Retorna los ingresos por mes del año anterior
    let fechaActual = new Date();//*Enviando la fecha actual para calcular el periodo de los datos a mostrar
    return this._http.get(this.url+'cuenta-particular/getGananciasPorMes-periodoAnterior/'+fechaActual);
  }


}
