import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Global } from '../../../services/global';

@Injectable({
    providedIn: 'root'
   })
export class CuentaService{
    public url: string;
    constructor(
        private _http: HttpClient
    ){
        this.url = Global.url;
    }

    getCuenta(cuentaId):Observable<any>{
        return this._http.get(this.url+'cuenta/obtener/'+cuentaId);
    }

    listarCuentas():Observable<any>{
        return this._http.get(this.url+'paciente/listar-deudores');
    }

    create(cuenta):Observable<any>{
        
        let params = JSON.stringify(cuenta);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        
        return this._http.post(this.url+'cuenta/save', params, {headers: headers});
    }
    update(id, cuenta):Observable<any>{

        let params = JSON.stringify(cuenta);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.put(this.url+'cuenta/update/'+id, params, {headers: headers});
    }
    getCuentas(pacienteId):Observable<any>{
        return this._http.get(this.url+'cuenta/search/'+pacienteId);
    }

    delete(id):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._http.delete(this.url+'cuenta/delete/'+id, {headers: headers});
    }

}