import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';

@Injectable({
 providedIn: 'root'
})
export class ConfirmaPasswordService{
    private subUserData = new Subject<any>();//*Aqui se almacenaran los datos del usuario
    private subResponse = new Subject<any>();//*Aqui se almacenara la respuesta del componente que compara passwords
    constructor(){}

    //*Métodos para enviar datos a otros componentes

    confirmarPassword(idUser){//*Este método se llamará cuando se desee confirmar usuario y contraseña desde alguna vista
        this.subUserData.next(idUser); //*Se almacena el evento para lanzar el modal
    }

    loadMatchModal(): Observable<any>{//*Este método devuelve un observable que lee el evento
        return this.subUserData.asObservable();
    }

    loadMatch(respuesta){//*Este método se llamará cuando el componente que compara contraseñas retorne una respuesta
        this.subResponse.next(respuesta);//*Devolviendo la respuesta, que sera true o false
    }

    getMatchPasswordResponse(): Observable<any>{//*Este método devuelve un observable con la repsuesta del match de contraseñas
        return this.subResponse.asObservable();
    }

}
