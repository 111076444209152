import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Global } from '../../../services/global';
import { PdfMakeWrapper, Txt, Table, Cell, Columns, Canvas, Line, Img, Ul, Stack, Item } from 'pdfmake-wrapper';
import * as pdfFonts from "pdfmake/build/vfs_fonts"; // fonts provided for pdfmake

@Injectable({
 providedIn: 'root',
})
export class ToPDFService{
    public url: string;

    constructor(
        private _http: HttpClient
    ){
        this.url = Global.url;
        //*Linea de código para que funcione PDF MAKE WRAPPER
        (window as any).pdfMake.vfs = pdfFonts.pdfMake.vfs;
    }

    //*Método que exporta a PDF los datos de la cuenta general por consulta
    async printCuentaConsulta(data: any){

        const pdf = new PdfMakeWrapper();
        pdf.pageSize('A4');
        pdf.pageOrientation('portrait'); // 'landscape'

        pdf.header(
            pdf.add(
              await new Img('../../../assets/imgs/membrete2.png').height(160).width(285).absolutePosition(150,10).build()
            )
          );
        pdf.add( pdf.ln(9) );//* Agregando un salto
        pdf.add(new Txt('Teléfonos: 7212-2156 y 7898-4503 ').alignment('center').fontSize(9).end);
        pdf.add( pdf.ln(1) );

        pdf.add(new Txt('CUENTA DE CONSULTA MÉDICA').bold().fontSize(15).end);

        //*Dibuja una linea como separador
        pdf.add(
            new Canvas([
                new Line([2,2], [510, 2]).end
            ]).bold().end
        );
        pdf.add( pdf.ln(1) );//* Agregando un salto

        pdf.add(new Table([
          [ new Txt('Número de cuenta: ').bold().end, data.numero],
        ]).widths([ 104, 420 ]).layout('noBorders').end);

        pdf.add(new Table([
            [ new Txt('Fecha de consulta: ').bold().end, data.fecha],
        ]).widths([ 104, 90 ]).layout('noBorders').end);

        pdf.add(new Table([
            [ new Txt('Paciente: ').bold().end, data.paciente],
        ]).widths([ 53, 420 ]).layout('noBorders').end);

        pdf.add(new Table([
          [ new Txt('Expediente: ').bold().end, data.expediente],
        ]).widths([ 63, 90 ]).layout('noBorders').end);

        pdf.add(new Table([
            [ new Txt('Cuenta total: ').bold().end, '$'+data.cuentaTotal.toFixed(2)],
        ]).widths([ 70, 80 ]).layout('noBorders').end);

        pdf.add(new Table([
            [ new Txt('Estado: ').bold().end, data.estado],
        ]).widths([ 42, 108 ]).layout('noBorders').end);

        pdf.add( pdf.ln(1) );

        pdf.add(new Table([
            [ new Txt('Lista de cuenta ').bold().end],
        ]).widths([ 498 ]).end);

        pdf.add(new Table([
            [ new Txt('# ').bold().end, new Txt('Detalle por:').bold().end, new Txt('Precio:').bold().end],
        ]).widths([ 30, 350, 100 ]).end);

        data.cuenta.forEach((x, index) => {
            if(x.concepto.toUpperCase() == 'ESTUDIOS CLÍNICOS'){//Si hay estudios adjuntos a la cuenta
              let examenes = [];
              let estudios = x.id_detalle.estudios;
              estudios.forEach(y => {
                y.examenes.forEach(ex => {
                    examenes.push(ex.nombre+': $'+ex.precio);
                });
              });
              pdf.add(new Table([
                [ new Cell( new Txt((index + 1) + '.').end ).rowSpan(2).end , x.concepto, new Cell( new Txt('$'+x.precio.toFixed(2)).end ).rowSpan(2).end],
                [ null, new Cell( new Ul(examenes).end ).colSpan(1).end, null ]
              ]).widths([ 30, 350, 100 ]).end);
            }else{

              pdf.add(new Table([
                  [ (index + 1) + '.', x.concepto, '$'+x.precio.toFixed(2)],
              ]).widths([ 30, 350, 100 ]).end);
            }
        });

        pdf.add(new Table([
            [ new Txt('Total a pagar:').bold().end, new Txt('$'+data.cuentaTotal.toFixed(2)).bold().end],
        ]).widths([ 389, 100 ]).end);

        pdf.add( pdf.ln(1) );

        pdf.add(new Table([
            [ new Txt('Lista de pagos ').bold().end],
        ]).widths([ 498 ]).end);

        pdf.add(new Table([
            [ new Txt('# ').bold().end,
              new Txt('Fecha: ').bold().end,
              new Txt('Monto abonado:').bold().end,
              new Txt('Resgistró:').bold().end],
        ]).widths([ 30, 70 , 140, 231 ]).end);

        if(data.abonos.length > 0){
          data.abonos.forEach((y, index) => {
              pdf.add(new Table([
                  [ index + 1,
                    this.printFecha(y.fecha),
                    '$'+y.monto.toFixed(2),
                    y.registro],
              ]).widths([ 30, 70 , 140, 231 ]).end);
          });
        }else{
          pdf.add(new Table([
            [ new Cell( new Txt('No hay pagos realizados').alignment('center').end ).colSpan(4).end ],
          ]).widths([ 30, 70 , 140, 231 ]).end);
        }


        pdf.add(new Table([
            [ new Txt('Total abonado:').bold().end,
              new Txt('$'+data.pagoTotal.toFixed(2)).bold().end,
              new Txt('Pendiente: $'+data.resto).bold().end],
        ]).widths([ 109 , 140, 231 ]).end);




        let fechaEmision = this.printFechaFull(new Date());

        pdf.footer(
            new Txt(fechaEmision).margin(15).color('gray').end
        );

        pdf.create().open();

    }

    //*Método que exporta a PDF los datos del formulario de consulta médica
    async printDatosConsulta(data: any){

        const pdf = new PdfMakeWrapper();
        pdf.pageSize('A4');
        pdf.pageOrientation('portrait'); // 'landscape'

        pdf.header(
            [

                pdf.add(
                    await new Img('../../../assets/imgs/membrete.PNG').height(150).width(275).absolutePosition(150,10).build()
                ),
                pdf.add( pdf.ln(10) ),
                pdf.add(new Table([
                    [
                      new Txt('HOJA DE CONSULTA MÉDICA').bold().fontSize(13).end,
                      new Txt('').bold().fontSize(10).end,
                      new Txt('EXPEDIENTE: '+data.exp).bold().fontSize(13).end
                    ],
                ]).widths([ 175 , 140, 167 ]).layout('noBorders').end),
                pdf.add(
                    new Canvas([
                        new Line([2,2], [510, 2]).end
                    ]).bold().end
                ),
                pdf.add( pdf.ln(1) )//* Agregando un salto
           ]
        );

        pdf.add(new Table([
            [ new Txt('Paciente: ').bold().end, data.nomPaciente],
        ]).widths([ 56, 420 ]).layout('noBorders').end);

        pdf.add(new Table([
            [ new Txt('Edad: ').bold().end, data.edad,
              new Txt('Sexo: ').bold().end, data.genero],
        ]).widths([ 30, 60, 30, 200 ]).layout('noBorders').end);

        pdf.add(new Table([
            [ new Txt('Médico: ').bold().end, data.nomMedico],
        ]).widths([ 48, 420 ]).layout('noBorders').end);

        pdf.add( pdf.ln(1) )//* Agregando un salto

        pdf.add(new Table([
            [ new Txt('Fecha de consulta: ').bold().end, data.fechaCon],
        ]).widths([ 108, 90 ]).layout('noBorders').end);

        pdf.add(new Table([
            [ new Txt('Motivo: ').bold().end, data.motivo],
        ]).widths([ 43, 300 ]).layout('noBorders').end);

        pdf.add(new Table([
            [ new Txt('Tiempo de sintomatología: ').bold().end, ' desde hace '+data.tiemSintoma],
        ]).widths([ 150, 180 ]).layout('noBorders').end);

        if((data.historia!='') || data.historia.length > 0){

            pdf.add( pdf.ln(1) )//* Agregando un salto
            pdf.add(new Table([
                [ new Txt('Historia de la enfermedad: ').bold().end],
            ]).widths([ 150 ]).layout('noBorders').end);
            pdf.add(new Table([
                [ data.historia ],
            ]).widths([ 500 ]).layout('noBorders').end);
        }

        if((data.antePatol!='') || data.antePatol.length > 0){

            pdf.add( pdf.ln(1) )//* Agregando un salto
            pdf.add(new Table([
                [ new Txt('Antecedentes patológicos o de enfermedades crónicas: ').bold().end],
            ]).widths([ 500 ]).layout('noBorders').end);
            pdf.add(new Table([
                [ data.antePatol ],
            ]).widths([ 500 ]).layout('noBorders').end);
        }

        pdf.add( pdf.ln(1) );//* Agregando un salto
        pdf.add(new Table([
            [ new Txt('SIGNOS VITALES ').bold().end],
        ]).widths([ 500 ]).layout('noBorders').end);

        //*Dibuja una linea como separador
        pdf.add(
            new Canvas([
                new Line([2,2], [510, 2]).end
            ]).bold().end
        )
        pdf.add( pdf.ln(1) )//* Agregando un salto

        pdf.add(new Table([
            [
              new Txt('Temperatura: ').bold().end,
              new Txt('Frecuencia Cardíaca:').bold().end,
              new Txt('Frecuencia Respiratoria:').bold().end
            ],
        ]).widths([ 167 , 167, 167 ]).layout('noBorders').end);

        let temp = (data.temp!='' || data.temp.length > 0) ? data.temp+'°' : data.temp;
        let freCard = (data.freCard!='' || data.freCard.length > 0) ? data.freCard+' PPM' : data.freCard;
        let freResp = (data.freResp!='' || data.freResp.length > 0) ? data.freResp+' RPM' : data.freResp;
        pdf.add(new Table([
            [
              temp,
              freCard,
              freResp
            ],
        ]).widths([ 167 , 167, 167 ]).layout('noBorders').end);
        pdf.add(new Table([
            [
              new Txt('Peso: ').bold().end,
              new Txt('Talla:').bold().end,
              new Txt('IMC:').bold().end
            ],
        ]).widths([ 167 , 167, 167 ]).layout('noBorders').end);

        let peso = (data.peso!='' || data.peso.length > 0) ? data.peso+' lbs' : data.peso;
        let talla = (data.talla!='' || data.talla.length > 0) ? data.talla+' mts' : data.talla;
        pdf.add(new Table([
            [
              peso,
              talla,
              data.imc
            ],
        ]).widths([ 167 , 167, 167 ]).layout('noBorders').end);
        pdf.add(new Table([
            [
              new Txt('Presión Sistólica: ').bold().end,
              new Txt('Presión Diastólica:').bold().end,
              new Txt('Presión Arterial:').bold().end
            ],
        ]).widths([ 167 , 167, 167 ]).layout('noBorders').end);

        let preSis = (data.preSis!='' || data.preSis.length > 0) ? data.preSis+' mm Hg' : data.preSis;
        let preDia = (data.preDia!='' || data.preDia.length > 0) ? data.preDia+' mm Hg' : data.preDia;
        let preArt = (data.preArt!='' || data.preArt.length > 0) ? data.preArt+' mm Hg' : data.preArt;
        pdf.add(new Table([
            [
              preSis,
              preDia,
              preArt
            ],
        ]).widths([ 167 , 167, 167 ]).layout('noBorders').end);
        pdf.add(new Table([
            [
              new Txt('Perímetro Cefálico: ').bold().end,
              new Txt('Circunferencia Abdominal:').bold().end,
            ],
        ]).widths([ 167 , 167]).layout('noBorders').end);

        let periCefa = (data.periCefa!='' || data.periCefa.length > 0) ? data.periCefa+' cm' : data.periCefa;
        let cirAb = (data.cirAb!='' || data.cirAb.length > 0) ? data.cirAb+' cm' : data.cirAb;
        pdf.add(new Table([
            [
              periCefa,
              cirAb
            ],
        ]).widths([ 167 , 167]).layout('noBorders').end);

        pdf.add( pdf.ln(1) )//* Agregando un salto
        pdf.add(new Table([
            [ new Txt('EVALUACIÓN CLÍNICA ').bold().end],
        ]).widths([ 500 ]).layout('noBorders').end);

        //*Dibuja una linea como separador
        pdf.add(
            new Canvas([
                new Line([2,2], [510, 2]).end
            ]).bold().end
        )

        if((data.alergias!='') || (data.alergias.length > 0)){

            pdf.add( pdf.ln(1) )//* Agregando un salto
            pdf.add(new Table([
                [ new Txt('Alergias: ').bold().end],
            ]).widths([ 150 ]).layout('noBorders').end);
            pdf.add(new Table([
                [ data.alergias ],
            ]).widths([ 500 ]).layout('noBorders').end);
        }

        if((data.fechaCito!='') || (data.fechaCito.length > 0)){
            pdf.add( pdf.ln(1) )//* Agregando un salto

            pdf.add(new Table([
                [ new Txt('Fecha de citología: ').bold().end, data.fechaCito],
            ]).widths([ 108, 90 ]).layout('noBorders').end);
            pdf.add(new Table([
                [ new Txt('Observación: ').bold().end],
            ]).widths([ 150 ]).layout('noBorders').end);
            pdf.add(new Table([
                [ data.obCito ],
            ]).widths([ 500 ]).layout('noBorders').end);
        }


        pdf.add( pdf.ln(1) )//* Agregando un salto
        pdf.add(new Table([
            [ new Txt('Examen físico: ').bold().end],
        ]).widths([ 150 ]).layout('noBorders').end);
        pdf.add(new Table([
            [ data.examenFisico ],
        ]).widths([ 500 ]).layout('noBorders').end);

        pdf.add( pdf.ln(1) )//* Agregando un salto

        pdf.add(new Table([
            [ new Txt('Diagnóstico: ').bold().end],
        ]).widths([ 150 ]).layout('noBorders').end);
        pdf.add(new Table([
            [ ' ', new Ul(data.diag).end ],
        ]).widths([ 50, 500 ]).layout('noBorders').end);
        //pdf.add(new Ul(data.diag).end);

        pdf.add( pdf.ln(1) )//* Agregando un salto

        pdf.add(new Table([
            [ new Txt('Observaciones: ').bold().end],
        ]).widths([ 150 ]).layout('noBorders').end);
        pdf.add(new Table([
            [ data.observaciones ],
        ]).widths([ 500 ]).layout('noBorders').end);

        pdf.add( pdf.ln(1) )//* Agregando un salto

        //*Footer o pie de pagina
        let fechaEmision = this.printFechaFull(new Date());
        pdf.footer(
            new Txt(fechaEmision).margin(15).color('gray').end
        );
        pdf.create().open();
    }

    //*Metodo que exporta a PDF los datos de la indicación médica
    async printIndicacionMedica(data: any){

        const pdf = new PdfMakeWrapper();
        pdf.pageSize('A4');
        pdf.pageOrientation('portrait'); // 'landscape'

        let fecha = this.printFecha(data.fecha);

        pdf.add(new Table([
            [ new Txt('Fecha: ').bold().end, fecha],
        ]).widths([ 40, 420 ]).layout('noBorders').end);

        pdf.add(new Table([
            [ new Txt('Expediente: ').bold().end, data.exp],
        ]).widths([ 65, 420 ]).layout('noBorders').end);

        pdf.add(new Table([
            [ new Txt('Paciente: ').bold().end, data.paciente],
        ]).widths([ 56, 420 ]).layout('noBorders').end);

        //*Dibuja una linea como separador
        pdf.add(
            new Canvas([
                new Line([2,2], [510, 2]).end
            ]).bold().end
        );
        pdf.add( pdf.ln(1) );

        pdf.add(new Table([
            [ new Txt('Indicaciones: ').bold().end],
        ]).widths([ 150 ]).layout('noBorders').end);
        pdf.add(new Table([
            [ data.detalles ],
        ]).widths([ 500 ]).layout('noBorders').end);

        //*Footer o pie de pagina
        let fechaEmision = this.printFechaFull(new Date());
        pdf.footer(
            new Txt(fechaEmision).margin(15).color('gray').end
        );

        pdf.create().open();

    }

    //*Método que exporta a PDF los datos de la indicación médica
    async printRecetaMedica(data: any){
        const pdf = new PdfMakeWrapper();
        pdf.pageSize('A4');
        pdf.pageOrientation('portrait'); // 'landscape'

        pdf.header(
            pdf.add(
              await new Img('../../../assets/imgs/membrete2.png').height(160).width(285).absolutePosition(150,10).build()
            )
        );

        pdf.add( pdf.ln(9) );//* Agregando un salto
        pdf.add(new Txt('Teléfonos: 7212-2156 y 7898-4503 ').alignment('center').fontSize(9).end);
        pdf.add( pdf.ln(1) );

        pdf.add(new Table([
            [ new Txt('Fecha de consulta: ').bold().end, data.fechaConsulta],
        ]).widths([ 105, 90 ]).layout('noBorders').end);

        pdf.add(new Table([
            [ new Txt('Nombre del paciente: ').bold().end, data.nombrePaciente],
        ]).widths([ 120, 420 ]).layout('noBorders').end);

        //*Dibuja una linea como separador
        pdf.add(
            new Canvas([
                new Line([2,2], [510, 2]).end
            ]).bold().end
        );
        pdf.add( pdf.ln(1) );//* Agregando un salto

        pdf.add(new Table([
            [ new Txt('Medicamentos recetados: ').bold().end],
        ]).widths([ 420 ]).layout('noBorders').end);
        pdf.add( pdf.ln(1) );//* Agregando un salto
        data.medicamentos.forEach( (x, index) => {

            pdf.add(new Table([
                [ index + 1+'.',
                  x.descripcion.toUpperCase()
                ],
            ]).widths([ 15, 495]).layout('noBorders').end);

            pdf.add( pdf.ln(1) );//* Agregando un salto
        });

        if(data.detalles.length > 0){
            pdf.add( pdf.ln(1) );//* Agregando un salto
            pdf.add(new Table([
                [ new Txt('Detalles: ').bold().end],
            ]).widths([ 420 ]).layout('noBorders').end);

            pdf.add(new Table([
                [ data.detalles ],
            ]).widths([ 500 ]).layout('noBorders').end);

        }


        //*Footer o pie de pagina
        let fechaEmision = this.printFechaFull(new Date());
        pdf.footer(
            new Txt(fechaEmision).margin(15).color('gray').end
        );
        pdf.create().open();
    }

    //*Método para exportar a PDF los datos de la selección de estudios de laboratorio
    async printEstudiosSeleccionados(dataPrint){

      let data = dataPrint.estudios;
      let datosClinicos = dataPrint.datos;

      const pdf = new PdfMakeWrapper();
        pdf.pageSize('A4');
        pdf.pageOrientation('portrait'); // 'landscape'

        pdf.header(
            pdf.add(
              await new Img('../../../assets/imgs/membrete2.png').height(160).width(285).absolutePosition(150,10).build()
            )
        );

        pdf.add( pdf.ln(9) );//* Agregando un salto
        pdf.add(new Txt('Teléfonos: 7212-2156 y 7898-4503 ').alignment('center').fontSize(9).end);
        pdf.add( pdf.ln(1) );

        pdf.add(
          new Txt('ESTUDIOS DE LABORATORORIO CLÍNICO').bold().fontSize(13).end,
        );
        pdf.add(
          new Canvas([
              new Line([2,2], [510, 2]).end
          ]).bold().end
        ),
        pdf.add( pdf.ln(1) )//* Agregando un salto

        pdf.add(new Table([
          [ new Txt('Fecha de consulta: ').bold().end, dataPrint.fecha],
        ]).widths([ 104, 90 ]).layout('noBorders').end);

        pdf.add(new Table([
          [ new Txt('Paciente: ').bold().end, dataPrint.paciente],
        ]).widths([ 53, 420 ]).layout('noBorders').end);

        pdf.add(new Table([
          [ new Txt('Expediente: ').bold().end, dataPrint.expediente],
        ]).widths([ 63, 90 ]).layout('noBorders').end);

        pdf.add( pdf.ln(1) )//* Agregando un salto

       for (let i = 0; i < data.length; i+=3) {
        let subArray = data.slice(i, i+3);
        let titulos = subArray.map(x => new Txt(x.nombre).bold().end);
        let examenes = subArray.map(y =>
          y.examenes.map(z => z.seleccionado ? new Item(new Txt(z.nombre).bold().end).listType('square').end : new Item(new Txt(z.nombre).end).listType('none').end)
        );
        let mostrarExamenes = [];
        examenes.forEach(element => {
            mostrarExamenes.push(
              new Ul(element).end
            );
        });

        pdf.add(new Table([
          titulos,
          mostrarExamenes
        ]).fontSize(13).widths([ 170, 170, 170]).layout('noBorders').end);

        pdf.add( pdf.ln(1) )//* Agregando un salto

       }

       pdf.add( pdf.ln(1) )//* Agregando un salto

       pdf.add(
        new Table([
          [new Txt('OTRAS PRUEBAS ESPECIALES / DATOS CLÍNICOS:').bold().end],
          [datosClinicos]
        ]).fontSize(13).widths([ 500 ]).layout('noBorders').end
       );


         //*Footer o pie de pagina
         let fechaEmision = this.printFechaFull(new Date());
         pdf.footer(
             new Txt(fechaEmision).margin(15).color('gray').end
         );
         pdf.create().open();
    }

    printFecha(fechaParam) {//* Devuelve la fecha en un formato string dd/mm/yyyy
        var fechaNac = new Date(fechaParam);

        var dd = fechaNac.getDate();
        var mm = fechaNac.getMonth() + 1; //January is 0!
        var yyyy = fechaNac.getFullYear();

        var dia = dd.toString();
        if (dd < 10) {
          dia = "0" + dd;
        }
        var mes = mm.toString();
        if (mm < 10) {
          mes = "0" + mm;
        }
        //console.log(yyyy + "-" + mes + "-" + dia);
        return dia + "/" + mes + "/" + yyyy;
    }

    printFechaFull(fechaParam) {//* Método para imprimir fecha y hora de emisión del informe

        var fechaNac = new Date(fechaParam);

        var seg = fechaNac.getSeconds();
        var min = fechaNac.getMinutes();
        var hora = fechaNac.getHours();
        var dd = fechaNac.getDate();
        var mm = fechaNac.getMonth() + 1; //January is 0!
        var yyyy = fechaNac.getFullYear();

        var dia = dd.toString();
        if (dd < 10) {
          dia = "0" + dd;
        }
        var mes = mm.toString();
        if (mm < 10) {
          mes = "0" + mm;
        }
        var segundo = seg.toString();
        if(seg < 10){
          segundo = '0'+seg.toString();
        }else{
            segundo = seg.toString();
        }
        var minuto = min.toString();
        if(min < 10){
            minuto = '0'+min.toString();
        }else{
            minuto = min.toString();
        }
        //console.log(yyyy + "-" + mes + "-" + dia);
        return 'Fecha: '+dia + "-" + mes + "-" + yyyy+' Hora: '+hora+":"+minuto+":"+segundo;
    }


}
