<!-- Content Header (Page header) -->
<div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6"> 
          <h3 >{{ titulo }} <i class="{{ icono }}"></i> </h3>
        </div><!-- /.col -->
       
        
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            
            <ng-container *ngIf="breadcrumbs.length > 0">

              <li class="breadcrumb-item" *ngFor="let bread of breadcrumbs">
                <a [routerLink]="[bread.link]">{{ bread.titulo }}</a>
              </li>

            </ng-container>
            
            <li class="breadcrumb-item active"><strong>{{ titulo }}</strong></li> 
          </ol>
        </div><!-- /.col -->
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- /.content-header -->
