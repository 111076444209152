import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dosDecimales'
})

export class DosDecimales implements PipeTransform{
  transform(value: any) {
      return value.toFixed(2);
  }
}
