import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';


@Injectable({
  providedIn: 'root'
})
export class SocketCuentaService {

  constructor(private socket: Socket) { }

  //*Métodos para enviar y recibir una cuenta de consulta médica en tiempo real
  sendCuenta(data: any) {
    this.socket.emit('addCuenta', JSON.stringify(data));
  }
  getCuenta() {
    return this.socket.fromEvent<any>('addCuenta');
  }

  //*Métodos para enviar y recibir una cuenta por estudios particulares en tiempo real

  sendCuentaParticular(data: any) {
    this.socket.emit('addCuentaParticular', JSON.stringify(data));
  }
  getCuentaParticular() {
    return this.socket.fromEvent<any>('addCuentaParticular');
  }


}
