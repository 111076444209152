import { Pipe, PipeTransform } from '@angular/core';
import { Hora } from 'src/app/moduloEmpleados/empleados/models/hora';

@Pipe({
    name: 'cantidadHoras'
})

export class CantidadHoras implements PipeTransform{

    transform(horaEntrada: Hora, horaSalida: Hora){

      let minutosInicio = horaEntrada.hour * 60 + horaEntrada.minute;
      let minutosFin = horaSalida.hour * 60 + horaSalida.minute;

      //validando si la hora de fin pertenece al dia siguiente
      if(minutosFin <= minutosInicio){
        minutosFin += 24*60;//Sumando 24 horas en minutos (para hora del dia siguiente)
      }

      let diferenciaMinutos = minutosFin - minutosInicio;

      let horas = Math.floor(diferenciaMinutos / 60);
      let minutos = diferenciaMinutos % 60;

      return horas+"."+minutos;
    }

}
