import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'localDate'
})

export class LocalDate implements PipeTransform{
  transform(value: Date) {
    let localTime = value.toLocaleTimeString('es-SV'); // Obtiene la fecha y hora local actual

      let fecha = new Date(value.setHours(0,0,0,0));

      return fecha;
  }
}
