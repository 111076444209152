import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fechaFormat2'
})

export class FechaFormat2 implements PipeTransform{

    transform(value: any){

      let fechaParam = value;

      if(fechaParam.length > 0){//Validando porque mientras no se cargue la fecha de la BD se recibe un string vacio
        let f = new Date(fechaParam);//Recibiendo la fecha UTC desde el server
        //*Formateando la fecha para que se muestre en zona horaria local dd/mm/yyyy
        let fecha = f.toLocaleDateString('es-SV', {year: 'numeric', month: '2-digit', day: '2-digit', timeZone:'UTC'});
        return fecha;
      }else{
        return '';
      }


    }

}
