import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'estadoCaducidad'
})

export class EstadoCaducidad implements PipeTransform{

    transform(estado: string, fechaCad: Date, diasPrevios: number){
        let clase = 'badge badge-success';//Si todo está bien
        let caducidad = new Date(fechaCad);
        let fechaCaducidad = new Date(fechaCad);//fecha
        let fechaActual = new Date();
        let fechaAlert = new Date(fechaCaducidad.setUTCDate(fechaCaducidad.getUTCDate() - diasPrevios));

        if(fechaActual >= fechaAlert && fechaActual < caducidad){//Si esta dentro de la alerta
          clase = 'badge badge-warning';
        }
        if(fechaActual >= caducidad){//Si ya esta caducado
          clase = 'badge badge-secondary';
        }
        if(estado == 'Sin stock'){
          clase = 'badge badge-secondary';
        }

        return clase;
    }

}
