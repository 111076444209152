import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Global } from '../../services/global';

@Injectable({
    providedIn: 'root'
})

export class UsuarioService{

    public url: string;
    constructor(
        private _http: HttpClient
    ){
        this.url = Global.url;
    }

    //*Crear un nuevo registro de usuario
    create(usuario):Observable<any>{

        let params = JSON.stringify(usuario);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.post(this.url+'usuario/save', params, {headers: headers});
    }

    //*Actualizar usuario
    update(id, usuario):Observable<any>{
        let params = JSON.stringify(usuario);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.put(this.url+'usuario/update/'+id, params, {headers: headers});;
    }

    //*Listar todos los usarios
    getUsuarios():Observable<any>{
        return this._http.get(this.url+'usuario/listarUsers');
    }

    //*Método para listar todos los usuarios activos
    listarActivos():Observable<any>{
      return this._http.get(this.url+'usuario/listarActivos');
    }

     //*Método que retorna la lista de usuarios registrados de forma paginada
     getUsuariosPaginate(page, limit):Observable<any>{
      let params = new HttpParams().set('page', page).set('limit', limit);
      return this._http.get(this.url+'usuario/paginateUsers', {params});
    }

    //*Método para conectar con el search engine en la API y buscar un record con datos especificos
    searchUser(key, page, limit):Observable<any>{
      let params = new HttpParams().set('page', page).set('limit', limit);
      return this._http.get(this.url+'usuario/search/'+key, {params});
    }

    //*Eliminar registro de usuario
    delete(id):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.delete(this.url+'usuario/delete/'+id, {headers: headers});
    }

    //*Obtener un usuario especifico a través de su ID
    getUsuario(id):Observable<any>{
        return this._http.get(this.url+'usuario/obtenerUser/'+id);
    }

    //*Cambiando el estado del usuario
    updateEstado(id, estado):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.put(this.url+'usuario/estado/'+id, estado, {headers: headers});
    }

    //*Validando password del usuario
    validaPass(data):Observable<any>{
        let params = JSON.stringify(data);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.post(this.url+'usuario/confirmPass', params, {headers: headers});
    }

  //*Método para obtener el registro del nombre de la imagen a través del id del usuario al que pertenece
  getImagenUsuarioById(idUser):Observable<any>{//id del usuario que hace relacion con la imagen
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.url+'imagenUsuario/getImageRecord/'+idUser, {headers: headers});
  }

  //*Método para obtener la imagen del usuario enviando el nombre de la imagen y obteniendo el enlace
  getPathImage(image):Observable<any>{
    return this._http.get(this.url+'imagenUsuario/getImage/'+image);
  }

  //*Método para eliminar la imagen del usuario
  deleteImagen(idUsuario):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.delete(this.url+'imagenUsuario/delete/'+idUsuario, {headers: headers});
  }

}
