import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { InsumosService } from '../../../moduloInventario/insumos/services/insumos.service';
import { LocalDate } from '../../pipes/localDate.pipe';
@Component({
  selector: 'app-consumo-insumos',
  templateUrl: './consumo-insumos.component.html',
  styleUrls: ['./consumo-insumos.component.css'],
  providers: [ LocalDate ]
})
export class ConsumoInsumosComponent implements OnInit {

   //*Para paginacion de ngx-pagination
   pSalidas: number = 1;

   pageControls = {
      itemsPorPagina: 0,
      paginaActual: 1,
      totalDeItems: 0
   }

  constructor(
    private insumosService: InsumosService,
    private localDate: LocalDate
  ) { }

  salidas = [];//*Arreglo para cargar la data
  dataLoaded = false;//Para manejar la carga de los datos
  total = 0.00;//*Para manejar el total del consumo del dia

  @Output() totalEmit = new EventEmitter<any>();//*Para retornar el total del consumo diario al dashboard

  ngOnInit(): void {
    this.loadSalidas(this.pSalidas, this.localDate.transform(new Date()));
  }

  loadSalidas(page, fechaActual){
    this.insumosService.getSalidasDeInsumos(page, fechaActual).subscribe(
      response => {
        let objeto = response.salidas;
        this.pageControls.itemsPorPagina = objeto.limit;
        this.pageControls.paginaActual = objeto.page;
        this.pSalidas = objeto.page;
        this.pageControls.totalDeItems = response.totalItems;
        this.salidas = response.salidas.docs;
        this.dataLoaded = true;
        if(this.salidas.length > 0){
          for (let i = 0; i < this.salidas.length; i++) {
            let element = this.salidas[i];
            this.total = this.total + element.costoTotal;
          }
          this.totalEmit.emit(this.total);//Enviando al componente padre el total
        }
      },
      error => {
        console.log('Error al cargar las salidas: ', error);
        //TODO: Agregar mensaje de error
      }
    );
  }

   //*Para actualizar la pagina en la paginación
   pageChanged(event){
    this.pSalidas= event;
    this.loadSalidas(this.pSalidas, this.localDate.transform(new Date()));
  }

}
