import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';


@Injectable({
  providedIn: 'root'
})
export class SocketIndicacionesService {

  constructor(private socket: Socket) { }

  //métodos para enviar y recibir la lista de indicaciones de ingreso
  //Aunque en realidad solo interesa el evento para poder actualizar la lista mediante una petición
  sendUpdateLista(lista: any) {
    this.socket.emit('updateIndicaciones', JSON.stringify(lista));
  }
  getListaUpdated() {
    return this.socket.fromEvent<any>('updateIndicaciones');
  }
  

}
