import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login.service';

@Injectable({
  providedIn: 'root'
})
export class IsAdminGuard implements CanActivate {
  constructor(private loginService: LoginService, private router: Router){};

  canActivate(){

    if(this.loginService.getRole() != 'admin'){
        console.log('No tienes permiso de administrador');
        this.router.navigate(['/']);
        return false;
    }

    return true;
  }
  
}
