import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dosDecimalesConvert'
})

export class DosDecimalesConvert implements PipeTransform{
  transform(value: string) {
      return (Number(value)).toFixed(2);
  }
}
