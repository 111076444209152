import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalcularEdad } from './calcularEdad.pipe';
import { FechaFormat } from './fechaFormat.pipe';
import { TelefonoFormat } from './telefonoFormat.pipe';
import { FechaRegistroFormat } from './fechaRegistroFormat.pipe';
import { SubstrEstudio } from './substrEstudio.pipe';
import { FechaFormatFull } from './fechaFormatFull.pipe';
import { Rol } from './rol.pipe';
import { AcortaTexto } from './acortaTexto.pipe';
import { AjusteDate } from './ajusteDate.pipe';
import { PercentStock } from './percentStock.pipe';
import { EstadoStock } from './estadoStock.pipe';
import { EstadoCaducidad } from './estadoCaducidad.pipe';
import { DosDecimales } from './dosDecimales.pipe';
import { FechaFormat2 } from './fechaFormat2.pipe';
import { LocalDate } from './localDate.pipe';
import { EstadoCaducidadText }  from './estadoCaducidadText.pipe';
import { FechaFormatDate } from './fechaFormatDate.pipe';
import { AgregaCero } from './agregaCero.pipe';
import { PercentDeduction } from './percentDeduction.pipe';
import { CantidadHoras } from './cantidadHoras.pipe';
import { FechaFutura } from './fechaFutura.pipe';
import { FechaFuturaBadge } from './fechaFuturaBadge.pipe';
import { FechaFuturaBadge2 } from './fechaFuturaBadge2.pipe';
import { FechaFuturaBadge3 } from './fechaFuturaBadge3.pipe';
import { DosDecimalesConvert } from './dosDecimalesConvert.pipe';
import { EstadoLicencia } from './estadoLicencia.pipe';

@NgModule({
  declarations: [
    CalcularEdad,
    FechaFormat,
    TelefonoFormat,
    FechaRegistroFormat,
    SubstrEstudio,
    FechaFormatFull,
    Rol,
    AcortaTexto,
    AjusteDate,
    PercentStock,
    EstadoStock,
    EstadoCaducidad,
    DosDecimales,
    FechaFormat2,
    LocalDate,
    EstadoCaducidadText,
    FechaFormatDate,
    AgregaCero,
    PercentDeduction,
    CantidadHoras,
    FechaFutura,
    FechaFuturaBadge,
    FechaFuturaBadge2,
    FechaFuturaBadge3,
    DosDecimalesConvert,
    EstadoLicencia
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CalcularEdad,
    FechaFormat,
    TelefonoFormat,
    FechaRegistroFormat,
    SubstrEstudio,
    FechaFormatFull,
    Rol,
    AcortaTexto,
    AjusteDate,
    PercentStock,
    EstadoStock,
    EstadoCaducidad,
    DosDecimales,
    FechaFormat2,
    LocalDate,
    EstadoCaducidadText,
    FechaFormatDate,
    AgregaCero,
    PercentDeduction,
    CantidadHoras,
    FechaFutura,
    FechaFuturaBadge,
    FechaFuturaBadge2,
    FechaFuturaBadge3,
    DosDecimalesConvert,
    EstadoLicencia
  ]
})
export class PipesModule { }
