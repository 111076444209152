import { Component, OnInit } from '@angular/core';
import { SocketColaService } from 'src/app/services/socket-cola.service';
import { ColaService } from '../../../services/cola.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSmartModalService } from 'ngx-smart-modal';
//importando para navegar en las rutas
import {Router} from '@angular/router';
import { LoginService } from '../../../auth/services/login.service';


@Component({
  selector: 'app-cola-pacientes',
  templateUrl: './cola-pacientes.component.html',
  styleUrls: ['./cola-pacientes.component.css']
})
export class ColaPacientesComponent implements OnInit {

  idCola = '';
  public cola = [];
  public atendidos = [];

  index = '';

  public signosVitales: any;

  public userRole = '';//para resguardar el rol de usuario logeado
  public userId = ''; //para resguardar el id del usuario administrador logueado

  constructor(private socketColaService: SocketColaService,
              private colaService: ColaService,
              private toastr: ToastrService,
              public ngxSmartModalService: NgxSmartModalService,
              private router: Router,
              private loginService: LoginService) {

    this.userRole = this.loginService.getRole();//obteniendo el rol del usuario logeado 
    this.userId = this.loginService.userLogged()._id;//obteniendo el id del user logeado 
    //console.log(this.loginService.userLogged());          

    //Obteniendo el paciente nuevo en cola por socket.io
    this.socketColaService.getMessage().subscribe(
      response => {
          let esta = false;
          let respuesta = JSON.parse(response);
          this.cola.forEach(element => {
              if(respuesta.idPaciente === element.idPaciente){
                esta = true;
                //console.log('entra: ',response.idPaciente, element.idPaciente);
              }
          });
          this.atendidos.forEach(element => {
              if(respuesta.idPaciente === element.idPaciente){
                esta = true;
              }
          });
          if(!esta){
            this.pacienteEnColaMessage('Se agregó un paciente a la cola','Pacientes en espera');
            this.cola.push(respuesta);
            if(this.idCola != ''){
              let count = this.cola.length;
              this.socketColaService.sendCountCola(count); // envío el tamaño de la cola
              this.actualizarCola(this.idCola, {colaDeEspera: this.cola, colaDeAtencion: this.atendidos});
            }
          }
      }
    );

    //Obteniendo la cola actualizada si esta es modificada
    this.socketColaService.getCola().subscribe(
      response => {
        if(response!=null){
          let respuesta = JSON.parse(response);
            this.cola = respuesta.cola;
            this.atendidos = respuesta.atendidos;
            this.propagarAtendidos(this.atendidos);//envia los pacientes atendidos al colaService
        }
      }
    );

    //Método para recargar la cola para el que enviá los datos
    this.colaService.getLoadCola().subscribe(
      response => {
          this.cargarCola();
      },
      error => {
          console.log('Error al hacer click para cargar cola');
      }
    );

  }

  ngOnInit(): void {
    this.cargarCola();

    this.signosVitales = {
      turno: "",
      nombrePaciente: "",
      temperatura: "",
      freCardiaca: "",
      freRespiratoria: "",
      peso: "",
      talla: "",
      preSistolica: "",
      preDiastolica: "",
      periCefalico: "",
      cirAbdominal: "",
      indiceMC: "",
      presionArt: "",
    }

    //console.log(this.atendidos);
  }

  //Método para cargar la cola desde la base de datos
  cargarCola(){
    this.colaService.getCola().subscribe(
      response => {
          this.cola = response.cola[0].colaDeEspera;
          this.atendidos = response.cola[0].colaDeAtencion;
          this.idCola = response.cola[0]._id;
          this.propagarAtendidos(this.atendidos);//envia los pacientes atendidos al colaService
      },
      error => {
          console.log(error);
      }
    );
  }

  //Método para actualizar la cola en la base de datos
  actualizarCola(id, cola){
      this.colaService.updateCola(id, cola).subscribe(
        response =>{
          //Solo se recibe la respuesta de que se actualizó la cola
          //No es necesario capturar los datos de respuesta porque con socket ya se actualizó previamente
          //console.log(response);
        },
        error => {
          console.log(error);
        }
      );
  }

  //descartar un paciente de la cola de espera
  descartarPaciente(index){
      this.cola.splice(index, 1);//Elimina el elemento del arreglo
      //actualiza la cola en la BD
      this.actualizarCola(this.idCola, {colaDeEspera: this.cola, colaDeAtencion: this.atendidos});
      this.socketColaService.sendCola({cola: this.cola, atendidos: this.atendidos});//actualiza la cola visualmente en tiempo real
  }

  //Método que transfiere un paciente de la cola de espera a la cola de atendidos
  darTurno(index){
    //prepara el objeto a insertar en la cola de atendidos
    //console.log(this.cola);
    let paciente = {
      idPaciente: this.cola[index].idPaciente,
      nombrePaciente: this.cola[index].nombrePaciente,
      idMedico: this.cola[index].idMedico,
      nombreMedico: this.cola[index].nombreMedico,
      temperatura : this.cola[index].temperatura,
      freCardiaca : this.cola[index].freCardiaca,
      freRespiratoria : this.cola[index].freRespiratoria,
      peso : this.cola[index].peso,
      talla : this.cola[index].talla,
      preSistolica : this.cola[index].preSistolica,
      preDiastolica : this.cola[index].preDiastolica,
      periCefalico : this.cola[index].periCefalico,
      cirAbdominal : this.cola[index].cirAbdominal,
      indiceMC: this.cola[index].indiceMC,
      presionArt: this.cola[index].presionArt
    }
    this.atendidos.push(paciente);//Agrega al paciente a la cola de atendidos
    this.cola.splice(index, 1);//Elimina el elemento del arreglo
    this.actualizarCola(this.idCola, {colaDeEspera: this.cola, colaDeAtencion: this.atendidos});//actualiza la cola en la BD
    this.socketColaService.sendCola({cola: this.cola, atendidos: this.atendidos}); //Envia la cola a través de socket para actualiar en todos los clientes 
    this.propagarAtendidos(this.atendidos);//envia los pacientes atendidos al colaService
  }

  //Método para transferir al primer paciente en espera a la cola de atendidos
  next(){
    if(this.cola.length > 0){
        this.darTurno(0);//siempre dará el turno al primero
    }
  }

  //Método que elimina un paciente de la cola de atendidos
  despachar(index){
    this.atendidos.splice(index, 1);//Elimina el elemento del arreglo
    this.actualizarCola(this.idCola, {colaDeEspera: this.cola, colaDeAtencion: this.atendidos});//actualiza la cola en la BD
    this.socketColaService.sendCola({cola: this.cola, atendidos: this.atendidos});
    this.propagarAtendidos(this.atendidos);//envia los pacientes atendidos al colaService
  }

  //Notificación cuando se agrega un paciente
  pacienteEnColaMessage(mensaje: string, titulo: string){
    this.toastr.success(mensaje, titulo);
  }

  //Notificación cuando se agrega un paciente
  signosMessage(mensaje: string, titulo: string){
    this.toastr.info(mensaje, titulo);
  }

  //Método para indicar a que paciente se le toma signos vitales
  signos(index, nombre){

    this.index = index;
    this.signosVitales.turno = index + 1;
    this.signosVitales.nombrePaciente = nombre;
    this.signosVitales.temperatura = this.cola[index].temperatura;
    this.signosVitales.freCardiaca = this.cola[index].freCardiaca;
    this.signosVitales.freRespiratoria = this.cola[index].freRespiratoria;
    this.signosVitales.peso = this.cola[index].peso;
    this.signosVitales.talla = this.cola[index].talla;
    this.signosVitales.preSistolica = this.cola[index].preSistolica;
    this.signosVitales.preDiastolica = this.cola[index].preDiastolica;
    this.signosVitales.periCefalico = this.cola[index].periCefalico;
    this.signosVitales.cirAbdominal = this.cola[index].cirAbdominal;
    this.signosVitales.indiceMC = this.cola[index].indiceMC;
    this.signosVitales.presionArt = this.cola[index].presionArt;
  }

  guardarSignos(){
      
    this.cola[this.index].temperatura = this.signosVitales.temperatura;
    this.cola[this.index].freCardiaca = this.signosVitales.freCardiaca;
    this.cola[this.index].freRespiratoria = this.signosVitales.freRespiratoria;
    this.cola[this.index].peso = this.signosVitales.peso;
    this.cola[this.index].talla = this.signosVitales.talla;
    this.cola[this.index].preSistolica = this.signosVitales.preSistolica;
    this.cola[this.index].preDiastolica = this.signosVitales.preDiastolica;
    this.cola[this.index].periCefalico = this.signosVitales.periCefalico;
    this.cola[this.index].cirAbdominal = this.signosVitales.cirAbdominal;
    this.cola[this.index].indiceMC = this.signosVitales.indiceMC;
    this.cola[this.index].presionArt = this.signosVitales.presionArt;

    //actualiza la cola en la BD
    this.actualizarCola(this.idCola, {colaDeEspera: this.cola, colaDeAtencion: this.atendidos});
    this.socketColaService.sendCola({cola: this.cola, atendidos: this.atendidos});//actualiza la cola visualmente en tiempo real

    this.signosMessage('Se agregaron signos vitales','Signos Vitales');

  }

  limpiarSignos(){
    this.index = '';
    this.signosVitales.turno = '';
    this.signosVitales.nombrePaciente = '';
    this.signosVitales.temperatura = '';
    this.signosVitales.freCardiaca = '';
    this.signosVitales.freRespiratoria = '';
    this.signosVitales.peso = '';
    this.signosVitales.talla = '';
    this.signosVitales.preSistolica = '';
    this.signosVitales.preDiastolica = '';
    this.signosVitales.periCefalico = '';
    this.signosVitales.cirAbdominal = '';
    this.signosVitales.indiceMC = '';
    this.signosVitales.presionArt = '';
  }

  calcularIMC(event, campo) {
  
    if(event != ''){

      if(campo === 'peso'){
        this.signosVitales.peso = event;//Event viene el peso en libras, se guarda en libras
      }

      if(campo === 'talla'){
        this.signosVitales.talla = event;
      }
      
      if ((this.signosVitales.talla != 0 && this.signosVitales.talla!= null && this.signosVitales.talla != '') &&
          (this.signosVitales.peso != null && this.signosVitales.peso != '')) {
        let pesoKilos = this.signosVitales.peso / 2.2046;//Se calcula el peso en kilogramos para calcular el IMC
        let indice = pesoKilos / (Math.pow(this.signosVitales.talla, 2));
        let valor = "" + parseFloat("" + indice.toFixed(1));
        this.signosVitales.indiceMC = valor;
      }else{
        this.signosVitales.indiceMC = '';
      }
    }else{
      this.signosVitales.indiceMC = '';
    }
  }

  calcularPresion(event, campo){

    if(event != '' && event != 0 && event != null){

      if(campo === 'Sistolica'){
        this.signosVitales.preSistolica = event;
      }

      if(campo === 'Diastolica'){
        this.signosVitales.preDiastolica = event;
      }

      if(this.signosVitales.preSistolica != '' && this.signosVitales.preSistolica != 0 &&
         this.signosVitales.preDiastolica != '' && this.signosVitales.preDiastolica != 0){

        this.signosVitales.presionArt = this.signosVitales.preSistolica+' / '+this.signosVitales.preDiastolica;

      }else{
        this.signosVitales.presionArt= '';  
        //this.pArterial = '';
      }

    }else{
      if(campo === 'Sistolica'){
        this.signosVitales.preSistolica = '';
      }
      if(campo === 'Diastolica'){
        this.signosVitales.preDiastolica = '';
      }
      this.signosVitales.presionArt= '';
      //this.pArterial = '';
    }

  }

  abrirConsulta(idPaciente){
    this.ngxSmartModalService.getModal('colaModal').close();//cerrando el modal de cola
    //Solo el usuario administrados puede abrir el formulario de consulta del paciente atendido
    this.router.navigate(['/admin/consulta/', idPaciente]);
  }

  /*
  Este método se utiliza para enviar los atendidos mediante un servicio y asi poder usarla cuando el paciente es enviado a su consulta
  para poder verificar si ya se le habian tomado los signos vitales, y si así es el caso poder recuperarlos y mostrarlos en el formulario
  de consulta.
  */
  propagarAtendidos(atendidos){
      this.colaService.setAtentidos(atendidos);
  }

}
