<table class="table table-sm table-bordered">
  <thead>
    <tr>
      <th>Nombre del artículo</th>
      <th style="width: 150px;">Transacción</th>
      <th style="width: 110px;">Unidades</th>
      <th style="width: 150px;">$Costo unitario</th>
      <th style="width: 200px;">$Valor de transacción</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let salida of salidas | paginate: { itemsPerPage: this.pageControls.itemsPorPagina,
                                                    currentPage: pSalidas,
                                                    totalItems: pageControls.totalDeItems,
                                                    id: 'salidasInsumosPagination' } ; let i = index">

        <td>{{ salida.articulo.nombre }}</td>
        <td>{{ salida.transaccion }}</td>
        <td>{{ salida.cantidadUnidades }}</td>
        <td>${{ salida.costoUnitario | dosDecimales }}</td>
        <td>${{ salida.costoTotal | dosDecimales }}</td>
    </tr>
  </tbody>
  <tfoot *ngIf="salidas.length > 0">
    <tr>
      <td colspan="4">
        <b>Total de consumo diario: </b>
      </td>
      <td>
        <b>
          ${{ total | dosDecimales }}
        </b>
      </td>
    </tr>
  </tfoot>
</table>
<p *ngIf="salidas.length == 0 && dataLoaded == false" style="text-align: center;">Cargando...</p>
<p *ngIf="salidas.length == 0 && dataLoaded == true" style="text-align: center;">No hay registro de salidas de insumos este día</p>
<hr>
<div class="row">
  <div class="col-md-12">
      <pagination-controls  class="float-right"
                    (pageChange)="pageChanged($event)"
                    id="salidasInsumosPagination"
                    previousLabel="Anterior"
                    nextLabel="Siguiente"
                    responsive="true">
      </pagination-controls>
  </div>
</div>
