import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';


@Injectable({
  providedIn: 'root'
})
export class SocketOrdenConsultaService {

  constructor(private socket: Socket) { }

  //*métodos para enviar y recibir el evento de una orden de estudios por consulta que ya no estara en produccion
  //*Aunque en realidad solo interesa el evento para poder actualizar la lista mediante una petición
  sendUpdateLista(orden: any) {
    this.socket.emit('removeOrderByConsulta', JSON.stringify(orden));
  }
  getListaUpdated() {
    return this.socket.fromEvent<any>('removeOrderByConsulta');
  }


}
