import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'acortaTexto'
})

export class AcortaTexto implements PipeTransform{

    transform(texto: string, long: number, limit: number = 75){
 
        let cadena = texto;

        if(cadena.length > limit){
            cadena = cadena.substring(0, long) + ' ...';
        }
        
        return cadena;

    }

}