<ngx-smart-modal #cuentasModal identifier="cuentasModal" [customClass]="'modal-lg'" [dismissable] = "false" [closable] = "false" >

    <div class="modal-body">
      <h4 *ngIf="deudores.length > 0">Cuentas pendientes de pago <i class="fas fa-hand-holding-usd"></i> - Consulta médica</h4>
      <table class="table table-sm table-bordered" *ngIf="deudores.length > 0">
          <thead>
            <tr>
              <th style="width:8%">#</th>
              <th style="width:25%">Fecha de consulta</th>
              <th>Nombre del paciente</th>
              <th style="width:20%">Ver cuenta</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let cuenta of deudores; let i = index">
              <td>{{ i + 1 }}.</td>
              <td>{{ cuenta.fecha | fechaFormat }}</td>
              <td>{{ cuenta.nombrePaciente | uppercase }}</td>
              <td>
                <button class="btn btn-block btn-default" (click)="ngxSmartModalService.getModal('cuentaPacienteModal').open(); mostrarCuenta(cuenta._id)">
                  <i class="fas fa-file-invoice-dollar fa-lg"></i>
                </button>
              </td>
            </tr>
          </tbody>
      </table>

      <ng-container *ngIf="deudores.length == 0">
        <div class="row justify-content-center">
          <h5>No hay cuentas pendientes de pago </h5>
        </div>
        <div class="row justify-content-center">
          <img
              src="../../../../assets/imgs/save_money.png"
              class="img-fluid"
              alt="check-green"
              style="width: 400px; height: 500;"/>
        </div>
      </ng-container>


  </div>
  <div class="modal-footer justify-content-right">

    <button class="btn btn-secondary float-right" (click)="cuentasModal.close();">
      Cerrar
    </button>

  </div>


</ngx-smart-modal>

<ngx-smart-modal #cuentaPacienteModal identifier="cuentaPacienteModal" [customClass]="'modal-lg'" [dismissable] = "false" [closable] = "false" >

  <div class="modal-body">
    <h4>Cuenta por consulta médica <i class="fas fa-file-invoice-dollar fa-lg"></i> </h4>

    <h6><strong>Número de cuenta: </strong>{{ numeroCuenta }}</h6>
    <h6><strong>Fecha de consulta: </strong>{{ fechaCuenta | fechaFormat }}</h6>
    <h6><strong>Paciente: </strong> {{ pacienteNom | uppercase }} - <strong>Expediente: </strong> {{ expediente }}</h6>
    <h6><strong>Cuenta total: </strong>${{ cuentaTotal | dosDecimales  }}</h6>
    <h6><strong>Estado: </strong>{{ estado }}</h6>
    <hr>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Detalle..."
              name="detalle"
              autocomplete="off"
              #detalleCargo='ngModel'
              [(ngModel)]="detalle"
            />
          </div>

        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="$Precio"
              name="precio"
              autocomplete="off"
              #precioCargo='ngModel'
              [(ngModel)]="precio"
              prefix="$"
              mask="separator.2" thousandSeparator=","

            />
          </div>

        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <button class="btn btn-info btn-block" (click)="agregarDetalle()">
            <i class="fas fa-arrow-down"></i>
              Agregar detalle
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <table class="table table-sm table-bordered">
        <thead>
          <tr>
            <th colspan="4">Lista de cuenta</th>
          </tr>
          <tr>
            <th style="width:8%">#</th>
            <th>Detalle por</th>
            <th>Precio</th>
            <th style="width:15%">Descartar</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let detalle of cuentaDeudor; let i = index">
            <tr>
              <td><b>{{ i + 1 }}.</b></td>
              <td>{{ detalle.concepto }}</td>
              <td>${{detalle.precio | dosDecimales }}</td>
              <td>
                <button class="btn btn-block btn-default"
                        [disabled]="i == 0" (click)="descartarDetalle(i)">
                        <i class="fas fa-backspace"></i>
                </button>
              </td>
            </tr>
            <tr *ngIf="detalle.concepto == 'Estudios clínicos' && detalle.id_detalle.estudios">
                <td></td>
                <td colspan="3">
                  <ng-container *ngFor="let estudio of detalle.id_detalle.estudios">
                    <ng-container *ngFor="let examen of estudio.examenes">
                      <li style="list-style-type: disc;">{{ examen.nombre }}: ${{ examen.precio }}</li>
                    </ng-container>
                  </ng-container>
                </td>
            </tr>
          </ng-container>

        </tbody>
        <tfoot>
          <td colspan="2"><strong>Total a pagar:</strong> </td>
          <td><strong>${{ cuentaTotal | dosDecimales }}</strong></td>
        </tfoot>
      </table>
    </div>

    <div class="row" *ngIf="!totalesValidos">
      <p style="color: red;">El total de la cuenta no debe ser menor al pago del paciente</p>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="$Abono"
              name="abono"
              autocomplete="off"
              #abonoC='ngModel'
              [(ngModel)]="abono"
              prefix="$"
              mask="separator.2" thousandSeparator=","
            />
          </div>

        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <button class="btn btn-info float-left" (click)="agregarAbono()">
            <i class="fas fa-hand-holding-usd"></i>
              Agregar abono
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <table class="table table-sm table-bordered">
          <thead>
              <tr>
                <th colspan="4">Lista de pagos</th>
              </tr>
              <tr>
                <th>#</th>
                <th>Fecha: </th>
                <th>Monto abonado: </th>
                <th>Registró:</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let abono of abonos; let i = index">
                <td>{{ i + 1 }} </td>
                <td>{{ abono.fecha | fechaFormatDate }}</td>
                <td>${{ abono.monto | dosDecimales }}</td>
                <td>{{ abono.registro }}</td>
              </tr>
          </tbody>
          <tfoot *ngIf="abonos.length > 0">
            <td colspan="2"><strong>Total abonado:</strong> </td>
            <td><strong>${{ pagoTotal | dosDecimales }}</strong></td>
            <td><strong>Pendiente: ${{ restoPendiente }}</strong></td>
          </tfoot>
      </table>
    </div>
    <p *ngIf="abonos.length == 0" style="text-align: center;">No hay pagos realizados</p>
  </div>
  <div class="modal-footer d-none d-sm-block">

    <div class="float-left"> <!--  (!(abonos.length > 0) || !agregoAbono) || !totalesValidos   -->
      <button class="btn btn-info" (click)="validarPago()" [disabled]="!totalesValidos">
        <i class="fas fa-save"></i>
          Guardar pago
      </button>

      <button class="btn btn-secondary ml-3 mr-0" (click)="cuentaToPDF()" [disabled]="!totalesValidos">
        <i class="fas fa-print"></i>
          Imprimir
      </button>
    </div>

    <div class="float-right">
      <button class="btn btn-secondary float-right"
      (click)="cuentaPacienteModal.close(); cancelar() ">
        Cancelar
      </button>
    </div>

  </div>

</ngx-smart-modal>

<ngx-smart-modal  #confirmaPagoModal identifier="confirmaPagoModal" [customClass]="'modal-dialog modal-dialog-centered'" [dismissable] = "false" [closable] = "false" >

  <div class="modal-body justify-content-center" style="text-align: center;">

      <h3>Confirma tu contraseña <i class="fas fa-key"></i></h3>
      <h5>Por favor ingresa tu contraseña para continuar </h5>
      <br>
      <div class="form-group">
        <input type="password"
              autofocus
              #pass="ngModel"
              [(ngModel)]="password"
              class="form-control form-control-lg"
              placeholder="Contraseña">
      </div>

  </div>

  <div class="modal-footer justify-content-center">

    <button class="btn btn-info btn-lg" (click)="validarPassword()">
        Aceptar
    </button>

    <button class="btn btn-secondary btn-lg ml-3 mr-0" (click)="confirmaPagoModal.close(); cancelar()">
        Cancelar
    </button>
  </div>

</ngx-smart-modal>
