 <!-- Modal para modificar los los motivos de consulta -->

 <ngx-smart-modal #colaModal identifier="colaModal" [customClass]="'modal-xl'" [dismissable] = "false" [closable] = "false" >


        <div class="modal-body">
          <h4>Pacientes en espera <i class="fas fa-users"></i> </h4>
          <table class="table table-sm table-bordered">
              <thead>
                <tr>
                  <th style="width: 8%">Turno</th>
                  <th>Nombre del paciente</th>
                  <th>Médico de consulta</th>
                  <th style="width: 12%">Dar turno</th>
                  <th style="width: 12%">Signos</th>
                  <th style="width: 12%">Descartar</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let paciente of cola; let i = index">
                  <td><b> N° {{ i + 1 }}. </b></td>
                  <td>{{ paciente.nombrePaciente | titlecase }}</td>
                  <td>{{ paciente.nombreMedico | titlecase }}</td>
                  <td>
                    <button class="btn btn-block btn-default" (click)="darTurno(i)">
                      <i class="fas fa-walking fa-lg"></i>
                    </button>
                  </td>
                  <td>
                    <button class="btn btn-block btn-default" (click)="ngxSmartModalService.getModal('signosModal').open(); signos(i, paciente.nombrePaciente)">
                      <i class="fas fa-diagnoses fa-lg"></i>
                    </button>
                  </td>
                  <td>
                    <button class="btn btn-block btn-default" (click)="descartarPaciente(i)">
                      <i class="fas fa-backspace"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
          </table>
          <p *ngIf="cola.length == 0" style="text-align: center;">No hay pacientes en espera</p>
          <br>
          <h4>Pacientes atendidos <i class="fas fa-user-md"></i></h4>
          <table class="table table-sm table-bordered">
            <thead>
              <tr>
                <th style="width: 8%">Turno</th>
                <th>Nombre del paciente</th>
                <th>Médico de consulta</th>
                <th style="width: 12%">Consulta</th>
                <th style="width: 12%">Despachar</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let atendido of atendidos; let i = index">
                <td><b>N° {{ i + 1 }}.</b></td>
                <td>{{ atendido.nombrePaciente | titlecase }}</td>
                <td>{{ atendido.nombreMedico | titlecase }}</td>
                <td>
                  <button class="btn btn-block btn-default"
                    (click)="abrirConsulta(atendido.idPaciente)"
                    [disabled]="userRole === 'recepcion' || !(userId === atendido.idMedico)">
                    <i class="fas fa-notes-medical"></i>
                  </button>
                </td>
                <td>
                  <button class="btn btn-block btn-default"
                          (click)="despachar(i)">
                    <i class="fas fa-sign-out-alt"></i>
                  </button>
                </td>
              </tr>
            </tbody>
        </table>
        <p *ngIf="atendidos.length == 0" style="text-align: center;">No hay pacientes siendo atendidos</p>
      </div>
      <div class="modal-footer justify-content-between">

        <button class="btn btn-info float-left" (click)="next()">
          <i class="fas fa-sign-in-alt"></i>
          Siguiente
        </button>

        <button class="btn btn-secondary float-right"
        (click)="colaModal.close()">
          Cerrar
        </button>

      </div>


 </ngx-smart-modal>

<!-- Modal para modificar los los motivos de consulta -->

<!-- Modal para agregar los signos vitales de los pacientes en cola -->

<ngx-smart-modal #signosModal identifier="signosModal" [customClass]="'modal-xl'" [dismissable] = "false" [closable] = "false" >

  <div class="modal-body">
    <h4>Signos vitales del paciente <i class="fas fa-diagnoses"></i> </h4>
    <h6><strong>Nombre del paciente: </strong>{{ signosVitales.nombrePaciente }}</h6>
    <h6><strong>Turno: N° {{ signosVitales.turno }}</strong></h6>
    <hr>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label>Temperatura:</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Temperatura"
              name="temperatura"
              #temperatura="ngModel"
              [(ngModel)]="signosVitales.temperatura"
              autocomplete="off"
              ngxPattern="^\d+\.?\d{0,2}$"
            />
            <div class="input-group-prepend">
              <span class="input-group-text">°C</span>
            </div>
          </div>

        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>Frecuencia Cardiáca:</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Frecuencia cardiáca"
              name="freCardia"
              #freCardia="ngModel"
              [(ngModel)]="signosVitales.freCardiaca"
              autocomplete="off"
              ngxPattern="^\d+\.?\d{0,2}$"
            />
            <div class="input-group-prepend">
              <span class="input-group-text">PPM</span>
            </div>
          </div>

        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label>Frecuencia Respiratoria:</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Frecuencia Respiratoria"
              name="freResp"
              #freResp="ngModel"
              [(ngModel)]="signosVitales.freRespiratoria"
              autocomplete="off"
              ngxPattern="^\d+\.?\d{0,2}$"
            />
            <div class="input-group-prepend">
              <span class="input-group-text">FR</span>
            </div>
          </div>


        </div>
      </div>

    </div>
    <div class="row">
      <!--Div de signos vitales-->
      <div class="col-md-4">
        <div class="form-group">
          <label>Peso:</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Peso"
              name="peso"
              (ngModelChange)="calcularIMC($event, 'peso')"
              #peso="ngModel"
              [ngModel]="signosVitales.peso"
              autocomplete="off"
              ngxPattern="^\d+\.?\d{0,2}$"
            />
            <div class="input-group-prepend">
              <span class="input-group-text">Lb</span>
            </div>
          </div>

        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>Talla:</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Talla"
              (ngModelChange)="calcularIMC($event, 'talla')"
              name="talla"
              #talla="ngModel"
              [ngModel]="signosVitales.talla"
              autocomplete="off"
              ngxPattern="^\d+\.?\d{0,2}$"
            />
            <div class="input-group-prepend">
              <span class="input-group-text">mt</span>
            </div>

          </div>

        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>Índice de Masa Corporal:</label>
          <div class="input-group">
            <input
              type="number"
              class="form-control"
              placeholder="Índice de masa corporal"
              name="indiceMC"
              #indiceMC="ngModel"
              [(ngModel)]="signosVitales.indiceMC"
              autocomplete="off"
              ngxPattern="[0-9.]+"
              disabled
            />
            <div class="input-group-prepend">
              <span class="input-group-text">IMC</span>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label>Presión Sistólica:</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Presión Sistólica"
              name="presionSis"
              #presionSis="ngModel"
              [ngModel]="signosVitales.preSistolica"
              (ngModelChange)="calcularPresion($event, 'Sistolica')"
              autocomplete="off"
              ngxPattern="^\d+\.?\d{0,2}$"
            />
            <div class="input-group-prepend">
              <span class="input-group-text">PS</span>
            </div>
          </div>

        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>Presión Diastólica:</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Presión Diastólica"
              name="presionDias"
              #presionDias="ngModel"
              [ngModel]="signosVitales.preDiastolica"
              (ngModelChange)="calcularPresion($event, 'Diastolica')"
              autocomplete="off"
              ngxPattern="^\d+\.?\d{0,2}$"
            />
            <div class="input-group-prepend">
              <span class="input-group-text">PD</span>
            </div>
          </div>

        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>Presión Arterial:</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Presión arterial"
              name="presionArt"
              #presionArt="ngModel"
              [ngModel]="signosVitales.presionArt"
              required
              autocomplete="off"
              disabled/>
            <div class="input-group-prepend">
              <span class="input-group-text">mm Hg</span>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="row" >
      <div class="col-md-4">
        <div class="form-group">
          <label>Perímetro Cefálico:</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Perímetro Cefálico"
              name="periCefalico"
              #periCefalico="ngModel"
              [(ngModel)]="signosVitales.periCefalico"
              autocomplete="off"
              ngxPattern="^\d+\.?\d{0,2}$"
            />
            <div class="input-group-prepend">
              <span class="input-group-text">PC</span>
            </div>
          </div>

        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>Circunferencia Abdominal:</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Circunferencia Abdominal"
              name="cirAbdominal"
              #cirAbdominal="ngModel"
              [(ngModel)]="signosVitales.cirAbdominal"
              autocomplete="off"
              ngxPattern="^\d+\.?\d{0,2}$"
            />
            <div class="input-group-prepend">
              <span class="input-group-text">CA</span>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- Fin del div signos vitales -->
  </div>
  <div class="modal-footer justify-content-between">

    <button class="btn btn-info float-left" (click)="guardarSignos()">
      <i class="fas fa-save"></i>
      Guardar signos
    </button>

    <button class="btn btn-secondary float-right"
    (click)="signosModal.close(); limpiarSignos()">
      Cerrar
    </button>

  </div>


</ngx-smart-modal>

<!-- Modal para agregar los signos vitales de los pacientes en cola -->
