import { Component, OnInit } from '@angular/core';
import { CuentaParticularService } from 'src/app/moduloLaboratorio/ordenes-particulares/services/cuenta-particular.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { LoginService } from 'src/app/auth/services/login.service';
import { ToastrService } from 'ngx-toastr';
import { UsuarioService } from '../../../auth/services/usuario.service';
import { SocketCuentaService } from '../../../services/socket-cuenta.service';
import { FechaFormat } from '../../pipes/fechaFormat.pipe';
import { ToPDFService } from 'src/app/moduloLaboratorio/ordenes-particulares/services/toPDF.service';
import { OrdenesParticularesService } from '../../../moduloLaboratorio/ordenes-particulares/services/ordenes-particulares.service';
import { SocketOrdenParticularService } from 'src/app/services/socket-ordenParticular.service';

@Component({
  selector: 'app-cuenta-orden-particular',
  templateUrl: './cuenta-orden-particular.component.html',
  styleUrls: ['./cuenta-orden-particular.component.css'],
  providers: [FechaFormat],
})
export class CuentaOrdenParticularComponent implements OnInit {

  public idCuenta = '';//*Variable auxiliar para almacenar el id de cuenta
  public idCliente = '';//*Variable para almacenar el valor del id de cliente
  public nombreCliente = '';//*Variable para almacenar el nombre del paciente
  public codigo = '';//*Variable para almacenar el codigo del cliente
  public numeroCuenta = '';//*Variable para mostrar el numero de cuenta
  public fechaCuenta = '';//*Variable para mostrar la fecha de cuenta

  public deudores = [];//*Arreglo para listar las cuentas deudoras
  public cuentas = [];//*Arreglo para listar todas la cuentas
  public abonos = [];//*Arreglo para manejar todos los abonos

  public sumaCuenta = "0.00";//*Variable para acumular todos precios por ordenes
  public sumaAbonos = "0.00";//*Variable para acumular todos los abonos o pagos que se hagan
  public restoPendiente = "0.00"//*Variable para el cálculo de resto por pagar

  totalesValidos = false;//*Bandera para verificar si el pago es menor o igual a la cuenta total
  validValues = true;//*Bandera para validar los valores de los campos de cada monto
  estado = 'Pendiente de pago';

  public abono = "0.00";//*Para manejar el valor de un abono agregado

  idUserLogged = '';//*id del usuario para validar password
  nameUserLogged = '';//*Nombre del usuario
  password = ''//*Variable para poder confirmar el pago con contraseña

  //*Arreglo para guardar los detalles de cuenta que han sido removidos
  detailsRemoved = [];

  constructor(
    private cuentaParticularService: CuentaParticularService,
    public smartModal: NgxSmartModalService,
    private loginService: LoginService,
    private usuarioService: UsuarioService,
    private toastr: ToastrService,
    private socketCuentaService: SocketCuentaService,
    private fechaFormatPipe: FechaFormat,
    private toPDFService: ToPDFService,
    private ordenesParticularesService: OrdenesParticularesService,
    private socketOrdenParticularService: SocketOrdenParticularService
  ) {
    this.socketCuentaService.getCuentaParticular().subscribe(
      response => {
        //No uso la data enviada a través del socket solo el evento
        this.loadCuentasDeudoras();
      },
      err => {
         //console.log('Error al cargar las cuentas particulares', err);
      }
    );
  }

  ngOnInit(): void {
    let userLogged = this.loginService.userLogged();
    this.idUserLogged = userLogged._id;
    this.nameUserLogged = userLogged.nombres+' '+userLogged.apellidos;//*Cargando nombre completo del usuario en sesión
    this.loadCuentasDeudoras();
  }

  loadCuentasDeudoras(){
    this.cuentaParticularService.getCuentasDeudoras().subscribe(
      response => {
        //console.log('Cuentas: ', response);
        this.deudores = response.cuentasDeudoras;
      },
      error => {
        //console.log("Error al cargar las cuentas dedudoras: ", error);
        this.errorMessage('Error al cargar las cuentas deudoras','Cuenta por estudios');
      }
    );
  }

  mostrarCuenta(idCuenta){
    this.deudores.forEach(deudor => {
        if(deudor._id == idCuenta){
          this.idCuenta = deudor._id;
          this.numeroCuenta = deudor.numero;
          this.nombreCliente = deudor.cliente;
          this.codigo = deudor.codigo;
          this.cuentas = Array.from(deudor.cuenta);
          this.abonos = Array.from(deudor.abono);
          this.fechaCuenta = deudor.fecha;
          this.idCliente = deudor.clienteId;
          this.calcularTotalCuenta();
          //console.log('Cuenta particular: ', this.cuentas);
          this.smartModal.getModal('cuentaParticularModal').open();
        }//*Fin del if
        else{
          return;//*Saltando a la siguiente iteración
        }
    });
  }

  calcularTotalCuenta(){
    let suma = 0;
    let suma2 = 0;

    if(this.cuentas.length > 0){
        this.cuentas.forEach(x => {
          if((x.precio.length > 0 && x.precio != NaN && x.precio != '$') || (x.precio >= 0)){
            suma = suma + parseFloat(x.precio);
          }else{
            return;
          }
        });
    }

    if(this.abonos.length > 0){
      this.abonos.forEach(y => {
        if((y.monto.length > 0 && y.monto != NaN && y.monto != '$') || (y.monto >= 0)){
          suma2 = suma2 + parseFloat(y.monto);
        }else{
          return;
        }
      });
    }

    //Mostrando los totales
    this.sumaCuenta = suma.toFixed(2);
    this.sumaAbonos = suma2.toFixed(2);
    this.estado = this.verificarEstado();
    this.validValues = this.verificarValores();//*Verificando si todos los campos estan bien digitados
    if(this.verificarTotales()){
      //*Calculando el resto de toda la cuenta - los abonos
      this.calcularResto();

    }else{
      this.restoPendiente = "0.00";
    }

  }

  //*Método para actualizar el resto pediente a pagar
  calcularResto(){
    this.restoPendiente = (Number(this.sumaCuenta) - Number(this.sumaAbonos)).toFixed(2);
  }

  //*Método para verificar los totales | que el pago no sea mayor a lo que se debe pagar en realidad
  verificarTotales(): Boolean{

    let valido = false;

    if(Number(this.sumaAbonos) <= Number(this.sumaCuenta)){//Si el pago es menor o igual a la cuenta total
      valido = true;
      this.totalesValidos = true;
    }else{
      valido = false;
      this.totalesValidos = false;
    }

    return valido
  }

  //*Método que verifica que sean válidos los valores
  verificarValores(){
    let valido = true;

    this.cuentas.forEach(x => {
      if(!(Number(x.precio) > 0)){
        valido = false;
      }
    });

    this.abonos.forEach(z => {
      if(!(Number(z.monto) > 0 )){
        valido = false;
      }
    });


    return valido;
  }

  descartarOrden(index){
      let detailRemoved = this.cuentas.splice(index, 1);
      this.calcularTotalCuenta();
      this.verificarEstado();

      //*Guardando los detalles de cuenta que se remueven de la lista
      this.detailsRemoved.push(...detailRemoved);
  }

  agregarAbono(){
    if(Number(this.abono) > 0){
      if(this.verificarAbono()){
        let suma = 0;
        let a = {
          fecha: new Date(),
          monto: Number(this.abono),
          registro: this.nameUserLogged
        }

        this.abonos.push(a);
        this.abonos.forEach(y => {
          suma = suma + parseFloat(y.monto);
        });
        this.sumaAbonos = suma.toFixed(2);
        this.abono = "0.00";

        //calcular el resto pediente a pagar
        this.calcularResto();
        this.estado = this.verificarEstado();

      }else{
        this.infoMessage('La cantidad del abono no puede ser mayor al cargo total o al resto pendiente','Cuenta por estudios');
      }
    }
  }

  //*Método para evaluar que el pago o abono no se mayor a la suma de toda la cuenta o al resto que el cliente debe abonar
  verificarAbono(){
    let check = false;
    let c = Number(this.sumaCuenta);
    if(this.abonos.length > 0){
      c = Number(this.restoPendiente);
    }

    let a = Number(this.abono);
    if( a <= c){
      check = true;
    }

    return check;
  }

  verificarEstado(){
    return Number(this.sumaCuenta) == Number(this.sumaAbonos) ? 'Cuenta pagada' : 'Pendiente de pago' ;
  }

  verificarEstadoBool(){
    return Number(this.sumaCuenta) == Number(this.sumaAbonos) ? true : false ;
  }

  validarPago(){
    if(this.verificarValores()){//*Si todos los valores estan correctamente digitados
      this.smartModal.getModal('cuentaParticularModal').close();
      this.smartModal.getModal('confirmaPagoParticularModal').open();
    }else{
      this.validValues = false;
    }
  }

  preparedDataCuenta(){

    //*Parseando a float los precios por servicios y los montos de pago
    this.cuentas.forEach((cuenta, i) => {
      this.cuentas[i].precio = parseFloat(cuenta.precio);
    });

    this.abonos.forEach((abono, j) => {
      this.abonos[j].monto = parseFloat(abono.monto);
    });

    let estado = this.verificarEstadoBool();
    //*Estructura de toda la cuenta
    let cuenta = {
      clienteId: this.idCliente,
      fecha: this.fechaCuenta,
      cliente: this.nombreCliente,
      cuenta: this.cuentas,
      abono: this.abonos,
      pagado: estado
    }

    this.cuentaParticularService.updateCuenta(this.idCuenta, cuenta).subscribe(
      response => {
          if(response.status == 'success'){
            this.successMessage('Se agregó un nuevo pago $$$', 'Cuenta orden particular');
            this.socketCuentaService.sendCuentaParticular(cuenta);//*Para actualizar en tiempo real
            this.verificarOrdenRemoved();//*Verificando si se ha eliminado una orden de estudios de la cuenta
            this.cancelar();
          }
      },
      error => {
        //console.log('Error al actualizar la cuenta: ', error);
        this.errorMessage('No se pudo agregar el pago', 'Cuenta orden particular');
      }
    );

  }

  validarPassword(){
    if(this.password.length > 0){

      //Preparando el objeto
      let data = {
        userId: this.idUserLogged,
        pass: this.password
      }
      //Petición para comparar las passwords
      this.usuarioService.validaPass(data).subscribe(
        response => {
            let match = response.match;
            if(match == true){
              this.smartModal.getModal('confirmaPagoParticularModal').close();
              this.preparedDataCuenta();
            }
        },
        err => {
            //console.log('Error al tratar de confirmar password', err);
            this.errorMessage("Error al confirmar contraseña","Contraseña inválida");
        }
      );
    }else{
      this.errorMessage("El campo está vacío","Contraseña inválida");
    }
  }

  cancelar(){
    this.idCuenta = '';
    this.fechaCuenta = '';
    this.idCliente = '';
    this.nombreCliente = '';
    this.cuentas = [];
    this.abonos = [];
    this.sumaCuenta = '0.00';
    this.sumaAbonos = '0.00';
    this.restoPendiente = '0.00';
    this.totalesValidos = false;
    this.validValues = false;
    this.estado = 'Pendiente de pago';
    this.abono = '0.00';
    this.password = '';

    this.detailsRemoved = [];
  }

  //*Método para prerarar los datos previo a exportar a PDF los datos de la cuenta
  cuentaToPDF(){

    let data = {
      numero: this.numeroCuenta,
      fecha: this.fechaFormatPipe.transform(this.fechaCuenta),
      cliente: this.nombreCliente,
      codigo: this.codigo,
      cuentaTotal: this.sumaCuenta,
      estado: this.verificarEstado(),
      cuenta: this.cuentas,
      abonos: this.abonos,
      pagoTotal: this.sumaAbonos,
      resto: this.restoPendiente
    }

    this.toPDFService.printCuentaEstudiosParticulares(data);
  }

  //*Verificando si se eliminaron una o mas ordenes de estudios
  verificarOrdenRemoved(){
    this.detailsRemoved.forEach(e => {
      this.deleteOrden(e.id_detalle._id);//*Haciendo referencia al _id porque viene dentro de la relacion o populate
    });
  }

  //*Petición para eliminar la orden de estudios particulares
  deleteOrden(id){
    this.ordenesParticularesService.deleteOrden(id).subscribe(
      resp => {
        //*actualizar mediante socket la lista de ordenes particulares y la lista de produccion
        this.socketOrdenParticularService.sendUpdateLista(resp);
      },
      err => {
        this.errorMessage('Error al eliminar la orden particular','Cuenta por estudios');
      }
    );
  }

  //Notificación cuando se agrega un pago
  successMessage(mensaje: string, titulo: string){
    this.toastr.success(mensaje, titulo);
  }

  errorMessage(mensaje: string, titulo: string){
    this.toastr.error(mensaje, titulo);
  }

  infoMessage(mensaje: string, titulo: string){
    this.toastr.info(mensaje, titulo);
  }



}
