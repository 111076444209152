import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { Global } from '../../../services/global';
import { Diagnostico } from '../models/diagnostico';

@Injectable({
 providedIn: 'root'
})
export class ConsultaService{
    public url: string;
    public diags: Diagnostico[] = [];

    private subJ$ = new Subject<void>();
    private subCuentaConsulta = new Subject<any>();//*Aqui se almacena el evento de mostrar una cuenta de consulta y se almacena su dato
    private subOrdenEstudio = new Subject<any>();//*Aqui se almancena el evento de mostrar una orden de estudios y se almacena su dato
    private subNewOrderEstudio = new Subject<any>();//*Aqui se almacena el evento de agregar una nueva orden de estudios y se almacena data a usar

    constructor(
        private _http: HttpClient
    ){
        this.url = Global.url;
    }

    //*Método para listar todas las consultas en general
    todasLasConsultas():Observable<any>{
        return this._http.get(this.url+'consulta/todasLasConsultas');
    }

    //*Método para recibir la lista de consulta paginadas
    getAllConsultas(pacienteId, page):Observable<any>{
        let params = new HttpParams().set('page', page);
        return this._http.get(this.url+'consulta/listar/'+pacienteId, {params});
    }

    //*!Posiblemente ya no se use este método
    getConsultas(pacienteId):Observable<any>{
        return this._http.get(this.url+'consulta/search/'+pacienteId);
    }

    //*Método para filtar registros de consultas entre dos fechas
    filtrarEntreFechas(pacienteId, page, fechaIn, fechaFin):Observable<any>{
        let params = new HttpParams()
        .set('page', page)
        .set('fechaIn', fechaIn)
        .set('fechaFin', fechaFin);
        return this._http.get(this.url+'consulta/consultasEntreFechas/'+pacienteId, {params});

    }

    getConsulta(consultaId):Observable<any>{
        return this._http.get(this.url+'consulta/obtener/'+consultaId);
    }

    //*Método para eliminar los registros de una consulta (consulta, receta, estudios, indicacione y cuenta por consulta)
    deteleConsulta(consultaId): Observable<any> {
      let headers = new HttpHeaders().set('Content-Type', 'application/json');
      return this._http.delete(this.url + 'consulta/delete/' + consultaId, { headers: headers });
    }

    getDiagnosticos(consultaId):Observable<any>{
        return this._http.get(this.url+'consulta/searchDiagnostico/'+consultaId);
    }

    getReceta(consultaId):Observable<any>{
        return this._http.get(this.url+'consulta/searchReceta/'+consultaId);
    }

    getIndicacion(consultaId):Observable<any>{
        return this._http.get(this.url+'consulta/searchIndicacion/'+consultaId);
    }

    //*Método para obtener la lista de indicaciones de ingreso
    getIndicaciones():Observable<any>{
        return this._http.get(this.url+'indicaciones/getIndicaciones');
    }

    create(consulta):Observable<any>{

        let params = JSON.stringify(consulta);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.post(this.url+'consulta/save', params, {headers: headers});
    }

    createOrdenEstudios(orden):Observable<any>{

        let params = JSON.stringify(orden);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.post(this.url+'estudios/saveOrden', params, {headers: headers});
    }

    obtenerOrdenEstudios(idConsulta):Observable<any>{
        return this._http.get(this.url+'estudios/findOrden/'+idConsulta);
    }

    updateOrdenEstudios(idConsulta, orden):Observable<any>{
        let params = JSON.stringify(orden);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.put(this.url+'estudios/updateOrden/'+idConsulta, params, {headers: headers});
    }

    update(id, consulta):Observable<any>{
        let params = JSON.stringify(consulta);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.put(this.url+'consulta/update/'+id, params, {headers: headers});
    }

    updateMonto(consulta):Observable<any>{
        let params = JSON.stringify(consulta);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.put(this.url+'consulta/update-monto', params, {headers: headers});
    }

    updateDiagnosticos(id, diagnosticos):Observable<any>{
        let params = JSON.stringify(diagnosticos);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.put(this.url+'consulta/updateDiagnostico/'+id, params, {headers: headers});
    }

    updateReceta(id, receta):Observable<any>{
        let params = JSON.stringify(receta);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.put(this.url+'consulta/updateReceta/'+id, params, {headers: headers});
    }

    updateIndicacion(id, indicacion):Observable<any>{
        let params = JSON.stringify(indicacion);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.put(this.url+'consulta/updateIndicacion/'+id, params, {headers: headers});
    }

    //*Método para actualizar la indicación de consulta por id de consulta médica
    updateIndicacionByConsulta(idConsulta, indicacion){
        let params = JSON.stringify(indicacion);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.put(this.url+'consulta/updateIndicacionByConsulta/'+idConsulta, params, {headers: headers});
    }

    //*Método para actualizar la lista de indicaciones de ingreso
    updateIndicaciones(id, lista){
        let params = JSON.stringify(lista);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.put(this.url+'indicaciones/updateIndicaciones/'+id, params, {headers: headers});
    }

    //*Uso de localStorage para guardar los motivos de consulta comunes
    addMotivos(key: string, data: string[]){
        //El localStorage solo almacena string o numeros por eso se convierte a json (menos pesado)
        try {
            localStorage.setItem(key, JSON.stringify(data));
        } catch (error) {
            //console.log("addMotivos", error);
        }
    }

    getMotivos(key: string){
        try {
            return JSON.parse(localStorage.getItem(key));
        } catch (error) {
            //console.log("getMotivos: ", error);
        }
    }

    imprimirReceta(data): Observable<any>{
        let params = JSON.stringify(data);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.post(this.url+'receta/reporte-receta', params, {headers: headers});
    }
    imprimirIndicaciones(data): Observable<any>{
        let params = JSON.stringify(data);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.post(this.url+'receta/reporte-indicaciones', params, {headers: headers});
    }
    actualizarConsultas(){
        //console.log("Hace click en regresar");
        this.subJ$.next();
    }

    getEventConsulta(): Observable<void>{
        //console.log("Event consulta");
        return this.subJ$.asObservable();
    }

    //*Métodos para enviar datos a otros componentes

    loadCuenta(valor){//*Este método se llamará cuando en el evento mostrar cuenta
        this.subCuentaConsulta.next(valor); //*Se almacena el valor
    }

    getCuentaDatos(): Observable<any>{//*Este método devuelve un observable que lee eventos y devuelve los valores generados
        return this.subCuentaConsulta.asObservable();
    }

    loadOrdenEstudios(valor){//*Este médoto se llamará en el evento mostrar estudios
        this.subOrdenEstudio.next(valor);//*Se almacena el valor
    }

    getOrdenEstudios(): Observable<any>{//*Este método devuelve un observable que lee eventos y devuelve los valores generados
        return this.subOrdenEstudio.asObservable();
    }

    loadDataForNewOrder(data){//*Este método se llamará en el evento agregar nueva orden de estudios
        this.subNewOrderEstudio.next(data);//*Se almacenan datos a usar en el componente que observará el evento y recibirá los datos
    }

    getNewOrderData(): Observable<any>{//*Este método devueve un observable que lee eventos y devuelve los valores generados
        return this.subNewOrderEstudio.asObservable();
    }

}
