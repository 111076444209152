import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from '../../services/global';
import { Observable } from 'rxjs';

import jwt_decode from "jwt-decode"; //Libreria para decodificar JWT

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public url: string;

  constructor(private _http: HttpClient) {
        this.url = Global.url;
   }

   login(usuario):Observable<any>{

        let params = JSON.stringify(usuario);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.post(this.url+'usuario/login', params, {headers: headers});
   }

   saveToken(key: string, token: string){

      try{
          localStorage.setItem(key, JSON.stringify(token));
      }catch(error){
          console.log("saveToken", error);
      }
   }

   getToken(key:string){
      try{
          return JSON.parse(localStorage.getItem(key));
      }catch(error){
        console.log("getToken", error);
      }
   }

   userLogged(){
      let token = this.getToken('token');
      let decoded = jwt_decode(token);

      return decoded['user'];//Está así porque no funciona con notación de punto

   }

   //*Metodo para verificar si el token ya expiró
   isTokenExpired(){
    let token = this.getToken('token');
    let decoded = jwt_decode(token);

    //Pasando la fecha actual a un formato para poder comparar
    let currentDate = Math.floor(new Date().getTime() / 1000);

    return decoded['exp'] <= currentDate;
   }

   getRole(){
      let user = this.userLogged();
      return user.roles[0].nombre;
   }

   userIsLogged(){
      if(this.getToken('token')){
            return true;
      }else{
         return false;
      }
   }

   logout(){
      localStorage.removeItem('token');
   }


}
