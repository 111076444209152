import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanActivateAuthGuardGuard } from './auth/guards/can-activate-auth-guard.guard';
import { CanActivateChildGuardGuard } from './auth/guards/can-activate-child-guard.guard';
import { IsAdminChildGuard } from './auth/guards/is-admin-child.guard';
import { IsAdminGuard } from './auth/guards/is-admin.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/pages/login/login.module').then( m => m.LoginModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./shared/pages/admin-home/admin-home.module').then( m => m.AdminHomeModule),
    canActivate: [CanActivateAuthGuardGuard, IsAdminGuard],
    canActivateChild: [CanActivateChildGuardGuard, IsAdminChildGuard]
  },
  {
    path: 'recepcion',
    loadChildren: () => import('./moduloConsulta/recepcion/pages/recepcion-home/recepcion-home.module').then( m => m.RecepcionHomeModule),
  },
  {
    path: 'mantenimiento',
    loadChildren: () => import('./auth/pages/mantenimiento/mantenimiento.module').then( m => m.MantenimientoModule)
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],//Se cambió a forRoot porque es el routing principal
  exports: [RouterModule]
})
export class AppRoutingModule { }
