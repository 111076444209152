import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'rol'
})

export class Rol implements PipeTransform{

    transform(value: any){

        let texto = '';

        switch (value) {
            case "admin":
                    texto = "Administración"
            break;
            case "recepcion":
                    texto = "Recepción"
            break;
            case "laboratorio":
                    texto = "Laboratorio"
            break;
            case "enfermeria":
                    texto = "Enfermeria"
            break;
            default:
            break;
        }

   

        return texto;

    }

}