
 <!-- Este es un modal que muestra una calculadora -->
<div class="modal fade" id="modal-default">
  <div class="modal-dialog">
    <div class="modal-content">
      
      <div class="modal-body">
        
        <input type="text" class="calculator-screen" [value]="currentNumber" disabled />
            
        <div class="calculator-keys">
          
          <button (click) = "getOperation('+')" class="button-cal operator" value="+">+</button>
          <button (click) = "getOperation('-')" class="button-cal operator" value="-">-</button>
          <button (click) = "getOperation('*')" class="button-cal operator" value="*">&times;</button>
          <button (click) = "getOperation('/')" class="button-cal operator" value="/">&divide;</button>
      
          <button class="button-cal" (click) = "getNumber('7')" value="7">7</button>
          <button class="button-cal" (click) = "getNumber('8')" value="8">8</button>
          <button class="button-cal" (click) = "getNumber('9')" value="9">9</button>
      
      
          <button class="button-cal" (click) = "getNumber('4')" value="4">4</button>
          <button class="button-cal" (click) = "getNumber('5')" value="5">5</button>
          <button class="button-cal" (click) = "getNumber('6')" value="6">6</button>
      
      
          <button class="button-cal" (click) = "getNumber('1')" value="1">1</button>
          <button class="button-cal" (click) = "getNumber('2')" value="2">2</button>
          <button class="button-cal" (click) = "getNumber('3')" value="3">3</button>
      
      
          <button class="button-cal" (click) = "getNumber('0')" value="0">0</button>
          <button (click) = "getDecimal()" class="decimal" value=".">.</button>
          <button (click) = "clear()"  class="button-cal all-clear" value="all-clear">AC</button>
      
          <button (click) = "getOperation('=')" class="button-cal equal-sign" value="=">=</button>
      
        </div>

      </div>
      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-primary" data-dismiss="modal">Cerrar</button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
<!-- /.modal -->       
