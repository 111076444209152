import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fechaFuturaBadge3'
})

export class FechaFuturaBadge3 implements PipeTransform{

    transform(value: any){//se obtiene la fecha en formato Date desde el backend

      let clase = 'badge badge-info';//por defecto

      if(value.length > 0){
        let fecha = new Date(value).setHours(0,0,0,0);
        let hoy = new Date().setHours(0,0,0,0);

        if(fecha < hoy){
          clase = 'badge badge-secondary';//para feechas caducadas
        }else{
          clase = 'badge badge-info';//para fechas vigentes
        }

      }

        return clase;

    }

}
