<!--<app-page-header
[titulo]="'Expedientes'"
[icono]="'fas fa-archive'"
[breadcrumbs]="breads">

</app-page-header>


<section class="content">
<div class="container-fluid">
    

<div class="card card-info">
    <div class="card-header">
      <h3 class="card-title">Historial cuenta de paciente</h3>
      <div class="card-tools"></div>
    </div>
    <div class="card-body">
      <form
        role="form"
        #cuentaForm="ngForm"
      >
        <div class="row">
          <div class="col-md-5">
            <div class="form-group">
              <label for="descripcion">Concepto de pago:</label>
              <div class="input-group mb-3">
                <input
                  type="text"
                  #descripcion="ngModel"
                  [(ngModel)]="cuenta.descripcion"
                  name="descripcion"
                  class="form-control"
                  placeholder="Descripción"
                  required
                  [ngClass]="{
                    'is-invalid': !descripcion.valid && descripcion.touched
                  }"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fa fa-info"></span>
                  </div>
                </div>
              </div>
              <small
                class="text-danger"
                *ngIf="!descripcion.valid && descripcion.touched"
              >
                Campo requerido
              </small>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="monto">Monto a pagar:</label>
              <div class="input-group mb-3">
                <input
                  type="text"
                  #monto="ngModel"
                  [(ngModel)]="cuenta.monto"
                  name="monto"
                  class="form-control"
                  placeholder="Monto inicial"
                  prefix="$"
                  mask="0*.00"
                  required
                  [ngClass]="{
                    'is-invalid': !monto.valid && monto.touched
                  }"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <i class="fas fa-file-invoice-dollar"></i>
                  </div>
                </div>
              </div>
              <small
                class="text-danger"
                *ngIf="!monto.valid && monto.touched"
              >
                Campo requerido
              </small>
            </div>
          </div>
          
        </div>
        
      
<div class="row">
        
  <div class="col-md-12">

      
      <div class="card">
          <div class="card-header border-transparent">
              <h3 class="card-title" >No se han encontrado registros</h3>
          </div>
          
          <div class="card-body p-0">

              <div class="table-responsive" id="tabRec">
                      <table class="table m-0" id="tab">
                      <thead>
                      <tr>
                          <th>Concepto de pago</th>
                          <th style="width: 200px;">Monto a pagar</th>
                          <th style="width: 10px;">Descartar</th>
                      </tr>
                      </thead>
                      <tbody>
                          
                          <ng-container>
                              <tr >
                                  <td></td>
                                  <td></td>
                                  <td>
                                      <button class="btn btn-block btn-default"
                                             >
                                          <i class="fas fa-backspace"></i>
                                      </button>
                                  </td>
                              </tr>
                          </ng-container>
                          
                      </tbody>
                      </table>
              </div>
              
              <hr>
              
          </div>
          
          
      </div>
      

  </div>  

</div>
 

<div class="row">
  <div class="col-md-8"></div>
  <div class="col-md-2">
    <div class="form-group">
      <label for="total">Total a pagar: </label>
      <div class="input-group mb-3">
        <input
          name="total"
          #total="ngModel"
          type="text"
          class="form-control"
          placeholder="Total a pagar"
          prefix="$"
          mask="0*.00"
          [(ngModel)]="cuenta.total"
          [ngClass]="{
            'is-invalid':
              !total.valid && total.touched
          }"
          required
        />
        <div class="input-group-append">
          <div class="input-group-text">
            <i class="fas fa-file-invoice-dollar"></i>
          </div>
        </div>
      </div>
      <small
        class="text-danger"
        *ngIf="!total.valid && total.touched"
      >
        Campo requerido
      </small>
    </div>
  </div>
  
  <div class="col-md-2">
    <div class="form-group">
      <label for="pendiente">Pendiente de pago: </label>
      <div class="input-group mb-3">
        <input
          name="pendiente"
          #pendiente="ngModel"
          type="text"
          class="form-control"
          placeholder=" 0.00"
          prefix="$"
          mask="0*.00"
          [(ngModel)]="cuenta.pendiente"
          [ngClass]="{
            'is-invalid': !pendiente.valid && pendiente.touched
          }"
          required
        />

        <div class="input-group-append">
          <div class="input-group-text">
            <i class="fas fa-file-invoice-dollar"></i>
          </div>
        </div>
      </div>
      <small class="text-danger" *ngIf="!pendiente.valid && pendiente.touched">
        Campo requerido
      </small>
    </div>
  </div>
</div>

<div class="row">
<div class="card-footer clearfix">
           
  <button 
          class="btn btn-info float-left">
        <i class="fas fa-check"></i> Guardar pagos
  </button>
  <button class="btn btn-secondary ml-3 mr-0 float-right">
      <i class="fas fa-print"></i> Imprimir
  </button>
</div>
</div>
</form>
</div>
</div>
</div>
</section>
-->
<ngx-smart-modal
  #cuentaModal
  identifier="cuentaModal"
  [customClass]="'modal-lg'"
  [dismissable]="false"
  [closable]="false"
>
  <div class="modal-body">
    <h4>
      Pacientes pendientes de pago
      <i class="nav-icon fas fa-file-invoice-dollar"></i>
    </h4>
    <table class="table table-sm table-bordered">
      <thead>
        <tr>
          <th style="width: 7px">#</th>
          <th>Paciente</th>
          <th style="width: 100px">Acción</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of cuentaPacientes; let i = index">
          <td>{{ i + 1 }}</td>
          <td>{{ item.nombre }}</td>
          
          <td>
            <button
              class="btn btn-block btn-default"
              (click)="ngxSmartModalService.getModal('pagoModal').open(); llevarIndex(item)"
            >
              Pagar
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    
    <button type="button" class="btn btn-info float-right" (click)="listarDeudores()"><i class="fas fa-sync-alt"></i></button>
    <button class="btn btn-info float-right mr-1" (click)="cuentaModal.close();">
      Cerrar
    </button>
  </div>
</ngx-smart-modal>

<ngx-smart-modal
  #pagoModal
  identifier="pagoModal"
  [customClass]="'modal-lg'"
  [dismissable]="false"
  [closable]="false"
>
  <div class="modal-body">
    <h4>
      <i class="nav-icon fas fa-file-invoice-dollar"></i> {{cuenta.paciente}} 
    </h4><br>
    <table class="table table-sm table-bordered">
      <thead>
        <tr>
          <th style="width: 7px">#</th>
          <th>Fecha de la Consulta</th>
          <th>Precio</th>
          <th style="width: 100px">Abono</th>
          <th style="width: 100px">Acción</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of cuenta.consultas; let i = index">
          <td>{{ i + 1 }}</td>
          <td>{{ item.fechaConsul}}</td>
          <td>{{ item.precio }}</td>
          <td>
              <input [disabled]="item.activo"
                type="text"
                #monto="ngModel"
                [(ngModel)]="item.abono"
                name="monto"
                class="form-control"
                prefix="$"
              />
          </td>
          <td>
            <button
            [disabled]="monto.value == ''"
              class="btn btn-block btn-default"
              (click)="actualizarTotal(item.abono,i)"
            >
              Cancelar
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <br>
    <div class="row">
      <div class="col-md-9"></div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="total">Total cuenta:</label>
          <div class="input-group mb-3">
            <input
              type="text"
              #total="ngModel"
              [(ngModel)]="cuenta.total"
              name="total"
              class="form-control"
              placeholder="Total a pagar"
              prefix="$"
              disabled
            />
            <div class="input-group-append">
              <div class="input-group-text">
                <i class="fas fa-file-invoice-dollar"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--<div class="col-md-3">
        <div class="form-group">
          <label for="monto">Monto a pagar:</label>
          <div class="input-group mb-3">
            <input
              type="number"
              #monto="ngModel"
              [(ngModel)]="cuenta.monto"
              name="monto"
              class="form-control"
              placeholder="Monto inicial"
              prefix="$"
              disabled
            />
            <div class="input-group-append">
              <div class="input-group-text">
                <i class="fas fa-file-invoice-dollar"></i>
              </div>
            </div>
          </div>
        </div>
      </div>-->
    </div>
    <button type="button" class="btn btn-info float-right" (click)="listarDeudores()"><i class="fas fa-sync-alt"></i></button>
    <button class="btn btn-info float-right mr-1" (click)="pagoModal.close(); listarDeudores()">
      Cerrar
    </button>
  </div>
</ngx-smart-modal>
<!-- Sweet alerts -->
<!-- Sweet Confirm -->
<swal
  #redireccionSwal
  title="Consulta cancelada!"
  text="La consulta ha sido cancelada"
  icon="success"
  showCancelButton="true"
  confirmButtonText="OK!"
  denyButtonText="No!"
  confirmButtonColor="#3085d6"
  cancelButtonColor="#bbbbbb"
  [focusCancel]="true"
>
</swal>
<swal
  #redireccionAbonoSwal
  title="Abono a consulta!"
  text="Se hizo un abono a la consulta"
  icon="success"
  showCancelButton="true"
  confirmButtonText="OK!"
  denyButtonText="No!"
  confirmButtonColor="#3085d6"
  cancelButtonColor="#bbbbbb"
  [focusCancel]="true"
>
</swal>
