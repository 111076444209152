import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fechaFutura'
})

export class FechaFutura implements PipeTransform{

    transform(value: any){//se obtiene la fecha en formato Date desde el backend

      let estadoString = 'FECHA CADUCADA'; //'FECHA PROGRAMADA', 'FECHA ACTUAL DE VACACIÓN'

      if(value.length > 0){
        let fecha = new Date(value).setHours(0,0,0,0);
        let hoy = new Date().setHours(0,0,0,0);

        if(fecha < hoy){
          estadoString = 'VACACIÓN CADUCADA';
        }else if(fecha > hoy){
          estadoString = 'VACACIÓN PROGRAMADA';
        }else{
          estadoString = 'FECHA ACTUAL DE VACACIÓN';
        }

      }

        return estadoString;

    }

}
