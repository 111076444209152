<!-- Main Sidebar Container -->
<aside class="main-sidebar sidebar-light-info elevation-4">
  <!-- Brand Logo -->
  <a [routerLink]="['/home']" class="brand-link">
    <img src="assets/dist/img/sinai_logo_2.jpg" alt="mountSinai Logo" class="brand-image" style="opacity: .8">

    <span class="brand-text font-weight-light">Mount Sinaí</span>

  </a>

  <!-- Sidebar -->
  <div class="sidebar">
    <!-- Sidebar user panel (optional) -->
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
      <div class="image">
        <img [src]="userImage" class="img-circle elevation-2" alt="User Image" style="width: 40px; height: 40px;"
          (error)="CargarImagenPorDefecto()">
      </div>
      <div class="info">
        <a [routerLink]="['/home']" class="d-block">
          <span class="brand-text font-weight-light">
            {{ roleTitle }}
          </span>

        </a>
      </div>
    </div>

    <!-- Sidebar Menu -->
    <nav [ngClass]="'mt-2'">
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="true">
        <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->

        <li class="nav-header">CONSULTA</li>
        <li class="nav-item">
          <a class="nav-link" style="cursor: pointer;"
            (click)="ngxSmartModalService.getModal('colaModal').open(); OnClick();">
            <i class="nav-icon fas fa-users"></i>
            <p>
              Pacientes en espera
              <span class="badge badge-info right">{{ colaCount }}</span>
            </p>
          </a>
        </li>
        <li [ngClass]="'nav-item has-treeview'" *ngIf="role === 'admin'">
          <a [ngClass]="'nav-link'" [routerLink]="['/admin/expedientes']" [routerLinkActive]="['active']">
            <i [ngClass]="'nav-icon fas fa-archive'"></i>
            <p>
              Expedientes
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>

        </li>
        <li [ngClass]="'nav-item has-treeview'" *ngIf="role === 'enfermeria'">
          <a [ngClass]="'nav-link'" [routerLink]="['/recepcion/expedientes']" [routerLinkActive]="['active']">
            <i [ngClass]="'nav-icon fas fa-archive'"></i>
            <p>
              Expedientes
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>

        </li>
        <li [ngClass]="'nav-item has-treeview'" *ngIf="role === 'admin'">
          <a [ngClass]="'nav-link'" [routerLink]="['/admin/registrarPaciente']" [routerLinkActive]="['active']">
            <i [ngClass]="'nav-icon fas fa-folder-open'"></i>
            <p>
              Nuevo expediente
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>

        </li>
        <li [ngClass]="'nav-item has-treeview'" *ngIf="role === 'enfermeria'">
          <a [ngClass]="'nav-link'" [routerLink]="['/recepcion/registrarPaciente']" [routerLinkActive]="['active']">
            <i [ngClass]="'nav-icon fas fa-folder-open'"></i>
            <p>
              Nuevo expediente
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>

        </li>
        <li class="nav-item" *ngIf="role === 'admin'">
          <a class="nav-link" style="cursor: pointer;" (click)="ngxSmartModalService.getModal('cuentasModal').open();">
            <i class="nav-icon fas fa-hand-holding-usd"></i>
            <p>
              Cuentas por consulta
              <span class="badge badge-success right"> {{ cuentaCount }} </span>
            </p>
          </a>
        </li>
        <li class="nav-item" *ngIf="role === 'enfermeria'">
          <a class="nav-link" style="cursor: pointer;" (click)="ngxSmartModalService.getModal('cuentasModal').open();">
            <i class="nav-icon fas fa-hand-holding-usd"></i>
            <p>
              Cuentas por consulta
              <span class="badge badge-success right"> {{ cuentaCount }} </span>
            </p>
          </a>
        </li>
        <li class="nav-item" *ngIf="role === 'admin'">
          <a [ngClass]="'nav-link'" [routerLink]="['/admin/indicaciones']" [routerLinkActive]="['active']"
            style="cursor: pointer;">
            <i class="nav-icon fas fa-list"></i>
            <p>
              Indicaciones
              <span class="badge badge-secondary right"> {{ listaCount }} </span>
            </p>
          </a>
        </li>
        <li class="nav-item" *ngIf="role === 'enfermeria'">
          <a [ngClass]="'nav-link'" [routerLink]="['/recepcion/indicaciones']" [routerLinkActive]="['active']"
            style="cursor: pointer;">
            <i class="nav-icon fas fa-list"></i>
            <p>
              Indicaciones
              <span class="badge badge-secondary right"> {{ listaCount }} </span>
            </p>
          </a>
        </li>
        <li [ngClass]="'nav-item has-treeview'" *ngIf="role === 'admin'">
          <a [ngClass]="'nav-link'" [routerLink]="['/admin/archivo']" [routerLinkActive]="['active']">
            <i [ngClass]="'nav-icon fas fa-box'"></i>
            <p>
              Archivo
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        </li>
        <li [ngClass]="'nav-item has-treeview'" *ngIf="role === 'enfermeria'">
          <a [ngClass]="'nav-link'" [routerLink]="['/recepcion/archivo']" [routerLinkActive]="['active']">
            <i [ngClass]="'nav-icon fas fa-box'"></i>
            <p>
              Archivo
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        </li>

        <li class="nav-header" *ngIf="role === 'admin'">AGENDA</li>
        <li class="nav-item has-treeview" *ngIf="role === 'admin'">
          <a [ngClass]="'nav-link'" [routerLink]="['/admin/agenda/calendario']" [routerLinkActive]="['active']">
            <i class="nav-icon fas fa-calendar-day"></i>
            <p>
              Calendario general
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
        </li>

        <li class="nav-header" *ngIf="role === 'admin'">LABORATORIO</li>
        <li class="nav-header" *ngIf="role === 'enfermeria'">LABORATORIO</li>
        <li class="nav-item has-treeview" *ngIf="role === 'admin'">
          <a [ngClass]="'nav-link'" [routerLink]="['/admin/ordenes']" [routerLinkActive]="['active']"
            style="cursor: pointer;">
            <i class="nav-icon fas fa-clipboard-list"></i>
            <p>
              Ordenes por consulta
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        </li>

        <li class="nav-item has-treeview" *ngIf="role === 'enfermeria'">
          <a [ngClass]="'nav-link'" [routerLink]="['/recepcion/ordenes']" [routerLinkActive]="['active']"
            style="cursor: pointer;">
            <i class="nav-icon fas fa-clipboard-list"></i>
            <p>
              Ordenes por consulta
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        </li>

        <li [ngClass]="'nav-item has-treeview'" *ngIf="role === 'admin'">
          <a [ngClass]="'nav-link'" [routerLink]="['/admin/laboratorio/nuevo-cliente-lab']"
            [routerLinkActive]="['active']">
            <i [ngClass]="'nav-icon fas fa-user-check'"></i>
            <p>
              Nuevo cliente
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        </li>
        <li [ngClass]="'nav-item has-treeview'" *ngIf="role === 'enfermeria'">
          <a [ngClass]="'nav-link'" [routerLink]="['/recepcion/laboratorio/nuevo-cliente-lab']"
            [routerLinkActive]="['active']">
            <i [ngClass]="'nav-icon fas fa-user-check'"></i>
            <p>
              Nuevo cliente
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        </li>

        <li [ngClass]="'nav-item has-treeview'" *ngIf="role === 'admin'">
          <a [ngClass]="'nav-link'" [routerLink]="['/admin/laboratorio/clientes']" [routerLinkActive]="['active']">
            <i [ngClass]="'nav-icon fas fa-users'"></i>
            <p>
              Clientes registrados
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        </li>
        <li [ngClass]="'nav-item has-treeview'" *ngIf="role === 'enfermeria'">
          <a [ngClass]="'nav-link'" [routerLink]="['/recepcion/laboratorio/clientes']" [routerLinkActive]="['active']">
            <i [ngClass]="'nav-icon fas fa-users'"></i>
            <p>
              Clientes registrados
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        </li>

        <li [ngClass]="'nav-item has-treeview'" *ngIf="role === 'admin'">
          <a [ngClass]="'nav-link'" [routerLink]="['/admin/laboratorio/nueva-orden-particular']"
            [routerLinkActive]="['active']">
            <i [ngClass]="'nav-icon fas fa-flask'"></i>
            <p>
              Nueva O. Particular
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        </li>
        <li [ngClass]="'nav-item has-treeview'" *ngIf="role === 'enfermeria'">
          <a [ngClass]="'nav-link'" [routerLink]="['/recepcion/laboratorio/nueva-orden-particular']"
            [routerLinkActive]="['active']">
            <i [ngClass]="'nav-icon fas fa-flask'"></i>
            <p>
              Nueva O. Particular
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        </li>

        <li [ngClass]="'nav-item has-treeview'" *ngIf="role === 'admin'">
          <a [ngClass]="'nav-link'" [routerLink]="['/admin/laboratorio/ordenes-particulares']"
            [routerLinkActive]="['active']">
            <i [ngClass]="'nav-icon fas fa-vials'"></i>
            <p>
              Ordenes particulares
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        </li>

        <li [ngClass]="'nav-item has-treeview'" *ngIf="role === 'enfermeria'">
          <a [ngClass]="'nav-link'" [routerLink]="['/recepcion/laboratorio/ordenes-particulares']"
            [routerLinkActive]="['active']">
            <i [ngClass]="'nav-icon fas fa-vials'"></i>
            <p>
              Ordenes particulares
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        </li>

        <li class="nav-item" *ngIf="role === 'admin'">
          <a class="nav-link" style="cursor: pointer;"
            (click)="ngxSmartModalService.getModal('particularDeudoresModal').open();">
            <i class="nav-icon fas fa-hand-holding-usd"></i>
            <p>
              Cuentas por estudios
              <span class="badge badge-success right"> {{ cuentaParticularCount }} </span>
            </p>
          </a>
        </li>

        <li class="nav-item" *ngIf="role === 'enfermeria'">
          <a class="nav-link" style="cursor: pointer;"
            (click)="ngxSmartModalService.getModal('particularDeudoresModal').open();">
            <i class="nav-icon fas fa-hand-holding-usd"></i>
            <p>
              Cuentas por estudios
              <span class="badge badge-success right"> {{ cuentaParticularCount }} </span>
            </p>
          </a>
        </li>

        <li [ngClass]="'nav-item has-treeview'" *ngIf="role === 'admin'">
          <a [ngClass]="'nav-link'" [routerLink]="['/admin/laboratorio/produccion-ordenes']"
            [routerLinkActive]="['active']">
            <i [ngClass]="'nav-icon fas fa-cogs'"></i>
            <p>
              Producción de ordenes
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        </li>

        <li [ngClass]="'nav-item has-treeview'" *ngIf="role === 'enfermeria'">
          <a [ngClass]="'nav-link'" [routerLink]="['/recepcion/laboratorio/produccion-ordenes']"
            [routerLinkActive]="['active']">
            <i [ngClass]="'nav-icon fas fa-cogs'"></i>
            <p>
              Producción de ordenes
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        </li>

        <li [ngClass]="'nav-item has-treeview'" *ngIf="role === 'admin'">
          <a [ngClass]="'nav-link'" [routerLink]="['/admin/laboratorio/catalogo-estudios']"
            [routerLinkActive]="['active']">
            <i [ngClass]="'nav-icon fas fa-book'"></i>
            <p>
              Catálogo de estudios
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        </li>

        <li class="nav-header" *ngIf="role === 'admin'">INVENTARIOS</li>
        <li class="nav-header" *ngIf="role === 'enfermeria'">INVENTARIOS</li>

        <li class="nav-item has-treeview" *ngIf="role === 'admin'">
          <a class="nav-link" [routerLink]="['/admin/inventarios/nuevo-articulo']" [routerLinkActive]="['active']">
            <i class="nav-icon fas fa-box"></i>
            <p>
              Nuevo artículo
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
        </li>

        <li class="nav-item has-treeview" *ngIf="role === 'enfermeria'">
          <a class="nav-link" [routerLink]="['/recepcion/inventarios/nuevo-articulo']" [routerLinkActive]="['active']">
            <i class="nav-icon fas fa-box"></i>
            <p>
              Nuevo artículo
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
        </li>

        <li class="nav-item has-treeview" *ngIf="role === 'admin'">
          <a class="nav-link" [routerLink]="['/admin/inventarios/lista-insumos']" [routerLinkActive]="['active']">
            <i class="nav-icon fas fa-boxes"></i>
            <p>
              Insumos médicos
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
        </li>

        <li class="nav-item has-treeview" *ngIf="role === 'enfermeria'">
          <a class="nav-link" [routerLink]="['/recepcion/inventarios/lista-insumos']" [routerLinkActive]="['active']">
            <i class="nav-icon fas fa-boxes"></i>
            <p>
              Insumos médicos
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
        </li>

        <li class="nav-item has-treeview" *ngIf="role === 'admin'">
          <a [ngClass]="'nav-link'" [routerLink]="['/admin/inventarios/pedidos-insumos']"
            [routerLinkActive]="['active']">
            <i class="nav-icon fas fa-shipping-fast"></i>
            <p>
              Pedidos de insumos
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        </li>
        <li class="nav-item has-treeview" *ngIf="role === 'enfermeria'">
          <a [ngClass]="'nav-link'" [routerLink]="['/recepcion/inventarios/pedidos-insumos']"
            [routerLinkActive]="['active']">
            <i class="nav-icon fas fa-shipping-fast"></i>
            <p>
              Pedidos de insumos
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        </li>
        <li class="nav-item has-treeview" *ngIf="role === 'admin'">
          <a [ngClass]="'nav-link'" [routerLink]="['/admin/inventarios/compras']" [routerLinkActive]="['active']">
            <i class="nav-icon fas fa-money-check"></i>
            <p>
              Compras
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        </li>

        <li class="nav-item has-treeview" *ngIf="role === 'enfermeria'">
          <a [ngClass]="'nav-link'" [routerLink]="['/recepcion/inventarios/compras']" [routerLinkActive]="['active']">
            <i class="nav-icon fas fa-money-check"></i>
            <p>
              Compras
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        </li>

        <li class="nav-item has-treeview" *ngIf="role === 'admin'">
          <a [ngClass]="'nav-link'" [routerLink]="['/admin/inventarios/nuevo-proveedor']"
            [routerLinkActive]="['active']">
            <i class="nav-icon fas fa-user-cog"></i>
            <p>
              Nuevo proveedor
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        </li>
        <li class="nav-item has-treeview" *ngIf="role === 'enfermeria'">
          <a [ngClass]="'nav-link'" [routerLink]="['/recepcion/inventarios/nuevo-proveedor']"
            [routerLinkActive]="['active']">
            <i class="nav-icon fas fa-user-cog"></i>
            <p>
              Nuevo proveedor
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        </li>
        <li class="nav-item has-treeview" *ngIf="role === 'admin'">
          <a [ngClass]="'nav-link'" [routerLink]="['/admin/inventarios/proveedores']" [routerLinkActive]="['active']">
            <i class="nav-icon fas fa-dolly-flatbed"></i>
            <p>
              Proveedores
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        </li>
        <li class="nav-item has-treeview" *ngIf="role === 'enfermeria'">
          <a [ngClass]="'nav-link'" [routerLink]="['/recepcion/inventarios/proveedores']"
            [routerLinkActive]="['active']">
            <i class="nav-icon fas fa-dolly-flatbed"></i>
            <p>
              Proveedores
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        </li>

        <li class="nav-header" *ngIf="role === 'admin'">EMPLEADOS</li>
        <li class="nav-item has-treeview" *ngIf="role === 'admin'">
          <a [ngClass]="'nav-link'" [routerLink]="['/admin/empleados/nuevo-empleado']" [routerLinkActive]="['active']">
            <i class="nav-icon fas fa-user-cog"></i>
            <p>
              Nuevo empleado
              <i class="fas fa-angle-left right"></i>

            </p>
          </a>

        </li>
        <li class="nav-item has-treeview" *ngIf="role === 'admin'">
          <a [ngClass]="'nav-link'" [routerLink]="['/admin/empleados/lista-empleados']" [routerLinkActive]="['active']">
            <i class="nav-icon fas fa-users-cog"></i>
            <p>
              Empleados
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
        </li>

        <li class="nav-item has-treeview" *ngIf="role === 'admin'">
          <a [ngClass]="'nav-link'" [routerLink]="['/admin/empleados/asistencia']" [routerLinkActive]="['active']">
            <i class="nav-icon fas fa-check-square"></i>
            <p>
              Control de asistencias
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
        </li>
        <li class="nav-item has-treeview" *ngIf="role === 'admin'">
          <a [ngClass]="'nav-link'" [routerLink]="['/admin/empleados/licencias']" [routerLinkActive]="['active']">
            <i class="nav-icon fas fa-file-import"></i>
            <p>
              Registro de licencias
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
        </li>
        <li class="nav-item has-treeview" *ngIf="role === 'admin'">
          <a [ngClass]="'nav-link'" [routerLink]="['/admin/empleados/registro-planillas']"
            [routerLinkActive]="['active']">
            <i class="nav-icon fas fa-calculator"></i>
            <p>
              Registro de planillas
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
        </li>

        <li class="nav-header" *ngIf="role === 'admin'">ACTIVO FIJO</li>
        <li class="nav-item" *ngIf="role === 'admin'">
          <a [ngClass]="'nav-link'" [routerLink]="['/admin/activo-fijo/lista-activos']" [routerLinkActive]="['active']">
            <i class="nav-icon fas fa-hospital-alt"></i>
            <p>
              Lista de activos
            </p>
          </a>
        </li>

        <li class="nav-header" *ngIf="role === 'admin'">SEGURIDAD</li>
        <li [ngClass]="'nav-item has-treeview'" *ngIf="role === 'admin'">
          <a [ngClass]="'nav-link'" [routerLink]="['/admin/usuarios']" [routerLinkActive]="['active']">
            <i [ngClass]="'nav-icon fas fa-user-friends'"></i>
            <p>
              Usuarios
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>

        </li>
        <li class="nav-item" *ngIf="role === 'admin'">
          <a class="nav-link">
            <i class="nav-icon fas fa-book"></i>
            <p>Bitácora general</p>
          </a>
        </li>

        <li class="nav-header">HERRAMIENTAS</li>
        <li class="nav-item">
          <a href="#modal-default" class="nav-link" data-toggle="modal" data-target="#modal-default">
            <i class="nav-icon fas fa-calculator"></i>
            <p>
              Calculadora
            </p>
          </a>
        </li>

        <li class="nav-item" *ngIf="role === 'admin'">
          <a class="nav-link">
            <i class="nav-icon fas fa-book"></i>
            <p>Documentación general</p>
          </a>
        </li>

        <li class="nav-item" *ngIf="role === 'recepcion'">
          <a class="nav-link">
            <i class="nav-icon fas fa-book"></i>
            <p>Documentación</p>
          </a>
        </li>

        <br>
        <br>
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>

<app-cola-pacientes></app-cola-pacientes>
<app-cuenta-paciente></app-cuenta-paciente>
<app-cuenta-consulta></app-cuenta-consulta>
<app-confirma-password></app-confirma-password>
<app-cuenta-orden-particular></app-cuenta-orden-particular>
