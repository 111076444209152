import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from './global'
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ColaService {

  public url: string;

  private loadColaSource$ = new Subject<void>();

  private colaAtendidos = []; //Un arreglo auxiliar para acceder a la cola de pacientes atendidos

  constructor(
    private _http: HttpClient
  ) {
    this.url = Global.url;
  }

  getCola():Observable<any>{
    return this._http.get(this.url+'cola/getCola');
  }

  updateCola(id, cola){
    let params = JSON.stringify(cola);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.put(this.url+'cola/updateCola/'+id, params, {headers: headers});
  }

  cargarCola(){
      this.loadColaSource$.next();//El subject emite el evento
  }

  getLoadCola(): Observable<void>{ //Método que retorna un observable de los eventos que emite el subject
      return this.loadColaSource$.asObservable();
  }

  setAtentidos(atendidos){
    this.colaAtendidos = atendidos;
  }

  getAtendidos(){
    return this.colaAtendidos;
  }

}
