import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'percentDeduction'
})

export class PercentDeduction implements PipeTransform{

    transform(salarioBase: string, porcentaje: number){
        let salario = salarioBase.length > 0 ? Number(salarioBase): 0.00;
        let deduccion = 0.00;
        let monto = 0.00;
        if(Number(salarioBase) > 0.00 && porcentaje > 0.00 && porcentaje <= 100.00){
          deduccion = (porcentaje/100)*salario;
          monto = Number(deduccion.toFixed(2));
        }

        return monto.toFixed(2);
    }

}
