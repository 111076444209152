import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';

//importando el servicio Global para acceder a la url del servidor
import { Global } from './services/global';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
//Reactive module es otra manera de manejar los formularios, ver documentación
import { ReactiveFormsModule } from '@angular/forms';

//importacion del modulo que comparte para toda la aplicación
import { SharedModule } from './shared/shared.module';

import { AppRoutingModule } from './app-routing.module';

//Modulos propios
import { GeneralComponentsModule } from './shared/components/general-components.module';
import { PipesModule } from './shared/pipes/pipes.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConnectionServiceModule } from 'ng-connection-service';

//Importando interceptor para agregar la cabecera de autorización a todas las request
import { TokenInterceptorService } from './auth/interceptors/tokenInterceptor.service';

//Importando guards
import { CanActivateAuthGuardGuard } from './auth/guards/can-activate-auth-guard.guard';
import { CanActivateChildGuardGuard } from './auth/guards/can-activate-child-guard.guard';
import { IsAdminGuard } from './auth/guards/is-admin.guard';
import { IsAdminChildGuard } from './auth/guards/is-admin-child.guard';

//Importando libreria para manejar socket.io desde el front ngx-socket-io
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { NotFoundInterceptorService } from './auth/interceptors/notFoundInterceptor.service';


//Configuración de la dirección del servidor y otras opciones
const config: SocketIoConfig = { url: Global.url, options: {} };

//Configuración del idioma para usar ngbDateRangePicker
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/es';
import localeDeExtra from '@angular/common/locales/extra/es';
registerLocaleData(localeDe, 'es', localeDeExtra);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,//SOLO SE IMPORTA UNA VEZ AQUI EN ROOT MODULE
    SocketIoModule.forRoot(config),
    BrowserAnimationsModule, // Se requiere el modulo de animaciones de angular, SOLO SE IMPORTA AQUI EN ROOT MODULE
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule, //Agregando manualmente este modulo
    ConnectionServiceModule,

    GeneralComponentsModule,
    SharedModule,
    PipesModule
  ],
  exports: [
    SharedModule,
    GeneralComponentsModule,
    PipesModule
  ],
  providers: [
    CanActivateAuthGuardGuard,
    CanActivateChildGuardGuard,
    IsAdminGuard,
    IsAdminChildGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotFoundInterceptorService,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'es-ES' }//para cambiar el idioma de dateRangePicker
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
