import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'estadoCaducidadText'
})

export class EstadoCaducidadText implements PipeTransform{

    transform(estado: string, fechaCad: Date, diasPrevios: number){
        let texto = 'Sin riesgo';//Si todo está bien
        let caducidad = new Date(fechaCad);
        let fechaCaducidad = new Date(fechaCad);//fecha
        let fechaActual = new Date();
        let fechaAlert = new Date(fechaCaducidad.setUTCDate(fechaCaducidad.getUTCDate() - diasPrevios));

        if(fechaActual >= fechaAlert && fechaActual < caducidad){//Si esta dentro de la alerta
          texto = 'Proximamente';
        }
        if(fechaActual >= caducidad){//Si ya esta caducado
          texto = 'Caducado';
        }
        if(estado == 'Sin stock'){
          texto = 'Sin stock';
        }

        return texto;
    }

}
