import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fechaFormatDate'
})

export class FechaFormatDate implements PipeTransform{

    transform(value: any){

      let fechaString = '';

      let fecha = new Date(value);

      let dia = fecha.getDate();
      let mes = fecha.getMonth()+1;
      let year = fecha.getFullYear();
      //let fechaString = year+'/'+mes+'/'+dia;
      fechaString = dia+'/'+mes+'/'+year;

      return fechaString;

    }

}
