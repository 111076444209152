import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../../auth/services/login.service';
import { SocketColaService } from '../../../services/socket-cola.service';
import { Global } from '../../../services/global';
import { UsuarioService } from 'src/app/auth/services/usuario.service';
import { SocketUserImageService } from 'src/app/services/socket-userImage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [LoginService]
})
export class HeaderComponent implements OnInit {

  //direccion para obtener las imagenes de usuario
  public url = Global.url+'imagenUsuario/getImage/';

  user = '';
  names = '';
  userType = '';
  userImage = '../../../../assets//imgs/user-general.png';//imagen por defecto

  ruta = '';
  link = '/admin/home';
  userId = '';

  public notificaciones:any = [];

  //Notificaciones

  constructor(
    private loginService: LoginService,
    private router: Router,
    private socketColaService: SocketColaService,
    private usuarioService: UsuarioService,
    private socketUserImage: SocketUserImageService) {

    this.nuevoPacienteEnCola();
    this.socketUserImage.getImageUpdated().subscribe(
      data => {
        this.loadUser(this.userId);
      }
    );
  }

  ngOnInit(): void {

    //Muestra el nombre de usuario en el dropdown
     let userLogged = this.loginService.userLogged();
     userLogged ? this.user = userLogged.usuario : this.user = '';
     userLogged ? this.names = userLogged.nombres+" "+userLogged.apellidos : this.names = '';
     let userId = userLogged._id;
     this.userId = userId;
     this.loadUser(userId);

    //Muestra que tipo de usuario en el dropdown
    let userRole = this.loginService.getRole();

    switch (userRole) {
      case 'admin':
          this.userType = 'Administrador'
          this.ruta = '/admin/perfil';
          this.link = '/admin/home'
        break;
      case 'enfermeria':
          this.userType = 'Recepcionista'
          this.ruta = '/recepcion/perfil';
          this.link = '/recepcion/home';
        break;

      default:
        break;
    }
  }

  //*Cargando la imagen de usuario logueado
  loadUser(userId){
    this.usuarioService.getUsuario(userId).subscribe(
      response => {
        let user = response.usuario;
        this.userImage = this.url+user.imagen || '';
      },
      error => {
        this.userImage = '../../../../assets/imgs/user-general.png';
      }
    );
  }

  CargarImagenPorDefecto(){
    this.userImage = '../../../../assets/imgs/user-general.png';//imagen por defecto
  }

  logout(){
    this.loginService.logout();
  }

  nuevoPacienteEnCola(){
      this.socketColaService.getCountCola().subscribe(
        response => {
          this.notificaciones.push(
            {
              icono: 'fas fa-users',
              titulo: 'Pacientes en espera',
              texto: 'Nuevo paciente en cola'
            }
          );
        }
      );
  }

}
