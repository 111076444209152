import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'estadoStock'
})

export class EstadoStock implements PipeTransform{

    transform(estado: string){
        let clase = 'badge badge-success';

        if(estado == 'Pocas unidades'){
          clase = 'badge badge-warning';
        }
        if(estado == 'Sin stock'){
          clase = 'badge badge-secondary';
        }

        return clase;
    }

}
