import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'estadoLicencia'
})

export class EstadoLicencia implements PipeTransform{

    transform(estado: string){
        let clase = 'badge badge-success';

        if(estado == 'EN PROCESO'){
          clase = 'badge badge-info';
        }
        if(estado == 'APROBADA'){
          clase = 'badge badge-success';
        }
        if(estado == 'RECHAZADA'){
          clase = 'badge badge-secondary';
        }

        return clase;
    }

}
