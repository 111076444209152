import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CanActivateChildGuardGuard implements CanActivateChild {

  constructor(private loginService: LoginService, private router: Router){};

  canActivateChild(){

    if(!this.loginService.userIsLogged()){
      //console.log('No estas logueado');
      this.router.navigate(['/']);
      return false;
    }else{//*Si hay un token en localStorage pero ya expiró
      if(this.loginService.isTokenExpired()){
        this.loginService.logout();
        this.router.navigate(['/']);
        return false;
      }
    }

    return true;
  }

}
