import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
 providedIn: 'root'
})
export class UserImageService{
    private subUserData = new Subject<any>();//*Aqui se almacenaran los datos del usuario

    constructor(){}

    //*Métodos para enviar datos a otros componentes

    sendUserId(idUser){//*Este método enviará el id del usuario del que se desea ver la imagen
        this.subUserData.next(idUser); //*Se almacena el evento y la data en el subject
    }

    loadUserId(): Observable<any>{//*Este método devuelve un observable que lee el evento y la data
        return this.subUserData.asObservable();
    }

}
