import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from './global'
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CuentaConsultaService {

  public url: string;

  constructor(
    private _http: HttpClient
  ) {
    this.url = Global.url;
  }

  createCuenta(cuentaC):Observable<any>{
    let params = JSON.stringify(cuentaC);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post(this.url+'consulta/save-cuenta', params, {headers: headers});
  }

  getCuentasDeudoras():Observable<any>{
      return this._http.get(this.url+'consulta/listar-cuentas-deudoras');
  }

  updateCuenta(id, data):Observable<any>{
      let params = JSON.stringify(data);
      let headers = new HttpHeaders().set('Content-Type', 'application/json');

      return this._http.put(this.url+'consulta/update-cuenta/'+id, params, {headers: headers});
  }

  obtenerCuenta(idPaciente, idConsulta):Observable<any>{
      return this._http.get(this.url+'consulta/obtener-cuenta/'+idPaciente+'/'+idConsulta);
  }

  getGananciasTotales():Observable<any>{//*Retorna los ingresos del mes actual
    let fechaActual = new Date();//*Enviando la fecha actual para calcular el periodo de los datos a mostrar
    return this._http.get(this.url+'consulta/getGanancias/'+fechaActual);
  }

  getGananciasPorMes():Observable<any>{//Retorna los ingresos por mes del presente año
    let fechaActual = new Date();//*Enviando la fecha actual para calcular el periodo de los datos a mostrar
    return this._http.get(this.url+'consulta/getGananciasPorMes/'+fechaActual);
  }

  getGananciasPorMesPeriodoAnterior():Observable<any>{//Retorna los ingresos por mes del año anterior
    let fechaActual = new Date();//*Enviando la fecha actual para calcular el periodo de los datos a mostrar
    return this._http.get(this.url+'consulta/getGananciasPorMes-periodoAnterior/'+fechaActual);
  }

}
