import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../../auth/services/login.service';
import { CuentaConsultaService } from '../../../services/cuentaConsulta.service';
import { SocketCuentaService } from '../../../services/socket-cuenta.service';
import { UsuarioService } from '../../../auth/services/usuario.service';
import { ToPDFService } from 'src/app/moduloConsulta/consulta/services/toPDF.service';
import { FechaFormat } from '../../pipes/fechaFormat.pipe';
import { LocalDate } from '../../pipes/localDate.pipe';
import { OrdenesService } from 'src/app/moduloLaboratorio/ordenes/services/ordenes.service';
import { ResultadosEstudiosService } from '../../../moduloLaboratorio/resultados/services/resultadosEstudios.service';
import { SocketOrdenConsultaService } from 'src/app/services/socket-ordenConsulta.service';

@Component({
  selector: 'app-cuenta-consulta',
  templateUrl: './cuenta-consulta.component.html',
  styleUrls: ['./cuenta-consulta.component.css'],
  providers: [FechaFormat, LocalDate],
})
export class CuentaConsultaComponent implements OnInit {

  totalesValidos = false;//*Bandera para verificar si el pago es menor o igual a la cuenta total

  public idCuenta = '';
  public idConsulta = '';
  public idPaciente = '';

  //Variables auxiliares a usar
  public numeroCuenta = '';
  public fechaCuenta = '';
  public pacienteNom = '';
  public expediente = '';
  public estado = 'Pendiente de pago';
  public cuentaTotal: number = 0;
  public pagoTotal: number = 0;
  public restoPendiente: String = '';

  public detalle = '';
  public precio = '0.00';
  public abono = '0.00';
  public fechaActual = null;
  public nameUserLogged = '';
  public idUserLogged = '';

  //Arreglos auxiliares
  public deudores = [];
  public cuentaDeudor = [];
  public abonos = [];

  //Banderas
  public agregoAbono = false;

  //Para confirmar contraseña
  public password = '';


  //*Arreglo para guardar los detalles de cuenta que han sido removidos
  detailsRemoved = [];

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private loginService: LoginService,
    private cuentaConsultaService: CuentaConsultaService,
    private socketCuentaService: SocketCuentaService,
    private toastr: ToastrService,
    private usuarioService: UsuarioService,
    private toPDFService: ToPDFService,
    private fechaFormatPipe: FechaFormat,
    private toLocaleDate: LocalDate,
    private ordenService: OrdenesService,
    private ordenResultadosService: ResultadosEstudiosService,
    private socketOrdenConsultaService: SocketOrdenConsultaService
  ){

    this.socketCuentaService.getCuenta().subscribe(
      response => {
        //*No se usa la data enviada a través del socket solo se usa el evento
        this.loadCuentas();
      },
      err => {
         //console.log('Error al agregar cuenta de consulta', err);
      }
    );

  }

  ngOnInit(): void {
    let userLogged = this.loginService.userLogged();
    this.nameUserLogged = userLogged.nombres+' '+userLogged.apellidos;//Cargando nombre completo del usuario en sesión
    this.idUserLogged = userLogged._id;
    this.fechaActual = new Date();
    this.loadCuentas();
  }

  loadCuentas(){
    this.cuentaConsultaService.getCuentasDeudoras().subscribe(
      response => {

        if(response.status == 'success'){
          this.deudores = response.cuentasDeudoras;
          //console.log('Cuentas deudoras: ', this.deudores);
        }
      },
      err => {
        //console.log(err);
      }
    );
  }

  mostrarCuenta(idCuenta){

    this.pagoTotal = 0;

    this.deudores.forEach(cuenta => {
        if(cuenta._id == idCuenta){
          this.idCuenta = cuenta._id;
          this.numeroCuenta = cuenta.numero;
          this.idPaciente = cuenta.pacienteId;
          this.idConsulta = cuenta.consultaId;
          this.fechaCuenta = cuenta.fecha;
          this.pacienteNom = cuenta.nombrePaciente;
          this.expediente = cuenta.expediente;
          this.cuentaDeudor = Array.from(cuenta.cuenta);//Crea una nueva instancia del array, una copia
          if(cuenta.abono.length > 0){//Si hay algun abono
            if(cuenta.abono[0].monto === 0){//Si solo trae el abono a cero por defecto
              this.abonos = [];
            }else{
              this.abonos = Array.from(cuenta.abono);
            }
          }else{
            this.abonos = [];
          }
          this.cuentaDeudor.forEach(x => {
                this.cuentaTotal += x.precio;
          });
          //console.log('Cuenta total: ', this.cuentaDeudor);
          this.abonos.forEach(y => {
                this.pagoTotal += y.monto;
          });
        }
    });
    this.calcularTotalCuenta();
    this.estado = this.verificarEstado();


  }

  agregarDetalle(){

    if(Number(this.precio) > 0 && this.detalle.length > 0){

      let esta = false;//Bandera para verificar si ya esta el detalle o concepto
      let detail = this.detalle.toUpperCase();//Convirtiendo a mayusculas
      //Recorriendo la lista de cuenta
      this.cuentaDeudor.forEach(element => {
          if(element.concepto.toUpperCase() == detail){
            esta = true;
          }
      });
      //Validando que no se agregue un detalle que ya está en la lista
      if(esta){
            this.infoMessage('El detalle ya está en la lista','Cuenta por consulta');
      }else{//si no se repite el mismo detalle para consulta o estudios se procede

        let sum = 0;
        let c = {
          concepto: this.detalle.trim(),
          precio: Number(this.precio),
          id_detalle: ''//Por defecto
        }

        this.cuentaDeudor.push(c);
        this.cuentaDeudor.forEach(x => {
          sum = sum+x.precio;
        });
        this.cuentaTotal = sum;
        this.detalle = '';
        this.precio = '0.00';

        this.calcularResto();
        this.estado = this.verificarEstado();

      }//Fin del else de detail

    }
  }

  calcularTotalCuenta(){
    let suma = 0;
    let suma2 = 0;

    if(this.cuentaDeudor.length > 0){
      this.cuentaDeudor.forEach(x => {
        if((x.precio.length > 0 && x.precio != '$') || (x.precio >= 0)){
          suma = suma + parseFloat(x.precio);
        }else{
          return;
        }
      });
    }

    if(this.abonos.length > 0){
      this.abonos.forEach(y => {
        if((y.monto.length > 0 && y.monto != '$') || (y.monto >= 0)){
          suma2 = suma2 + parseFloat(y.monto);
        }else{
          return;
        }
      });
    }

    this.cuentaTotal = suma;
    this.pagoTotal = suma2;
    this.estado = this.verificarEstado();
    if(this.verificarTotales()){
      //*Calculando el resto de toda la cuenta - los abonos
      this.calcularResto();

    }else{
      this.restoPendiente = "0.00";
    }
  }

  verificarTotales(){
    let valido = false;

    if(Number(this.pagoTotal) <= Number(this.cuentaTotal)){//Si el pago es menor o igual a la cuenta total
      valido = true;
      this.totalesValidos = true;
    }else{
      valido = false;
      this.totalesValidos = false;
    }

    return valido
  }

  descartarDetalle(index){
    let detailRemoved = this.cuentaDeudor.splice(index, 1);
    this.calcularTotalCuenta();
    this.estado = this.verificarEstado();

    //*Guardando los detalles de cuenta que se remueven de la lista
    this.detailsRemoved.push(...detailRemoved);

  }

  agregarAbono(){

    if(Number(this.abono) > 0){
      if(this.verificaAbono()){

        let sum = 0;
        let a = {
          fecha: new Date(),
          monto: Number(this.abono),
          registro: this.nameUserLogged
        }

        this.abonos.push(a);
        this.abonos.forEach(y => {
            sum = sum+y.monto;
        });
        this.pagoTotal = sum;
        this.abono = '0.00';

        this.calcularTotalCuenta();
        this.calcularResto();
        this.estado = this.verificarEstado();
        this.agregoAbono = true;
      }//Fin verifica abono
      else{
          this.infoMessage('La cantidad del abono no puede ser mayor al cargo total o al resto pendiente','Cuenta consulta');
      }
    }//Fin si abono > 0
    else{
      this.agregoAbono = false;
    }
  }

  calcularResto(){
    this.restoPendiente = (this.cuentaTotal - this.pagoTotal).toFixed(2);
  }

  verificaAbono(){
    let check = false;
    let c = this.cuentaTotal;
    if(this.abonos.length > 0){//*Si ya hay abonos
      c = Number(this.restoPendiente);//*Cálcula en base al resto pendiente de pagar
    }
    let a = Number(this.abono);
    if(a <= c){//*si el abono es menor o igual que el cargo total puede ser agregado
        check = true;
    }
    //*Si el abono es mayor que el cargo no se agrega ya que estaria pagando de más el paciente

    return check;
  }

  verificarEstado(){
    return this.cuentaTotal == this.pagoTotal ? 'Cuenta pagada' : 'Pendiente de pago' ;
  }

  verificarEstadoBool(){
    return this.cuentaTotal == this.pagoTotal ? true : false ;
  }

  cancelar(){
      this.idCuenta = '';
      this.fechaCuenta = '';
      this.pacienteNom = '';
      this.cuentaDeudor = [];
      this.abonos = [];
      this.cuentaTotal = 0;
      this.pagoTotal = 0;
      this.idConsulta = '';
      this.idPaciente = '';
      this.abono = '0.00'
      this.detalle = '';
      this.precio = '0.00';
      this.restoPendiente = '';
      this.agregoAbono = false;
      this.password = '';

      this.detailsRemoved = [];
  }

  validarPago(){

    this.ngxSmartModalService.getModal('cuentaPacienteModal').close();
    this.ngxSmartModalService.getModal('confirmaPagoModal').open();
  }

  validarPassword(){

    if(this.password.length > 0){

      //Preparando el objeto
      let data = {
        userId: this.idUserLogged,
        pass: this.password
      }
      //Petición para comparar las passwords
      this.usuarioService.validaPass(data).subscribe(
        response => {
            //console.log("Validación de password: ", response);
            let match = response.match;
            if(match == true){
              this.ngxSmartModalService.getModal('confirmaPagoModal').close();
              this.guardarPago();
            }
        },
        err => {
            console.log('Error al tratar de confirmar password', err);
            this.errorMessage("Error al confirmar contraseña","Contraseña inválida");
        }
      );
    }else{
      this.errorMessage("El campo está vacío","Contraseña inválida");
    }


  }

  guardarPago(){

      let estado = this.verificarEstadoBool();

      let cuenta = {
          pacienteId: this.idPaciente,
          consultaId: this.idConsulta,
          fecha: this.fechaCuenta,
          nombrePaciente: this.pacienteNom,
          cuenta: this.cuentaDeudor,
          abono: this.abonos,
          pagado: estado
      }

      this.cuentaConsultaService.updateCuenta(this.idCuenta, cuenta).subscribe(
        response => {

          //console.log('Cuenta de consulta modificada:', response);
          if(response.status == 'success'){
              this.successMessage('Se agregó un nuevo pago $$$', 'Cuenta consulta');
              this.socketCuentaService.sendCuenta(cuenta);
              //*Verificando si entre los detalles eliminados hay una orden de estudios
              this.verificarOrdenRemoved();//*Verificando si se ha eliminado una orden de estudios de la cuenta
              this.cancelar();//Limpiando todas las variables
          }
        },
        err => {
          //console.log('Error al actualizar la cuenta de consulta médica');
          this.errorMessage('No se pudo agregar el pago', 'Cuenta consulta');
          this.ngxSmartModalService.getModal('cuentaPacienteModal').close();
        }
      );

  }


  //*Método para verificar los detalles eliminados de la lista de cuentas y verificar si hay una orden de estudios
  verificarOrdenRemoved(){
    this.detailsRemoved.forEach(e => {
      //Si la etiqueta es igual a 'Estudios clínicos' y tiene un id de referencia
        if(e.concepto == 'Estudios clínicos'){
          this.cambiarEstadoOrden(e.id_detalle._id);
          return;//Sale del bucle despues de ejecutar cambiarEstadoOrden()
        }
    });
  }

  //*Método para cambiar el estado de la orden de estudios si se eliminó de la lista de cuenta por consulta
  cambiarEstadoOrden(id){
      console.log('id removed: ', id);
      //*Cambiando el estado de la orden a 'SOLO REGISTRO'
      this.ordenService.cambiarEstadoDeOrden(id).subscribe(
        response => {
          let idOrden = response.ordenUpdated._id;//*Obteniendo el id de la orden modificada
          let ordenUpdated = response.ordenUpdated;
          //*Eliminando la orden en el registro de producción
          this.ordenResultadosService.deleteRecordByConsulta(idOrden).subscribe(
            resp => {
              console.log('Record removed: ', resp);
              //*Enviando el evento y el objeto para que se actualicen las listas de ordenes por consulta y produccion de ordenes
              this.socketOrdenConsultaService.sendUpdateLista(ordenUpdated);
            },
            err => {
              //console.log('Error al eliminar el record de orden por consulta: ', err);
              this.errorMessage('Error al eliminar la orden de producción','Cuenta por consulta');
            }
          );
        },
        error => {
          //console.log('Error al actualizar estado de orden: ', error);
          this.errorMessage('Error al actualizar la orden de estudios','Cuenta por consulta');
        }
      );
  }

  cuentaToPDF(){

    let data = {
      numero: this.numeroCuenta,
      fecha: this.fechaFormatPipe.transform(this.fechaCuenta),
      paciente: this.pacienteNom,
      expediente: this.expediente,
      cuentaTotal: this.cuentaTotal,
      estado: this.verificarEstado(),
      cuenta: this.cuentaDeudor,
      abonos: this.abonos,
      pagoTotal: this.pagoTotal,
      resto: this.restoPendiente,
    }

    this.toPDFService.printCuentaConsulta(data);

  }

  currentDate() {
    var fechaCon = new Date();
    //console.log(fechaCon);
    var dd = fechaCon.getDate();
    var mm = fechaCon.getMonth() + 1; //January is 0!
    var yyyy = fechaCon.getFullYear();

    var dia = dd.toString();
    if (dd < 10) {
      dia = "0" + dd;
    }
    var mes = mm.toString();
    if (mm < 10) {
      mes = "0" + mm;
    }
    //console.log(yyyy + "-" + mes + "-" + dia);
    return dia + "-" + mes + "-" + yyyy;
  }

  //Notificación cuando se agrega un pago
  successMessage(mensaje: string, titulo: string){
    this.toastr.success(mensaje, titulo);
  }

  errorMessage(mensaje: string, titulo: string){
    this.toastr.error(mensaje, titulo);
  }

  infoMessage(mensaje: string, titulo: string){
    this.toastr.info(mensaje, titulo);
  }


  /*
    LINKS
      https://stackoverflow.com/questions/68291450/how-to-check-password-on-sweetalert-in-angular-7
    https://www.itsolutionstuff.com/post/angular-9-8-sweetalert2-sample-example-tutorialexample.html
  */

}
