import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';//Importando Location

@Component({
  selector: 'app-page-header-exit-button',
  templateUrl: './page-header-exit-button.component.html',
  styleUrls: ['./page-header-exit-button.component.css']
})
export class PageHeaderExitButtonComponent implements OnInit {

  @Input() titulo: string;
  @Input() icono: string;
  @Input() breadcrumbs: any[];

  constructor(private _location: Location) { }

  ngOnInit(): void {
  }

  volver(){
    //this._location.back(); //comando para volver a la última URL visitada
    window.close();//cierra la ventana actual
  }

}
