import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';


@Injectable({
  providedIn: 'root'
})
export class SocketUserImageService {

  constructor(private socket: Socket) { }

  //*métodos para enviar y recibir el evento de actualizacion de la imagen de usuario
  //*Aunque en realidad solo interesa el evento para poder actualizar la imagen en los diferentes componentes
  sendUpdateImage(idUser: any) {
    this.socket.emit('updateUserImage', JSON.stringify(idUser));
  }
  getImageUpdated() {
    return this.socket.fromEvent<any>('updateUserImage');
  }


}
