import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Global } from '../../../services/global'
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrdenesService {

  public url: string;

  constructor(
    private _http: HttpClient
  ) {
    this.url = Global.url;
  }

  //*Método para obtener la lista de ordenes por consulta médica paginadas
  getAllOrdenesPorConsulta(page):Observable<any>{
    let params = new HttpParams().set('page', page);
    return this._http.get(this.url+'estudios/getAllOrdenes', {params});
  }

  //*Método para obtener los datos de una orden en especifico mediante su ID
  obtenerOrdenEstudiosById(idOrden):Observable<any>{
    return this._http.get(this.url+'estudios/findOrdenById/'+idOrden);
  }

  //*Método para realizar la petición de búsqueda con search engine
  searchOrden(key, page):Observable<any>{
    let params = new HttpParams().set('page', page);
    return this._http.get(this.url+'estudios/search/'+key, {params});
  }

  //*Método para cambiar el estado de la orden a "SOLO REGISTRO" para que no sea tomada en cuenta en producción
  cambiarEstadoDeOrden(idOrden, estado = "SOLO REGISTRO"):Observable<any>{
    let params = JSON.stringify({ estado: estado });//Solo cambiar el campo de estado
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.put(this.url+'estudios/cambiarEstado/'+idOrden, params, {headers: headers});
  }

  //*Método para eliminar una orden de estudios por consulta médica
  deleteOrden(id):Observable<any>{
    return this._http.delete(this.url+'estudios/deleteOrden/'+id);
  }

  //*Método para enviar los datos de la imagen del estudio
  saveResultado(data):Observable<any>{
    let params = JSON.stringify(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post(this.url+'imagenEstudio/save', params, {headers: headers});
  }

  //*Método para listar las imagenes paginadas
  getImages(idOrden, page):Observable<any>{
    let params = new HttpParams().set('page', page);
    return this._http.get(this.url+'imagenEstudio/getImages/'+idOrden, {params});
  }

  //*Método para listar todas las imagenes del estudio en general
  getAllImages(idOrden):Observable<any>{
    return this._http.get(this.url+'imagenEstudio/getAllImages/'+idOrden);
  }

  //*Método para obtener el path de una imagen
  getPathImage(image):Observable<any>{
    return this._http.get(this.url+'imagenEstudio/getImage/'+image);
  }

  //*Método para eliminar una imagen de estudio
  deleteImage(id):Observable<any>{
    return this._http.delete(this.url+'imagenEstudio/delete/'+id);
  }

}
