import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Global } from '../../../services/global'
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResultadosEstudiosService {

  public url: string;

  constructor(
    private _http: HttpClient
  ) {
    this.url = Global.url;
  }

  //*Método para crear un registro de orden con correlativo para resultados
  crearOrdenResultado(orden):Observable<any>{

    let params = JSON.stringify(orden);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post(this.url+'orden_resultados/save', params, {headers: headers});
  }

  //*Método para obtener la lista de las ordenes por su fecha y numero diario paginadas
  getOrdenesResultados(page):Observable<any>{
    let params = new HttpParams().set('page', page);
    return this._http.get(this.url+'orden_resultados/listar', {params});
  }

  //*Método para filtar registros de consultas entre dos fechas
  filtrarProduccionEntreFechas(page, fechaIn, fechaFin):Observable<any>{
    let params = new HttpParams()
    .set('page', page)
    .set('fechaIn', fechaIn)
    .set('fechaFin', fechaFin);
    return this._http.get(this.url+'orden_resultados/produccionEntreFechas', {params});
  }

  //*Método para obtener la orden de resultados por id de orden
  obtenerOrdenDeResultados(idOrden, servicio):Observable<any>{
    let params = new HttpParams().set('servicio', servicio);
    return this._http.get(this.url+'orden_resultados/obtener/'+idOrden, {params});
  }

  //*Método para actualizar el numero del registro de producción
  actualizarNumeroDeProduccion(id, numero: number){
    let params = JSON.stringify({numeroDiario: numero});//formato de la data en el modelo del back = {numeroDiario: valor}
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.put(this.url+'orden_resultados/actualizarNumero/'+id, params, {headers: headers});
  }

  //*Método para eliminar una orden de consulta de la lista de produccion
  deleteRecordByConsulta(idOrdenEstudios):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.delete(this.url+'orden_resultados/delete/'+idOrdenEstudios, {headers: headers});
  }


}
