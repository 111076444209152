import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Global } from '../../../services/global'
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class InsumosService {

  public url: string;

  constructor(
    private _http: HttpClient
  ) {
    this.url = Global.url;
  }

  //*Método para crear un registro de un articulo
  crearArticulo(articulo):Observable<any>{

    let params = JSON.stringify(articulo);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post(this.url+'insumos/saveArticulo', params, {headers: headers});
  }

  //*Método para obtener la lista de insumos médicos paginados
  getInsumos(page, tipo = '', ubicacion = '', proveedor = ''):Observable<any>{
    let params = new HttpParams().set('page', page)
    .set('tipo', tipo)
    .set('ubicacion', ubicacion)
    .set('proveedor', proveedor);
    return this._http.get(this.url+'insumos/listarInsumos', { params });
  }

  //*Método para obtener la lista de insumos médicos sin paginar
  listarTodos(): Observable<any>{
    return this._http.get(this.url+'insumos/listarTodos');
  }

  //*Método para buscar articulos con search engine
  searchArticulo(key, page, tipo = '', ubicacion = '', proveedor = ''):Observable<any>{
    let params = new HttpParams().set('page', page)
    .set('tipo', tipo)
    .set('ubicacion', ubicacion)
    .set('proveedor', proveedor);
    return this._http.get(this.url+'insumos/searchInsumos/'+key, { params });
  }

  //*Método para obtener un articulo en especifico
  getArticulo(id):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.url+'insumos/obtenerArticulo/'+id, {headers: headers});
  }

  //*Método para hacer una petición de edición de registro
  updateArticulo(id, objeto):Observable<any>{
    let params = JSON.stringify(objeto);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.put(this.url+'insumos/updateArticulo/'+id, params, {headers: headers});
  }

  //*Método para hacer una petición de edición de registro cuando hay un movimiento de inventario
  updateArticuloMovimiento(id, objeto):Observable<any>{
    let params = JSON.stringify(objeto);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.put(this.url+'insumos/updateMovimiento/'+id, params, {headers: headers});
  }

  //*Método para hacer una petición de eliminación de registro
  deleteArticulo(id):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.delete(this.url+'insumos/deleteArticulo/'+id, {headers: headers});
  }

  //*Método para obtener la lista de insumos médicos paginados con pocas unidades en stock
  getPocasUnidades(page):Observable<any>{
    let params = new HttpParams().set('page', page);
    return this._http.get(this.url+'insumos/pocasUnidades', { params });
  }

  //*Método para obtener la lista de insumos médicos paginados sin existencias o stock 0
  getSinExistencias(page):Observable<any>{
    let params = new HttpParams().set('page', page);
    return this._http.get(this.url+'insumos/sinExistencias', { params });
  }

  //*Método para obtener la lista de insumos médicos paginados con caducidad proxima
  getCaducidadProxima(page, currentDate):Observable<any>{
    let params = new HttpParams().set('page', page).set('fechaActual', currentDate);
    return this._http.get(this.url+'insumos/caducidadProxima', { params });
  }

  //*Método para obtener la lista de insumos médicos paginados ya caducados a la fecha
  getCaducados(page, currentDate):Observable<any>{
    let params = new HttpParams().set('page', page).set('fechaActual', currentDate);
    return this._http.get(this.url+'insumos/caducados', { params });
  }

  //*Método para obtener el registro del nombre de la imagen a través del id del articulo al que pertenece
  getImagenArticuloById(idArt):Observable<any>{//id del articulo que hace relacion con la imagen
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.url+'imagenInsumo/getImageRecord/'+idArt, {headers: headers});
  }

  //*Método para obtener la imagen del articulo enviando su nombre y obteniendo el enlace
  getPathImage(image):Observable<any>{
    return this._http.get(this.url+'imagenInsumo/getImage/'+image);
  }

  //*Método para eliminar la imagen del articulo
  deleteImagen(idImagen):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.delete(this.url+'imagenInsumo/delete/'+idImagen, {headers: headers});
  }

  //*Método para crear un registro de kardex
  crearMovimiento(movimiento):Observable<any>{

    let params = JSON.stringify(movimiento);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post(this.url+'insumos/saveMovimiento', params, {headers: headers});
  }

  //*Método para obtener la lista de movimientos en kardex
  getMovimientos(idArticulo, page):Observable<any>{
    let params = new HttpParams().set('page', page).set('id', idArticulo);
    return this._http.get(this.url+'insumos/listarKardex', {params});
  }

  //*Método para filtar registros de movimientos entre dos fechas
  filtrarMovimientosEntreFechas(articuloId, page, fechaIn, fechaFin):Observable<any>{
    let params = new HttpParams()
    .set('page', page)
    .set('fechaIn', fechaIn)
    .set('fechaFin', fechaFin);
    return this._http.get(this.url+'insumos/kardexEntreFechas/'+articuloId, {params});
  }

  //*Método para obtener la lista de salidas diarias de inventario de insumos
  getSalidasDeInsumos(page, currentDate):Observable<any>{
    let params = new HttpParams().set('page', page).set('fechaActual', currentDate);
    return this._http.get(this.url+'insumos/salidasDelDia', {params});
  }

  //*Método para crear un registro de tipo de insumos
  crearTipo(tipo):Observable<any>{

    let params = JSON.stringify(tipo);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post(this.url+'insumos/saveTipo', params, {headers: headers});
  }

  //*Método para obtener la lista de tipos paginados
  getTipos(page):Observable<any>{
    let params = new HttpParams().set('page', page);
    return this._http.get(this.url+'insumos/listarTipos', {params});
  }

  //*Método para listar todos los tipos de insumos sin paginar
  getAllTipos():Observable<any>{
    return this._http.get(this.url+'insumos/todosLosTipos');
  }

  //*Método para buscar tipos con search engine
  searchTipo(key, page):Observable<any>{
    let params = new HttpParams().set('page', page);
    return this._http.get(this.url+'insumos/searchTipo/'+key, { params });
  }

  //*Método para hacer una petición de edición de registro
  updateTipo(id, tipo):Observable<any>{
    let params = JSON.stringify(tipo);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.put(this.url+'insumos/updateTipo/'+id, params, {headers: headers});
  }

   //*Método para hacer una petición de eliminación de registro
  deleteTipo(id):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.delete(this.url+'insumos/deleteTipo/'+id, {headers: headers});
  }

  //*Método para crear un registro de tipo de ubicacion
  crearUbicacion(ubicacion):Observable<any>{

    let params = JSON.stringify(ubicacion);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post(this.url+'ubicacion/save', params, {headers: headers});
  }
  //*Método para obtener la lista de ubicaciones paginadas
  getUbicaciones(page):Observable<any>{
    let params = new HttpParams().set('page', page);
    return this._http.get(this.url+'ubicacion/listar', {params});
  }

  //*Método para listar todos las ubicaciones de insumos sin paginar
  getAllUbicaciones():Observable<any>{
    return this._http.get(this.url+'ubicacion/todas');
  }

  //*Método para buscar ubicaciones con search engine
  searchUbicacion(key, page):Observable<any>{
    let params = new HttpParams().set('page', page);
    return this._http.get(this.url+'ubicacion/search/'+key, { params });
  }

  //*Método para hacer una petición de edición de registro
  updateUbicacion(id, ubicacion):Observable<any>{
    let params = JSON.stringify(ubicacion);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.put(this.url+'ubicacion/update/'+id, params, {headers: headers});
  }

  //*Método para hacer una petición de eliminación de registro
  deleteUbicacion(id):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.delete(this.url+'ubicacion/delete/'+id, {headers: headers});
  }

  //*MÉTODOS DE REPORTES EN EXCEL, se deben cambiar los headers para recirbirlo como respuesta
  generarInsumosExcel():Observable<any>{
    let headers = new HttpHeaders().set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    return this._http.get(this.url+'insumosReportes/generarInsumosExcel', {headers: headers, responseType: 'blob'});
  }
  generarKardexExcel(idArticulo):Observable<any>{
    let params = new HttpParams().set('id', idArticulo);
    let headers = new HttpHeaders().set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    return this._http.get(this.url+'insumosReportes/generarKardexExcel', {headers: headers, responseType: 'blob', params: params},);
  }


}
