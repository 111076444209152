import { Component, OnInit, ViewChild } from '@angular/core';
import { Global } from '../../../services/global';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { UserImageService } from 'src/app/services/user-image.service';
import { UsuarioService } from 'src/app/auth/services/usuario.service';
import { Rol } from '../../pipes/rol.pipe';
import { SocketUserImageService } from 'src/app/services/socket-userImage.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-image',
  templateUrl: './user-image.component.html',
  styleUrls: ['./user-image.component.css'],
  providers: [ Rol ]
})
export class UserImageComponent implements OnInit {

  @ViewChild("confirmarDeleteImagenUser2") private deleteUserImage: SwalComponent;

  //*direccion para obtener las imagenes de usuario
  public url = Global.url+'imagenUsuario/getImage/';

  //*data a enviar a través de la subida del archivo | este objeto no debe ser modificado
  data = {
    idUsuario: ''
  }

  //*Configuracion para el angular file uploader
  afuConfig = {
    multiple: false,
    formatsAllowed: ".jpg,.png,.jpeg",
    maxSize: "500",
    uploadAPI:  {
      url: Global.url+'imagenUsuario/upload-image',
      method:"POST",
      params: this.data,
    },
    theme: "",
    hideProgressBar: false,
    hideResetBtn: true,
    hideSelectBtn: false,
    fileNameIndex: false,
    autoUpload: false,
    replaceTexts: {
      selectFileBtn: 'Selecciona la imagen',
      resetBtn: 'Cancelar',
      uploadBtn: 'Subir imagen',
      afterUploadMsg_success: 'Tu imagen fue subida!',
      afterUploadMsg_error: 'Error al subir la imagen!',
      sizeLimit: 'Tamaño limite'
    }
  };

  public pathImagen = '';//*Variable para mostrar el path de la imagen del usuario
  public imagenExists = false;//*Bandera para verificar si hay una imagen para cargar
  public idUserRecord = '';//*Variable auxiliar para guardar el id del usuario

  //*Variables para mostrar en el modal de imagen de usuario
  public userName = '';
  public fullName = '';
  public userRole = '';
  public state = '';

  constructor(
    public smartModal: NgxSmartModalService,
    private userImageService: UserImageService,
    private userService: UsuarioService,
    public rolPipe: Rol,
    private socketUserImageService: SocketUserImageService,
    private toastr: ToastrService,
  ) {

    //*Subscribiendose al observable que recibirá el id de usuario
    this.userImageService.loadUserId().subscribe(
      userId => {
        this.loadUser(userId);
      }
    );
  }

  ngOnInit(): void {
  }

  //*Cargando la data del usuario
  loadUser(userId){
    this.userService.getUsuario(userId).subscribe(
      response => {
        if(response.status == 'success'){
          let user = response.usuario;
          this.verImagen(user);
        }
      },
      error => {
        this.imagenExists = false;
        this.pathImagen = '';
        this.idUserRecord = '';
      }
    );
  }

  //*Método para mostrar la data y la imagen del usuario
  verImagen(data){
    this.userName = data.usuario;
    this.fullName = data.nombres+' '+data.apellidos;
    this.userRole = this.rolPipe.transform(data.roles[0].nombre);//obteniendo el tipo de rol
    this.state = data.estado;
    this.imagenExists = false;
    this.pathImagen = '';
    this.data.idUsuario = data._id;
    this.idUserRecord = data._id;

    this.pathImagen = this.url+data.imagen || '';
    this.imagenExists = true;

    this.smartModal.getModal('userImageModal').open();

  }

  //*Para cargar la imagen por defecto si hay algun error
  cargarImagenPorDefecto(){
    this.imagenExists = false;
  }

  //*Método que se ejecuta luego de subir la imagen y recibe la respuesta del servidor
  onUploadImage(event){
    this.imagenExists = false;
    this.pathImagen = '';
    this.idUserRecord = '';
    if(event){
      let record = event.body.userUpdated;
      this.idUserRecord = record._id;//*id del registro de imagen
      this.pathImagen = this.url+record.imagen;
      this.imagenExists = true;
      this.socketUserImageService.sendUpdateImage(this.idUserRecord);
    }

  }

  //*Método para eliminar la imagen del usuario
  deleteImage(){
    this.smartModal.getModal('userImageModal').close();
    this.deleteUserImage.fire().then(
      result => {
        if(result.isConfirmed){
          this.imagenExists = false;
          this.pathImagen = '';

          this.userService.deleteImagen(this.idUserRecord).subscribe(
            response => {
              this.showSucces('La imagen fue eliminada','Perfil de usuario');
              this.socketUserImageService.sendUpdateImage(this.idUserRecord);
            },
            error => {
              this.showError('Error al eliminar la imagen de usuario', 'Perfil de usuario');
            }
          );
        }else{
          this.smartModal.getModal('userImageModal').open();
        }
    });

  }

  showSucces(mensaje: string, titulo: string){
    this.toastr.success(mensaje, titulo);
  }
  showInfo(mensaje: string, titulo: string) {
    this.toastr.info(mensaje, titulo);
  }
  showError(mensaje: string, titulo: string) {
    this.toastr.error(mensaje, titulo);
  }


}
