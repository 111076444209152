import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {RouterModule} from '@angular/router';
import { PageHeaderComponent } from './page-header/page-header.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CalculadorComponent } from './calculador/calculador.component';
import { PageHeaderExitButtonComponent } from './page-header-exit-button/page-header-exit-button.component';
import { ColaPacientesComponent } from './cola-pacientes/cola-pacientes.component';
import { SharedModule } from '../shared.module';
import { CuentaPacienteComponent } from '../../moduloConsulta/pacientes/pages/cuenta-paciente/cuenta-paciente.component';
import { CuentaConsultaComponent } from './cuenta-consulta/cuenta-consulta.component';
import { PipesModule } from '../pipes/pipes.module';
import { ConfirmaPasswordComponent } from './confirma-password/confirma-password.component';
import { CuentaOrdenParticularComponent } from './cuenta-orden-particular/cuenta-orden-particular.component';
import { ConsumoInsumosComponent } from './consumo-insumos/consumo-insumos.component';
import { UserImageComponent } from './user-image/user-image.component';


@NgModule({
  declarations: [
    PageHeaderComponent,
    SidemenuComponent,
    HeaderComponent,
    FooterComponent,
    CalculadorComponent,
    PageHeaderExitButtonComponent,
    ColaPacientesComponent,
    CuentaPacienteComponent,
    CuentaConsultaComponent,
    ConfirmaPasswordComponent,
    CuentaOrdenParticularComponent,
    ConsumoInsumosComponent,
    UserImageComponent
  ],
  exports: [
    PageHeaderComponent,
    SidemenuComponent,
    HeaderComponent,
    FooterComponent,
    CalculadorComponent,
    PageHeaderExitButtonComponent,
    ColaPacientesComponent,
    CuentaPacienteComponent,
    CuentaConsultaComponent,
    SharedModule,
    ConfirmaPasswordComponent,
    ConsumoInsumosComponent,
    UserImageComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    PipesModule
  ]
})
export class GeneralComponentsModule { }
