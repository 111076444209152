import { Component, OnInit, ViewChild } from '@angular/core';
import {NgForm} from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ConsultaService } from '../../../consulta/services/consulta.service';
import { CuentaService } from '../../services/cuenta.service';
import { map } from 'rxjs/operators';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
@Component({
  selector: 'app-cuenta-paciente',
  templateUrl: './cuenta-paciente.component.html',
  styleUrls: ['./cuenta-paciente.component.css']
})
export class CuentaPacienteComponent implements OnInit {

  //Se le pasan los titulos y los links de las paginas que preseden esta pagina
  public breads: any[] = [{ titulo: "Home", link: "/admin/home" }];

  @ViewChild("redireccionSwal") private redireccionSwal: SwalComponent;
  @ViewChild("redireccionAbonoSwal") private redireccionAbonoSwal: SwalComponent;

  cuenta: any;
  consultasP: any[]=[];
  cuentaPacientes: any[];
  indexPago = '';

  constructor(public ngxSmartModalService: NgxSmartModalService,
    private _consultaService: ConsultaService,
    private _cuentaService: CuentaService
    ) {

    this.cuenta = {
      paciente:"",
      consultas:[],
      monto:0,
      total:0
    }

  }

  ngOnInit(): void {
    this.listarDeudores();
  }

  listarDeudores(){
    this._cuentaService.listarCuentas().subscribe(
      response =>  {
        if (response.status = 200) {
          this.cuentaPacientes = response.deudores;
          //console.log(this.cuentaPacientes);
        }else if(response.status = 400){
          //console.log(response.message);
        }
      },
      error => {
        console.log("Error al listar las cuentas ", error);
      }
    );
  }

  //en este metodo se hara la actualizacion del campo abono de la consulta
  //se llama desde el boton cancelar del segundo modal
  actualizarTotal(abono,i){

    this.cuenta.consultas[i].abono = abono;
    let montoUpdated={
      id:this.cuenta.consultas[i]._id,
      abono:parseFloat(abono)
    }

    //console.log(this.cuenta.consultas[i]);
    console.log(montoUpdated);


    this._consultaService.updateMonto(montoUpdated).subscribe(
      response =>  {
        if (response.status = 'Success') {

          if (this.cuenta.consultas[i].precio == this.cuenta.consultas[i].abono) {
            //this.cuenta.consultas.splice(i,1);
            this.cuenta.consultas[i].activo = true;
            this.redireccionSwal.fire();
          }else{
            this.redireccionAbonoSwal.fire();
          }

            this.cuenta.monto=0;
           this.cuenta.monto= parseFloat( this.cuenta.monto + abono );
            this.cuenta.total=this.cuenta.total - abono;
          console.log('Se actualizo correctamente ',response);
        }
      },
      error => {
        console.log("Error al actualizar el monto de consulta ", error);
      }
    );

  }


  //lleva las consultas de la tabla de pacientes del primer modal a la tabla de consultas del segundo modal
  //se llama desde el boton pagar del primer modal
  llevarIndex(obj){
    this.cuenta.total=0;
      this.cuenta.monto=0;
      this.cuenta.consultas=[];
      this.consultasP = [];
    for (let consulta of obj.consultas) {
      this.cuenta.total+=consulta.precio;
      this.cuenta.monto+=consulta.abono;
      this.consultasP.push({
        _id:consulta._id,
        fechaConsul:consulta.fechaConsul,
        precio:consulta.precio,
        abono:consulta.abono,
        activo:(consulta.precio == consulta.abono) ? true:false
      });
    }

    this.cuenta.total-=this.cuenta.monto;
    this.cuenta.paciente=obj.nombre;
    this.cuenta.consultas=this.consultasP;
    console.log(this.cuenta.consultas);
  }

}
