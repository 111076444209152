import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class SocketColaService {

  constructor(private socket: Socket) { }

  //métodos para enviar y recibir un paciente en la cola de espera en tiempo real
  sendMessage(paciente: any) {
    this.socket.emit('message', JSON.stringify(paciente));
  }
  getMessage() {
    return this.socket.fromEvent<any>('message');
  }
  //métodos para actualizar la cola en tiempo real
  sendCola(cola: any){
    this.socket.emit('updateCola', JSON.stringify(cola));
  }
  getCola(){
    return this.socket.fromEvent<any>('updateCola');
  }

  //métodos para el conteo de los pacientes atendidos en tiempo real
  sendCountCola(count: any){
    this.socket.emit('countCola', JSON.stringify(count));
  }
  getCountCola(){
    return this.socket.fromEvent<any>('countCola');
  }

}
